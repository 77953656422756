import React, { useState } from 'react'
import CookiesBannerUtils from 'utils/cookies-banner-utils'
import StandardModal from 'components/modals/StandardModal'
import Button from 'components/Button'
import useTranslation from 'translations/hooks/useTranslation'
import CookiesSection from './CookiesSection'

interface IProps {
  hide: () => void
  hideAll: () => void
}

const CookiesBannerExtended: React.FC<IProps> = ({ hide, hideAll }) => {
  const { t } = useTranslation()

  const [categoryVisibility, setCategoryVisibility] = useState({
    required: false,
    analytics: false,
    ad: false,
  })

  const [categoryApproval, setCategoryApproval] = useState({
    required: true,
    analytics: false,
    ad: false,
  })

  const toggleCategoryVisibility = (key: string) => {
    setCategoryVisibility({
      ...categoryVisibility,
      [key]: !categoryVisibility[key],
    })
  }

  const toggleCategoryApproval = (key: string) => {
    setCategoryApproval({
      ...categoryApproval,
      [key]: !categoryApproval[key],
    })
  }

  const allowAll = () => {
    CookiesBannerUtils.allowAll()
    hideAll()
  }

  const allowSelected = () => {
    CookiesBannerUtils.set({
      analytics: categoryApproval.analytics,
      ad: categoryApproval.ad,
    })
    hideAll()
  }

  const disallow = () => {
    CookiesBannerUtils.disallow()
    hideAll()
  }

  return (
    <StandardModal
      className="cookies__extended-modal"
      title={t('common.cookiesBanner.settingsHeading')}
      description={t('common.cookiesBanner.detail.general')}
      onClose={hide}
      visible
    >
      <div>
        <CookiesSection
          required
          name="required"
          opened={categoryVisibility.required}
          checked={categoryApproval.required}
          title="common.cookiesBanner.functional"
          toggleCategoryVisibility={toggleCategoryVisibility}
          toggleCategoryApproval={toggleCategoryApproval}
          description="common.cookiesBanner.detail.functional"
          data={[
            {
              name: 'cc_settings',
              duration: `1 ${t('common.cookiesBanner.date.year')}`,
              description: `${t('common.cookiesBanner.descriptions.ccSettings')}`,
              company: 'pretlak s.r.o.',
            },
            {
              name: 'user_id',
              duration: 'Session',
              description: `${t('common.cookiesBanner.descriptions.session')}`,
              company: 'pretlak s.r.o.',
            },
            {
              name: 'cv_about',
              duration: `3 ${t('common.cookiesBanner.date.months')}`,
              description: `${t('common.cookiesBanner.descriptions.cv')}`,
              company: 'pretlak s.r.o.',
            },
            {
              name: 'cv_email',
              duration: `3 ${t('common.cookiesBanner.date.months')}`,
              description: `${t('common.cookiesBanner.descriptions.cv')}`,
              company: 'pretlak s.r.o.',
            },
            {
              name: 'cv_name',
              duration: `3 ${t('common.cookiesBanner.date.months')}`,
              description: `${t('common.cookiesBanner.descriptions.cv')}`,
              company: 'pretlak s.r.o.',
            },
            {
              name: 'freelancer_email',
              duration: `1 ${t('common.cookiesBanner.date.month')}`,
              description: `${t('common.cookiesBanner.descriptions.freelancer')}`,
              company: 'pretlak s.r.o.',
            },
            {
              name: 'freelancer_type',
              duration: `1 ${t('common.cookiesBanner.date.month')}`,
              description: `${t('common.cookiesBanner.descriptions.freelancer')}`,
              company: 'pretlak s.r.o.',
            },
            {
              name: 'job_cand_visited_#',
              duration: `1 ${t('common.cookiesBanner.date.month')}`,
              description: `${t('common.cookiesBanner.descriptions.visited')}`,
              company: 'pretlak s.r.o.',
            },
          ]}
        />

        <CookiesSection
          title="common.cookiesBanner.analytics"
          name="analytics"
          opened={categoryVisibility.analytics}
          checked={categoryApproval.analytics}
          toggleCategoryVisibility={toggleCategoryVisibility}
          toggleCategoryApproval={toggleCategoryApproval}
          description="common.cookiesBanner.detail.analytics"
          data={[
            {
              name: '_ga',
              duration: `2 ${t('common.cookiesBanner.date.years')}`,
              description: `${t('common.cookiesBanner.descriptions.gaBasic')}`,
              company: 'Google',
            },
            {
              name: '_ga_#',
              duration: `2 ${t('common.cookiesBanner.date.years')}`,
              description: `${t('common.cookiesBanner.descriptions.gaBasic')}`,
              company: 'Google',
            },
            {
              name: '_gid',
              duration: `1 ${t('common.cookiesBanner.date.day')}`,
              description: `${t('common.cookiesBanner.descriptions.ga24')}`,
              company: 'Google',
            },
          ]}
        />

        <CookiesSection
          title="common.cookiesBanner.ad"
          name="ad"
          opened={categoryVisibility.ad}
          checked={categoryApproval.ad}
          toggleCategoryVisibility={toggleCategoryVisibility}
          toggleCategoryApproval={toggleCategoryApproval}
          description="common.cookiesBanner.detail.ad"
          data={[
            {
              name: '_gcl_au',
              duration: `3 ${t('common.cookiesBanner.date.months')}`,
              description: `${t('common.cookiesBanner.descriptions.gaAds')}`,
              company: 'Google',
            },
            {
              name: '_fbp',
              duration: `4 ${t('common.cookiesBanner.date.months')}`,
              description: `${t('common.cookiesBanner.descriptions.metaAds')}`,
              company: 'Meta',
            },
          ]}
        />

        <Button
          text={t('common.cookiesBanner.agree')}
          onClick={allowAll}
          className="button--full margin-top-small"
        />

        {!categoryApproval.ad && !categoryApproval.analytics ? (
          <p className="paragraph paragraph--small center margin-top-small no-margin-bottom">
            <a className="link--black" onClick={disallow}>
              {t('common.cookiesBanner.disagree')}
            </a>
          </p>
        ) : (
          <p className="paragraph paragraph--small center margin-top-small no-margin-bottom">
            <a className="link--black" onClick={allowSelected}>
              {t('common.cookiesBanner.save')}
            </a>
          </p>
        )}
      </div>
    </StandardModal>
  )
}

export default CookiesBannerExtended
