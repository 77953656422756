import { GrammarCaseKey, SpecialChar, TranslationVariables } from 'translations/types'

const isGrammarCaseInTranslation = (translation: Record<GrammarCaseKey, string>) => {
  return (
    GrammarCaseKey.One in translation ||
    GrammarCaseKey.Other in translation ||
    GrammarCaseKey.Many in translation ||
    GrammarCaseKey.For in translation ||
    GrammarCaseKey.Whom in translation
  )
}

const getPluralKey = (count: number | string) => {
  if (typeof count === 'string') {
    return GrammarCaseKey.Many
  }

  if (count === 1) {
    return GrammarCaseKey.One
  }

  return count > 1 && count < 5 ? GrammarCaseKey.Other : GrammarCaseKey.Many
}

const parseSentenceVariables = (sentence: string, variables: TranslationVariables) => {
  return sentence.replace(/\{\{(\w+)\}\}/g, (match, key) => {
    return variables[key].toString() || ''
  })
}

const replaceTranslationVariables = (
  rawText: string,
  variables: TranslationVariables
) => {
  if (rawText) {
    const parseNewLinesAndSpaces = (input: string) => {
      return input
        .split(SpecialChar.NewLine)
        .map((sentence) => parseSentenceVariables(sentence, variables))
        .join('\n')
    }

    return rawText
      .split(SpecialChar.NoBreakingSpace)
      .map((sentense) => parseNewLinesAndSpaces(sentense))
      .join(SpecialChar.NoBreakingSpace)
  }

  return ''
}

const getGrammarEndingString = (
  translation: Record<GrammarCaseKey, string>,
  variables: TranslationVariables
) => {
  const { grammarCase } = variables

  return translation[grammarCase]
    ? replaceTranslationVariables(translation[grammarCase], variables)
    : ''
}

const getPluralizedString = (
  translation: Record<GrammarCaseKey, string>,
  variables: TranslationVariables
) => {
  const { count } = variables
  const key = getPluralKey(count)
  const plualizedTranslation = translation[key]

  return plualizedTranslation
    ? replaceTranslationVariables(plualizedTranslation, variables)
    : ''
}

const getGrammarCaseString = (
  translation: Record<GrammarCaseKey, string>,
  variables: TranslationVariables
) => {
  if ('count' in variables) {
    return getPluralizedString(translation, variables)
  }

  if ('grammarCase' in variables) {
    return getGrammarEndingString(translation, variables)
  }

  return ''
}

export const getTranslationWithVariables = (
  translation: any,
  variables: TranslationVariables
) => {
  if (typeof translation === 'object' && isGrammarCaseInTranslation(translation)) {
    return getGrammarCaseString(translation, variables)
  }

  return replaceTranslationVariables(translation, variables)
}
