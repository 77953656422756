module.exports = {
  controls: {
    forCompanies: 'Pre firmy',
    viewProfile: 'Zobraziť profil',
    accountSettings: 'Nastavenie účtu',
    registration: 'Registrácia',
    myCompany: 'Moja firma',
    forFreelancer: 'Pre freelancera',
  },
}
