module.exports = {
  invoice1: `Posting a job {{jobLevelName}} for the position of {{jobName}} on pretlak.com`,
  invoice2: "'Highlight' a job with your own color on pretlak.com",
  invoice3: `Boosting for {{days}} days on pretlak.com`,
  invoice4: 'Extra sharing on pretlak.com social channels',
  invoice5: `{{productText}} job offers on pretlak.com`,
  invoice6: `{{productText}} from pretlak.com`,
  invoice7: `Purchase display mode {{productName}} on pretlak.com`,
  invoice8: 'Monthly subscription. PRO membership - pretlak.com/freelancers',
  invoice9: 'Annual subscription. PRO membership - pretlak.com/freelancers',
  invoice10: 'Unused time. PRO membership - pretlak.com/freelancers',
  invoice11: 'Monthly subscription. Unlock freelancers - pretlak.com/freelancers',
  invoice12: 'Annual subscription. Unlock freelancers - pretlak.com/freelancers',
  invoice13: 'Unused time. Unlock freelancers - pretlak.com/freelancers',
  invoice14: 'Boosting profile on the web',
  invoice15: 'Sharing profile on social networks',
  invoice16: 'Highlighting profile with custom color',
}
