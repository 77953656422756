import { IPromoCode } from 'redux/interfaces/promoCodes'

export const defaultPromoCodesState: IPromoCode[] = null

const company = (state = defaultPromoCodesState, action) => {
  switch (action.type) {
    case 'PROMOCODES_FETCH':
      return action.payload
    default:
      return state
  }
}

export default company
