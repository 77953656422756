module.exports = {
  'job-edit-ok': 'Vaše pracovní nabídka byla úspěšně upravena!',
  'company-edit-ok': 'Firemní data byla úspěšně upravena.',
  'profile-edit-ok': 'Profil byl úspěšně upraven.',
  'settings-edit-ok': 'Heslo bylo úspěšně upraveno.',
  'notification-edit-ok': 'Nastavení e-mailů bylo úspěšně upraveno.',
  'notification-edit-ko': 'Při ukládání změn e-mailů nastala chyba.',
  'signup-ok': 'Vaše registrace byla úspěšná. Vítejte!',
  registrationSuccess: 'Vaše registrace byla úspěšná. Vítejte!',
  urlOfPostWasCopied: 'URL adresa inzerátu byla zkopírována!',
  postWasSuccessfullyEdited: 'Inzerát byl úspěšně upraven!',
}
