import { useRouter } from 'next/router'
import React, { FC, useRef } from 'react'
import Dropdown, { DropdownContent, DropdownTrigger } from 'react-simple-dropdown'
import classnames from 'classnames'
import { getAvailableLanguages } from 'server/translations'
import { Locale } from 'translations/types'
import { NODE_ENV } from 'utils/helpers'
import Emoji from 'components/Emoji'
import { LANGUAGE_ICON, LANGUAGE_SHORTCUT_LABEL } from './utils'
import useLanguageSwitch from './useLanguageSwitch'

type IProps = {
  theme?: 'light' | 'dark'
}

const AVAILABLE_LANGUAGES = getAvailableLanguages(NODE_ENV)

const LanguageDropdownSwitch: FC<IProps> = ({ theme = 'light' }) => {
  const dropdownRef = useRef<{
    hide?: () => void
  }>()
  const { defaultLocale, locale } = useRouter()
  const switchLanguage = useLanguageSwitch()

  return (
    <Dropdown ref={dropdownRef}>
      <DropdownTrigger>
        <div
          className={classnames({
            'dropdown__handler-white': theme === 'light',
            'dropdown__handler-black': theme === 'dark',
          })}
        >
          <Emoji className="header__company-emoji">
            {LANGUAGE_ICON[locale || defaultLocale]}
          </Emoji>
          <span className="header__company-link">
            {LANGUAGE_SHORTCUT_LABEL[locale || defaultLocale]}
          </span>
        </div>
      </DropdownTrigger>
      <DropdownContent>
        {(AVAILABLE_LANGUAGES as Locale[]).map((language) => (
          <div
            key={`language-${language}`}
            className="dropdown__inner dropdown__language dropdown__inner-single"
          >
            <div
              className="dropdown__item"
              onClick={() => {
                dropdownRef?.current?.hide?.()
                switchLanguage(language)
              }}
            >
              <span className="dropdown__language-link">
                <Emoji>{LANGUAGE_ICON[language]}</Emoji>{' '}
                <span className="dropdown__language-link-label">
                  {LANGUAGE_SHORTCUT_LABEL[language]}
                </span>
              </span>
            </div>
          </div>
        ))}
      </DropdownContent>
    </Dropdown>
  )
}

export default LanguageDropdownSwitch
