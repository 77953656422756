import { combineReducers } from 'redux'
import { ICompany } from 'redux/interfaces/company'
import { IGeneral } from 'redux/interfaces/general'
import { IUser } from 'redux/interfaces/user'
import { IJob } from 'redux/interfaces/job'
import { IProfile } from 'redux/interfaces/profile'
import { IPromoCode } from 'redux/interfaces/promoCodes'
import { IProduct } from 'redux/interfaces/product'
import { IStats } from 'redux/interfaces/stats'
import user, { defaultUserState } from './user'
import company, { defaultCompanyState } from './company'
import general, { defaultGeneralState } from './general'
import jobs, { defaultJobsState } from './jobs'
import promoCodes, { defaultPromoCodesState } from './promoCodes'
import profile, { defaultProfileState } from './profile'
import products, { defaultProductsState } from './products'
import stats, { defaultStatsState } from './stats'
import jobDrafts, { defaultJobDraftsState } from './jobDrafts'

export interface IStoreState {
  general: IGeneral
  user: IUser | null
  company: ICompany
  jobs: IJob[] | null
  promoCodes: IPromoCode[] | null
  profile: IProfile | null
  products: IProduct[] | null
  stats: IStats | null
  jobDrafts: any[]
}

export const initialStoreState: IStoreState = {
  general: defaultGeneralState,
  user: defaultUserState,
  company: defaultCompanyState,
  jobs: defaultJobsState,
  promoCodes: defaultPromoCodesState,
  profile: defaultProfileState,
  products: defaultProductsState,
  stats: defaultStatsState,
  jobDrafts: defaultJobDraftsState,
}

export const rootReducer = combineReducers({
  general,
  user,
  company,
  jobs,
  promoCodes,
  profile,
  products,
  stats,
  jobDrafts,
})
