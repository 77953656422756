import classnames from 'classnames'
import Link from 'next/link'
import React from 'react'
import TrackingUtils from 'utils/tracking'
import Emoji from 'components/Emoji'

interface IProps {
  text: string
  className?: string
  size?: 'full'
  loading?: boolean
  progress?: number
  disabled?: boolean
  href?: any
  target?: string
  customRef?: any
  container?: boolean
  containerClassName?: string
  color?: 'green' | 'white'
  onClick?: (e) => void
  type?: 'reset' | 'submit' | 'button'
  dataCy?: string
}

const Button = ({
  text,
  loading,
  progress,
  disabled,
  className,
  href,
  customRef,
  size,
  target,
  color,
  dataCy,
  onClick,
  type,
}: IProps) => {
  const handleClick = (e) => {
    TrackingUtils.event('click-button', {
      event_action: href,
      event_data1: text,
      event_data2: `location:${document.location.href}`,
      event_data3: `text:${text}`,
      targetUrl: href,
      location: document.location.href,
      text,
    })

    if (onClick) {
      onClick(e)
    }
  }

  if (href) {
    return (
      <Link
        href={href}
        className={classnames('button', {
          'button--full': size === 'full',
          'button--green': color === 'green',
          'button--white': color === 'white',
          [className]: className,
        })}
        data-cy={dataCy}
        ref={customRef}
        onClick={handleClick}
        target={target}
      >
        <span className="button__content">{text}</span>
      </Link>
    )
  }

  return (
    <button
      className={classnames('button', {
        'button--loading': loading,
        'button--full': size === 'full',
        'button--green': color === 'green',
        'button--white': color === 'white',
        [className]: className,
      })}
      data-cy={dataCy}
      ref={customRef}
      disabled={disabled || loading}
      onClick={handleClick}
      type={type}
    >
      <span className="button__content">
        {progress ? (
          <>
            <span
              className="button__content--percentage"
              style={{ width: `${progress || 0}%` }}
            />
            <span className="button__content-loader-wrapper">
              <span className="button__content--rotate">
                <Emoji>⏳</Emoji>
              </span>{' '}
              {progress}%
            </span>
          </>
        ) : (
          <span className="button__content-loader button__content--rotate">
            <Emoji>⏳</Emoji>
          </span>
        )}
        <span className="button__content-text">{text}</span>
      </span>
    </button>
  )
}

const ButtonWrapper = (props: IProps) => {
  if (props.container) {
    return (
      <div
        className={classnames('container center', {
          [props.containerClassName]: props.containerClassName,
        })}
      >
        {Button(props)}
      </div>
    )
  }

  return Button(props)
}

export default ButtonWrapper
