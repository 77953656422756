import Router from 'next/router'

export default class RouterUtils {
  static async push(link: string) {
    await Router.push(link)
  }

  static async openExternal(link: string) {
    const newWindow = window.open(link, '_blank', 'noopener,noreferrer')

    if (newWindow) {
      newWindow.opener = null
    }
  }
}
