import get from 'lodash.get'
import strings from 'translations/strings'
import { LocalizeOptions } from 'translations/types'
import { getTranslationWithVariables } from './helpers'

export const localize = (args: {
  path: string
  locale: string
  defaultLocale: string
  options?: LocalizeOptions
}): string => {
  const { locale, path, options, defaultLocale } = args
  let translation = get(strings[locale], path)
  if (options?.variables) {
    translation = getTranslationWithVariables(translation, options.variables)
  }

  if (translation) {
    if (typeof translation === 'object') {
      if ('default' in translation) {
        return translation['default']
      }

      const translationKeys = Object.keys(translation)
      return translation[translationKeys[0]] ?? ''
    }

    return translation
  }

  if (translation === undefined) {
    console.warn(`Translation '${path}' for locale '${locale}' not found.`)
  }

  return get(strings[defaultLocale], path) || ''
}

export const t = (args: { path: string; locale?: string }) => {
  const { path, locale } = args

  return localize({
    path,
    locale: locale ?? 'sk',
    defaultLocale: locale,
  })
}
