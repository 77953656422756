module.exports = {
  'job-edit-ok': 'Vaša pracovná ponuka bola úspešne upravená!',
  'company-edit-ok': 'Firemné údaje boli úspešne upravené.',
  'profile-edit-ok': 'Profil bol úspešne upravený.',
  'settings-edit-ok': 'Heslo bolo úspešne upravené.',
  'notification-edit-ok': 'Nastavenie e-mailov bolo úspešne upravené.',
  'notification-edit-ko': 'Pri ukladaní zmien e-mailov nastala chyba.',
  'signup-ok': 'Vaša registrácia bola úspešná. Vitajte!',
  registrationSuccess: 'Vaša registrácia bola úspešná. Vitajte!',
  urlOfPostWasCopied: 'URL adresa inzerátu bola skopírovaná!',
  postWasSuccessfullyEdited: 'Inzerát bol úspešne upravený!',
}
