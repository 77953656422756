import { IJob } from 'redux/interfaces/job'
import JobUtils from 'utils/job'

export const defaultJobsState: IJob[] = null

const updateItem = (item, state) => {
  const jobs = state.map((currentItem) => {
    if (currentItem._id === item._id) {
      return item
    }

    return currentItem
  })

  return JobUtils.sortOnlineJobs(jobs)
}

const addItem = (item, state) => {
  const jobs = state
  jobs.unshift(item)

  return jobs
}

const company = (state = defaultJobsState, action) => {
  switch (action.type) {
    case 'JOBS_ITEM_ADD':
      return addItem(action.payload, state)
    case 'JOBS_ITEM_UPDATE':
      return updateItem(action.payload, state)
    case 'JOBS_FETCH':
      return action.payload
    default:
      return state
  }
}

export default company
