import Head from 'next/head'
import { NextRouter, useRouter } from 'next/router'
import Script from 'next/script'
import { HOST, GTM_TRACKING_ID } from 'utils/helpers'

interface IProps {
  appType: string
}

const getLanguageUrl = (router: NextRouter, locale: string) => {
  const url =
    locale === 'sk' ? `${HOST}${router.asPath}` : `${HOST}/${locale}${router.asPath}`

  // Note: remove / from the end of the url
  return url.replace(/\/$/, '')
}

export default ({ appType }: IProps) => {
  const router = useRouter()
  const activeLocale = router.locale
  const canonicalUrl = getLanguageUrl(router, activeLocale)

  return (
    <>
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}

          gtag('consent', 'default', {
            'ad_storage': 'denied',
            'analytics_storage': 'denied',
            'functionality_storage': 'denied',
            'personalization_storage': 'denied',
            'security_storage': 'denied',
          });

          gtag('js', new Date());
          gtag('config', '${GTM_TRACKING_ID}');
        `}
      </Script>
      <Script
        id="gtag-base"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${GTM_TRACKING_ID}');
          `,
        }}
      />

      <Head>
        <link
          key="alternate-x-default"
          rel="alternate"
          hrefLang="x-default"
          href={getLanguageUrl(router, 'sk')}
        />
        {router.locales.map((locale) => {
          return (
            <link
              key={`alternate-${locale}`}
              rel="alternate"
              hrefLang={locale}
              href={getLanguageUrl(router, locale)}
            />
          )
        })}

        <link key="canonical" rel="canonical" href={canonicalUrl} />

        {appType === 'admin' && (
          <>
            <meta name="robots" content="noindex, nofollow" />
            <meta name="referrer" content="no-referrer" />
          </>
        )}
      </Head>
    </>
  )
}
