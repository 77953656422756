module.exports = {
  company: {
    subtitle:
      'Len 1-krát zadáte vaše firemné údaje a už ich viac od vás nebudeme potrebovať.',
    form: {
      companyNameLabel: 'Názov firmy (vrátane s.r.o., spol. s.r.o., a.s., ...)',
      iAmPersonalAgency: 'Som personálna agentúra',
      iAmOutsideEu: 'Som firma mimo EÚ',
      streetLabel: 'Ulica a číslo (Fakturačná adresa)',
      emailTooltip: 'Tento e-mail slúži na prihlásenie do firemného profilu.',
    },
  },
  freelancer: {
    subtitle: 'Buď súčasťou špičky freelance komunity a pridaj sa k nám. Zadarmo!',
    fillTheForm: 'Vyplň formulár a vyšperkuj si profil',
    beVisibleForCompanies: 'Buď na očiach u viac ako {{count}} firiem',
    offersToEmail: 'Ponuky na prácu ti budú chodiť e-mailom',
    form: {
      salaryNoteLabel: 'Poznámka k hodinovej sadzbe',
      aboutMeLabel: 'O mne',
    },
    topBadgeTitle: 'badge za kvalitný profil',
    topBadgeText:
      'Daj si na svojom profile záležať a zvýš tak šancu zaujať potencionálneho klienta. Tie najlepšie dostanú od nás TOP badge.',
  },
  doYouHaveAccountAlready: 'Máte už vytvorený svoj účet?',
}
