import { useRouter } from 'next/router'
import { useCallback } from 'react'
import { TranslationFunction, TranslationVariables } from 'translations/types'
import { localize } from 'translations/utils'

const useTranslation = () => {
  const { locale, defaultLocale } = useRouter()

  const t: TranslationFunction = useCallback(
    (path: string, variables?: TranslationVariables) => {
      return localize({
        path,
        locale,
        defaultLocale,
        options: {
          variables,
        },
      })
    },
    [locale]
  )

  return {
    t,
    locale: locale ?? 'sk',
  }
}

export default useTranslation
