module.exports = {
  sponsored: 'Sponzorované',
  sponsoredFreelancer: 'Odporúčame',
  exclusive: 'Exkluzív',
  colored: 'Zafarbená',
  new: 'Nové',
  newF: 'Nová',
  newM: 'Nový',
  mostFavorite: 'NAJOBĽÚBENEJŠIE',
  top: 'Topovaná',
  topFreelancer: 'Topovaný',
  newOfferCount: {
    one: '{{count}} Nová',
    other: '{{count}} Nové',
    many: '{{count}} Nových',
  },
  available: 'Dostupný',
  unavailable: 'Nedostupný',
  hot: '🔥 HOT',
}
