module.exports = {
  sponsored: 'Sponsored',
  sponsoredFreelancer: 'Recommended',
  exclusive: 'Exclusive',
  colored: 'Colored', // with custom color?
  new: 'New',
  newF: 'New',
  newM: 'New',
  mostFavorite: 'MOST POPULAR',
  top: 'Top',
  topFreelancer: 'Top',
  newOfferCount: {
    one: '{{count}} new',
    other: '{{count}} new',
    many: '{{count}} new',
  },
  available: 'Available',
  unavailable: 'Unavailable',
  hot: '🔥 HOT',
}
