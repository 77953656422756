module.exports = {
  'ostrov-ascension': '🇦🇨 Ostrov Ascension',
  andorra: '🇦🇩 Andorra',
  'spojene-arabske-emiraty': '🇦🇪 Spojené Arabské Emiráty',
  afganistan: '🇦🇫 Afganistan',
  'antigua-a-barbuda': '🇦🇬 Antigua a Barbuda',
  anguilla: '🇦🇮 Anguilla',
  albansko: '🇦🇱 Albánsko',
  armensko: '🇦🇲 Arménsko',
  angola: '🇦🇴 Angola',
  antarktida: '🇦🇶 Antarktida',
  argentina: '🇦🇷 Argentína',
  'americka-samoa': '🇦🇸 Americká Samoa',
  rakusko: '🇦🇹 Rakúsko',
  australia: '🇦🇺 Austrália',
  aruba: '🇦🇼 Aruba',
  'alandske-ostrovy': '🇦🇽 Alandské ostrovy',
  azerbajdzan: '🇦🇿 Azerbajdžan',
  'bosna-a-hercegovina': '🇧🇦 Bosna a Hercegovina',
  barbados: '🇧🇧 Barbados',
  banglades: '🇧🇩 Bangladéš',
  belgicko: '🇧🇪 Belgicko',
  'burkina-faso': '🇧🇫 Burkina Faso',
  bulharsko: '🇧🇬 Bulharsko',
  bahrajn: '🇧🇭 Bahrajn',
  burundi: '🇧🇮 Burundi',
  benin: '🇧🇯 Benin',
  'svaty-bartolomej': '🇧🇱 Svätý Bartolomej',
  bermudy: '🇧🇲 Bermudy',
  brunej: '🇧🇳 Brunej',
  bolivia: '🇧🇴 Bolívia',
  'karibske-holandsko': '🇧🇶 Karibské Holandsko',
  brazilia: '🇧🇷 Brazília',
  bahamy: '🇧🇸 Bahamy',
  bhutan: '🇧🇹 Bhután',
  'ostrov-bouvet': '🇧🇻 Ostrov Bouvet',
  botswana: '🇧🇼 Botswana',
  bielorusko: '🇧🇾 Bielorusko',
  belize: '🇧🇿 Belize',
  kanada: '🇨🇦 Kanada',
  'kokosove-keeling-ostrovy': '🇨🇨 Kokosové (Keeling) ostrovy',
  'kongo-kinshasa': '🇨🇩 Kongo – Kinshasa',
  'stredoafricka-republika': '🇨🇫 Stredoafrická republika',
  'kongo-brazzaville': '🇨🇬 Kongo – Brazzaville',
  svajciarsko: '🇨🇭 Švajčiarsko',
  'pobrezie-slonoviny': '🇨🇮 Pobrežie Slonoviny',
  'cookove-ostrovy': '🇨🇰 Cookove ostrovy',
  cile: '🇨🇱 Čile',
  kamerun: '🇨🇲 Kamerun',
  cina: '🇨🇳 Čína',
  kolumbia: '🇨🇴 Kolumbia',
  'clipperton-island': '🇨🇵 Clipperton Island',
  kostarika: '🇨🇷 Kostarika',
  kuba: '🇨🇺 Kuba',
  kapverdy: '🇨🇻 Kapverdy',
  curacao: '🇨🇼 Curaçao',
  'vianocny-ostrov': '🇨🇽 vianočný ostrov',
  cyprus: '🇨🇾 Cyprus',
  cesko: '🇨🇿 Česko',
  nemecko: '🇩🇪 Nemecko',
  'diego-garcia': '🇩🇬 Diego Garcia',
  dzibutsko: '🇩🇯 Džibutsko',
  dansko: '🇩🇰 Dánsko',
  dominica: '🇩🇲 Dominica',
  'dominikanska-republika': '🇩🇴 Dominikánska republika',
  alzirsko: '🇩🇿 Alžírsko',
  'ceuta-a-melilla': '🇪🇦 Ceuta a Melilla',
  ekvador: '🇪🇨 Ekvádor',
  estonsko: '🇪🇪 Estónsko',
  egypt: '🇪🇬 Egypt',
  'zapadna-sahara': '🇪🇭 Západná Sahara',
  eritrea: '🇪🇷 Eritrea',
  spanielsko: '🇪🇸 Španielsko',
  etiopia: '🇪🇹 Etiópia',
  'europska-unia': '🇪🇺 Európska únia',
  finsko: '🇫🇮 Fínsko',
  fiji: '🇫🇯 Fiji',
  'falklandske-ostrovy': '🇫🇰 Falklandské ostrovy',
  mikronezia: '🇫🇲 Mikronézia',
  'faerske-ostrovy': '🇫🇴 Faerské ostrovy',
  francuzsko: '🇫🇷 Francúzsko',
  gabon: '🇬🇦 Gabon',
  'spojene-kralovstvo': '🇬🇧 Spojené kráľovstvo',
  grenada: '🇬🇩 Grenada',
  gruzinsko: '🇬🇪 Gruzínsko',
  'francuzska-guiana': '🇬🇫 Francúzska Guiana',
  guernsey: '🇬🇬 Guernsey',
  ghana: '🇬🇭 Ghana',
  gibraltar: '🇬🇮 Gibraltár',
  gronsko: '🇬🇱 Grónsko',
  gambia: '🇬🇲 Gambia',
  guinea: '🇬🇳 Guinea',
  guadeloupe: '🇬🇵 Guadeloupe',
  'rovnikova-guinea': '🇬🇶 Rovníková Guinea',
  grecko: '🇬🇷 Grécko',
  'juzna-georgia-and-south-sandwich-islands': '🇬🇸 Južná Georgia & South Sandwich Islands',
  guatemala: '🇬🇹 Guatemala',
  guam: '🇬🇺 Guam',
  'guinea-bissau': '🇬🇼 Guinea-Bissau',
  guyana: '🇬🇾 Guyana',
  'hongkong-sar-cina': '🇭🇰 Hongkong SAR Čína',
  'heard-and-mcdonald-islands': '🇭🇲 Heard & McDonald Islands',
  honduras: '🇭🇳 Honduras',
  chorvatsko: '🇭🇷 Chorvátsko',
  haiti: '🇭🇹 Haiti',
  madarsko: '🇭🇺 Maďarsko',
  'kanarske-ostrovy': '🇮🇨 Kanarske ostrovy',
  indonezia: '🇮🇩 Indonézia',
  irsko: '🇮🇪 Írsko',
  izrael: '🇮🇱 Izrael',
  'ostrov-man': '🇮🇲 Ostrov Man',
  india: '🇮🇳 India',
  'britske-indicke-oceanske-uzemie': '🇮🇴 Britské indické oceánske územie',
  irak: '🇮🇶 Irak',
  iran: '🇮🇷 Irán',
  island: '🇮🇸 Island',
  taliansko: '🇮🇹 Taliansko',
  jersey: '🇯🇪 Jersey',
  jamajka: '🇯🇲 Jamajka',
  jordansko: '🇯🇴 Jordánsko',
  japonsko: '🇯🇵 Japonsko',
  kena: '🇰🇪 Keňa',
  kirgizsko: '🇰🇬 Kirgizsko',
  kambodza: '🇰🇭 Kambodža',
  kiribati: '🇰🇮 Kiribati',
  komory: '🇰🇲 komory',
  'svaty-kristof-a-nevis': '🇰🇳 Svätý Krištof a Nevis',
  'severna-korea': '🇰🇵 Severná Kórea',
  'juzna-korea': '🇰🇷 Južná Kórea',
  kuvajt: '🇰🇼 Kuvajt',
  'kajmanske-ostrovy': '🇰🇾 Kajmanské ostrovy',
  kazachstan: '🇰🇿 Kazachstan',
  laos: '🇱🇦 Laos',
  libanon: '🇱🇧 Libanon',
  'svata-lucia': '🇱🇨 Svätá Lucia',
  lichtenstajnsko: '🇱🇮 Lichtenštajnsko',
  'sri-lanka': '🇱🇰 Srí Lanka',
  liberia: '🇱🇷 Libéria',
  lesotho: '🇱🇸 Lesotho',
  litva: '🇱🇹 Litva',
  luxembursko: '🇱🇺 Luxembursko',
  lotyssko: '🇱🇻 Lotyšsko',
  libya: '🇱🇾 Líbya',
  maroko: '🇲🇦 Maroko',
  monaco: '🇲🇨 Monaco',
  moldavsko: '🇲🇩 Moldavsko',
  'cierna-hora': '🇲🇪 Čierna hora',
  'svaty-martin': '🇲🇫 Svätý Martin',
  madagaskar: '🇲🇬 Madagaskar',
  'marsalove-ostrovy': '🇲🇭 Maršalove ostrovy',
  'severne-macedonsko': '🇲🇰 Severné Macedónsko',
  mali: '🇲🇱 Mali',
  'mjanmarsko-barma': '🇲🇲 Mjanmarsko (Barma)',
  mongolsko: '🇲🇳 Mongolsko',
  'oao-macao-cina': '🇲🇴 OAO Macao Čína',
  'severne-mariany': '🇲🇵 Severné Mariány',
  martinique: '🇲🇶 Martinique',
  mauritania: '🇲🇷 Mauritánia',
  montserrat: '🇲🇸 Montserrat',
  malta: '🇲🇹 Malta',
  mauricius: '🇲🇺 Maurícius',
  maldivy: '🇲🇻 Maldivy',
  malawi: '🇲🇼 Malawi',
  mexiko: '🇲🇽 Mexiko',
  malajzia: '🇲🇾 Malajzia',
  mozambik: '🇲🇿 Mozambik',
  namibia: '🇳🇦 Namíbia',
  'nova-kaledonia': '🇳🇨 Nová Kaledónia',
  niger: '🇳🇪 Niger',
  'ostrov-norfolk': '🇳🇫 Ostrov Norfolk',
  nigeria: '🇳🇬 Nigéria',
  nikaragua: '🇳🇮 Nikaragua',
  holandsko: '🇳🇱 Holandsko',
  norsko: '🇳🇴 Nórsko',
  nepal: '🇳🇵 Nepál',
  nauru: '🇳🇷 Nauru',
  niue: '🇳🇺 Niue',
  'novy-zeland': '🇳🇿 Nový Zéland',
  oman: '🇴🇲 Omán',
  panama: '🇵🇦 Panama',
  peru: '🇵🇪 Peru',
  'francuzska-polynezia': '🇵🇫 Francúzska Polynézia',
  'papua-nova-guinea': '🇵🇬 Papua-Nová Guinea',
  filipiny: '🇵🇭 Filipíny',
  pakistan: '🇵🇰 Pakistan',
  polsko: '🇵🇱 Poľsko',
  'st-pierre-and-miquelon': '🇵🇲 St. Pierre & Miquelon',
  'pitcairnove-ostrovy': '🇵🇳 Pitcairnove ostrovy',
  portoriko: '🇵🇷 Portoriko',
  'palestinske-uzemia': '🇵🇸 Palestínske územia',
  portugalsko: '🇵🇹 Portugalsko',
  palau: '🇵🇼 Palau',
  paraguaj: '🇵🇾 Paraguaj',
  katar: '🇶🇦 Katar',
  reunion: '🇷🇪 Réunion',
  rumunsko: '🇷🇴 Rumunsko',
  srbsko: '🇷🇸 Srbsko',
  rusko: '🇷🇺 Rusko',
  rwanda: '🇷🇼 Rwanda',
  'saudska-arabia': '🇸🇦 Saudská Arábia',
  'salamunove-ostrovy': '🇸🇧 Šalamúnove ostrovy',
  seychely: '🇸🇨 Seychely',
  sudan: '🇸🇩 Sudán',
  svedsko: '🇸🇪 Švédsko',
  singapur: '🇸🇬 Singapur',
  'svata-helena': '🇸🇭 Svätá Helena',
  slovinsko: '🇸🇮 Slovinsko',
  'svalbard-and-jan-mayen': '🇸🇯 Svalbard & Jan Mayen',
  slovensko: '🇸🇰 Slovensko',
  'sierra-leone': '🇸🇱 Sierra Leone',
  'san-marino': '🇸🇲 San Marino',
  senegal: '🇸🇳 Senegal',
  somalsko: '🇸🇴 Somálsko',
  suriname: '🇸🇷 Suriname',
  'juzny-sudan': '🇸🇸 Južný Sudán',
  'sao-tome-and-principe': '🇸🇹 São Tomé & Principe',
  'el-salvador': '🇸🇻 El Salvador',
  'sint-maarten': '🇸🇽 Sint Maarten',
  syria: '🇸🇾 Sýria',
  eswatini: '🇸🇿 Eswatini',
  'tristan-da-cunha': '🇹🇦 Tristan da Cunha',
  'ostrovy-turks-and-caicos': '🇹🇨 Ostrovy Turks & Caicos',
  cad: '🇹🇩 Čad',
  'francuzske-juzne-uzemia': '🇹🇫 Francúzske južné územia',
  ist: '🇹🇬 Ísť',
  thajsko: '🇹🇭 Thajsko',
  tadzikistan: '🇹🇯 Tadžikistan',
  tokelau: '🇹🇰 Tokelau',
  'vychodny-timor': '🇹🇱 Východný Timor',
  turkmensko: '🇹🇲 Turkménsko',
  tunisko: '🇹🇳 Tunisko',
  tonga: '🇹🇴 Tonga',
  turecko: '🇹🇷 Turecko',
  'trinidad-a-tobago': '🇹🇹 Trinidad a Tobago',
  tuvalu: '🇹🇻 Tuvalu',
  taiwan: '🇹🇼 Taiwan',
  tanzania: '🇹🇿 Tanzánia',
  ukrajina: '🇺🇦 Ukrajina',
  uganda: '🇺🇬 Uganda',
  'odlahle-ostrovy-usa': '🇺🇲 Odľahlé ostrovy USA',
  'spojene-narody': '🇺🇳 Spojené národy',
  'spojene-staty': '🇺🇸 USA',
  uruguaj: '🇺🇾 Uruguaj',
  uzbekistan: '🇺🇿 Uzbekistan',
  vatikan: '🇻🇦 Vatikán',
  'svaty-vincent-a-grenadiny': '🇻🇨 Svätý Vincent a Grenadíny',
  venezuela: '🇻🇪 Venezuela',
  'britske-panenske-ostrovy': '🇻🇬 Britské Panenské ostrovy',
  'americke-panenske-ostrovy': '🇻🇮 Americké Panenské ostrovy',
  vietnam: '🇻🇳 Vietnam',
  vanuatu: '🇻🇺 Vanuatu',
  'wallis-and-futuna': '🇼🇫 Wallis & Futuna',
  samoa: '🇼🇸 Samoa',
  kosovo: '🇽🇰 Kosovo',
  jemen: '🇾🇪 Jemen',
  mayotte: '🇾🇹 Mayotte',
  'juzna-afrika': '🇿🇦 Južná Afrika',
  zambia: '🇿🇲 Zambia',
  zimbabwe: '🇿🇼 Zimbabwe',
  anglicko: '🏴󠁧󠁢󠁥󠁮󠁧󠁿 Anglicko',
  skotsko: '🏴󠁧󠁢󠁳󠁣󠁴󠁿 Škótsko',
  wales: '🏴󠁧󠁢󠁷󠁬󠁳󠁿 Wales',
  bangladesh: '🇧🇩 Bangladesh',
  bulgaria: '🇧🇬 Bulharsko',
  brazil: '🇧🇷 Brazília',
  'ceska-republika': '🇨🇿 Česká republika',
  'velka-britania': '🇬🇧 Veľká Británia',
  'slovenska-republika': '🇸🇰 Slovenská republika',
  usa: '🇺🇸 USA',
  remote: '🌎 Remote',
}
