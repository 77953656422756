module.exports = {
  luigisBox: `The quality of candidates was 3 times higher than through other websites. Candidates were relevant, with experience, and following current trends since they came from Pretlak.`,
  slido: `Through Pretlak we reach a new segment of more qualified candidates and we don't have to overload our recruitment team.`,
  berlinBrandsGroup: `Almost every candidate applying for a marketing position through Pretlak is invited for an interview. The quality is stellar - we save a lot of recruiting time.`,
  sygic: `Great job! Thanks to you we hired the first person. We will definitely continue using Pretlak.`,
  barneyStudio: `Pretlak helped us to find great people who were an amazing addition to our team. Thanks to Pretlak we save our time, because we do not get CVs that are not irrelevant to our needs.`,
  twoCreateAdvertising: `Pretlak is made by people who understand the creative business. Recruitment effectivity beats other websites by a huge margin..`,
  imagons: `Relevant responses and the quality of candidates are miles ahead comparing to the other websites, from which people from marketing seemingly disappeared. We are very satisfied. `,
  darenAndCurtis: `We've been using Pretlak for several years and it's an important part of our HR strategy.`,
  gecora: `We use Pretlak.sk for tech and non-tech jobs as well. It's our main channel and we even linked it with gecora.com. It's a solution where people from IT world "find themselves" and look for a job in the process, which, based on our experience, is the ideal opportunity to get the most qualified candidates.`,
  kesu: `Pretlak gave us crazy good candidates. Fast, cheap and high-quality (yes, it is possible!).`,
  gymbeam: `We love to use Pretlak.sk because it's a modern job website which helps us to connect with a community of talented creatives.`,
  tvjoj: `Thank you for the excellent work! Today, I posted an advertisement and already have 5 resumes. They also come directly to my email. I would like to praise your work, and I only handle marketing job positions through you. I like good people, and I have found them at your company.`,
  ovb: `We managed to find 2 frontend programmers very quickly. Thank you for sharing the offer, even though it hasn't been paid yet, which I consider a very considerate gesture.`,
}
