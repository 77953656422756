import { post } from 'utils/axios'

export const JOB_DRAFTS_FETCH = 'JOB_DRAFTS_FETCH'
export const JOB_DRAFT_SAVE = 'JOB_DRAFT_SAVE'
export const JOB_DRAFT_DELETE = 'JOB_DRAFT_DELETE'

export const jobDraftCreate =
  (inputData: any) =>
  async (dispatch): Promise<{ success: boolean; data: any }> => {
    try {
      const { data } = await post('api/job-draft/save', inputData)

      if (data.status === 'ok') {
        dispatch({ type: JOB_DRAFT_SAVE, payload: data.data })
        return { success: true, data: data.data }
      }

      return { success: false, data: null }
    } catch (e) {
      return { success: false, data: null }
    }
  }

export const jobDraftEdit =
  (inputData: any) =>
  async (dispatch): Promise<{ success: boolean }> => {
    try {
      const { data } = await post('api/job-draft/save', inputData)

      if (data.status === 'ok') {
        dispatch({ type: JOB_DRAFT_SAVE, payload: data.data })
        return { success: true }
      }

      return { success: false }
    } catch (e) {
      return { success: false }
    }
  }

export const jobDraftDelete =
  (id: string) =>
  async (dispatch): Promise<{ success: boolean }> => {
    try {
      const { data } = await post(`api/job-draft/delete/${id}`)

      if (data.status === 'ok') {
        dispatch({ type: JOB_DRAFT_DELETE, payload: id })
        return { success: true }
      }

      return { success: false }

      return { success: true }
    } catch (e) {
      return { success: false }
    }
  }
