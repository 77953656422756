import React from 'react'
import classnames from 'classnames'
import Checkbox from 'components/form/Checkbox'
import useTranslation from 'translations/hooks/useTranslation'

interface IDataItem {
  name: string
  duration: string
  description: string
  company: string
}

interface IProps {
  name: string
  title: string
  description: string
  opened: boolean
  checked: boolean
  data: IDataItem[]
  required?: boolean
  toggleCategoryApproval: (key: string) => void
  toggleCategoryVisibility: (key: string) => void
}

const CookiesSection: React.FC<IProps> = ({
  name,
  title,
  description,
  data,
  opened,
  checked,
  required,
  toggleCategoryApproval,
  toggleCategoryVisibility,
}) => {
  const { t } = useTranslation()

  return (
    <div key={name}>
      <a
        className="accordeon__title cookies__accordeon"
        onClick={() => toggleCategoryVisibility(name)}
      >
        {opened ? (
          <img className="accordeon__title-icon" src="/images/icons/minus-sign.svg" />
        ) : (
          <img className="accordeon__title-icon" src="/images/icons/plus-sign.svg" />
        )}
        {t(title)}

        <Checkbox
          checked={checked}
          label={t(
            required ? 'common.cookiesBanner.required' : 'common.cookiesBanner.optional'
          )}
          name={name}
          disabled={required}
          onChange={() => toggleCategoryApproval(name)}
          withoutMarginTop
        />
      </a>
      <div
        className={classnames('accordeon__items', {
          open: opened,
        })}
      >
        <p className="paragraph paragraph--super-small">{t(description)}</p>
        <div className="cookies__table-wrapper">
          <table className="cookies__table">
            <thead>
              <tr>
                <th>Cookie</th>
                <th>{t('common.cookiesBanner.duration')}</th>
                <th>{t('common.cookiesBanner.description')}</th>
                <th>{t('common.cookiesBanner.processor')}</th>
              </tr>
            </thead>
            <tbody>
              {data.map((dataItem) => {
                return (
                  <tr key={dataItem.name}>
                    <td>{dataItem.name}</td>
                    <td>{dataItem.duration}</td>
                    <td>{dataItem.description}</td>
                    <td>{dataItem.company}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default CookiesSection
