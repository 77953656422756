import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Link from 'next/link'
import CookiesBannerUtils from 'utils/cookies-banner-utils'
import TrackingUtils from 'utils/tracking'
import StandardModal from 'components/modals/StandardModal'
import Button from 'components/Button'
import useTranslation from 'translations/hooks/useTranslation'
import { setCookiesVisibleContext } from 'redux/managers/general'
import CookiesBannerExtended from './CookiesBannerExtended'
import type { IStoreState } from 'redux/reducers'

const CookiesBanner: React.FC = () => {
  const cookiesVisible = useSelector((state: IStoreState) => state.general.cookiesVisible)
  const [extendedVisible, setExtendedVisible] = useState(false)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const setVisible = (nextVisibility: boolean) => {
    dispatch(setCookiesVisibleContext(nextVisibility))
  }

  const isVisible = () => {
    const actualPolicyData = CookiesBannerUtils.getPolicyCookie()

    TrackingUtils.trackConsent({
      analytics: actualPolicyData?.analytics || false,
      ad: actualPolicyData?.ad || false,
    })

    if (actualPolicyData) {
      CookiesBannerUtils.set({
        analytics: actualPolicyData.analytics || false,
        ad: actualPolicyData.ad || false,
      })
      return false
    }

    return true
  }

  useEffect(() => {
    const nextVisible = isVisible()
    setVisible(nextVisible)
  }, [])

  const showExtendedModal = () => {
    setExtendedVisible(true)
  }

  const hideExtendedModal = () => {
    setExtendedVisible(false)
  }

  const hide = () => {
    setVisible(false)
  }

  const allow = () => {
    CookiesBannerUtils.allowAll()
    hide()
  }

  const disallow = () => {
    CookiesBannerUtils.disallow()
    hide()
  }

  if (!cookiesVisible) {
    return null
  }

  return (
    <>
      {!extendedVisible && (
        <StandardModal
          title={t('common.cookiesBanner.heading')}
          className="user-usage-info-modal"
          description={
            <span className="cookies__text">
              <span className="cookies__text-desktop">
                {t('common.cookiesBanner.text')}{' '}
              </span>
              <span className="cookies__text-mobile">
                {t('common.cookiesBanner.textMobile')}{' '}
              </span>
              <Link href="/gdpr" className="cookies__link" rel="nofollow">
                {t('common.cookiesBanner.moreInfo')}
              </Link>
              .
            </span>
          }
          visible
        >
          <div className="cookies__buttons">
            <a onClick={disallow} className="cookies__link">
              {t('common.cookiesBanner.disagree')}
            </a>
            <Button
              text={t('common.cookiesBanner.agree')}
              onClick={allow}
              dataCy="cookies-allow"
            />
            <a onClick={showExtendedModal} className="cookies__link">
              {t('common.cookiesBanner.settings')}
            </a>
          </div>
        </StandardModal>
      )}

      {extendedVisible && (
        <CookiesBannerExtended hideAll={hide} hide={hideExtendedModal} />
      )}
    </>
  )
}

export default CookiesBanner
