export enum SpecialChar {
  NewLine = '[[nl]]',
  NoBreakingSpace = '&nbsp;',
}

export enum Locale {
  Sk = 'sk',
  Cs = 'cs',
  En = 'en',
}

export type StringsTranslations = {
  [key: string]: string | Record<string, any>
}

export type Strings = {
  [key in Locale]: StringsTranslations
}

export type TranslationVariables = Record<string, string | number>

export type LocalizeOptions = {
  variables?: TranslationVariables
}

export enum GrammarCaseKey {
  One = 'one',
  Other = 'other',
  Many = 'many',
  For = 'for',
  Whom = 'whom',
}

export type TranslationFunction = (
  path: string,
  variables?: TranslationVariables
) => string
