module.exports = {
  controls: {
    forCompanies: 'For companies',
    viewProfile: 'View profile',
    accountSettings: 'Account preferences',
    registration: 'Registration',
    myCompany: 'My company',
    forFreelancer: 'For freelancers',
  },
}
