module.exports = {
  title: 'PRO členství',
  membership: 'Členství',
  loginAdvantagesTitle: 'Přidej se mezi freelancery a získáš',
  loginAdvantages: `– Vlastní profil zcela zdarma (navždy)
  <br />
  – Místo mezi freelancerskou špičkou v Česku
  <br />
  – Viditelnost u více než {{count}} firem
  <br />
  – Vstupy na konference, meetupy + výhody od našich partnerů`,
  noFreelancerFound: 'Žiadny freelancer nebol nájdený.',
  companyUpsellHeading: 'Nejlepší <br/>databáza <br/>freelancerů',
  hpUpsellHeading: 'Objev <br/>přetlak {{count}} <br/>freelancerů',
  hpUpsellHeadingWithoutNumber: 'Objev <br/>přetlak <br/>freelancerů',
  showAllCategoryWithCount: {
    developeri: 'Zobrazit všech {{count}} developerů',
    dizajneri: 'Zobrazit všech {{count}} designérů',
    marketeri: 'Zobrazit všech {{count}} marketérů',
    nontech: 'Zobrazit všech {{count}} non-tech',
    kreativci: 'Zobrazit všech {{count}} kreativců',
  },
  showAllCategory: {
    developeri: 'Zobrazit všechny developery',
    dizajneri: 'Zobrazit všechny designéry',
    marketeri: 'Zobrazit všechny marketéryv',
    nontech: 'Zobrazit všechny non-tech',
    kreativci: 'Zobrazit všechny kreativce',
  },
  addFavourite: 'Přidat mezi oblíbených',
  removeFavourite: 'Odstranit z oblíbených',
  showAllCount: 'Zobrazit všech {{count}}',
  showAll: 'Zobrazit všechny',
  showFreelancers: 'Zobrazit freelancery',
  weAreSadThatYouAreLeaving:
    'Je nám líto, že odcházíš, ale takový už je život. Držíme palce a kdyby něco, víš, kde nás najdeš.',
  contactToFreelancerInMail: 'Zpráva freelancerovi byla úspěšně odeslána!',
  openingPrice: 'ZAVÁDĚCÍ CENA',
  displayForOneMonth: 'Zobrazení na 1 měsíc',
  showAllFreelancers: 'Zobrazení všech freelancerů',
  uncoverSelectedCategory: 'Odkrytí jen 1 vybrané kategorie',
  currentlyUnavailable: 'Momentálně nedostupné',
  unlockFreelancers: 'Odemknout freelancery!',
  unlockFreelancersNumber: '{{count}} freelancerů, zdarma!',
  unlockFreelancersModalDescription:
    'Zobrazení dalších freelancerů je podmíněno přihlášením nebo registrací.',
  unlockFreelancersModalText: 'Pokud již máte přístup zakoupen',
  unlockFreelancersCta: 'Odemknout freelancery',
  next: 'Další',
  contact: 'Kontaktovat',
  favourite: {
    title: 'Oblíbený',
    titleMany: 'Oblíbený',
    empty:
      'Zatím tady nic nemáte. Klepnutím na tlačítko ❤️ u profilu freelanceru ho přidáte do oblíbených.',
  },
  bonus: {
    promoCodeExpiry: 'Platnosť promokódu',
    headline1: 'Sleva 30 %',
    headline2: 'Sleva ke studiu',
    headline3: '3 měsíce hostingu ZDARMA',
    headline4: 'Lifetime sleva 15 %',
    headline5: 'Sleva 15 % na FLEXI/FIX DESK',
    item1:
      'Začni fakturovat rychle a moderně. S Billdou to je hračka. Nyní i se slevou 30% na všechny plány.',
    item2:
      'Absolvuj 130 hodin marketingových tipů a rad. Přihlas se na pololetní studium s 10% slevou nebo roční MBA studium s 5% slevou. Studuj kdykoli a kdekoli.',
    item3:
      'Spusť svůj web ještě dnes. Při objednávce The Hosting Start (100 GB) na 1 rok získáš 3 měsíce navíc.',
    item4:
      'Trovi může být tvůj poslední nástroj pro fakturaci, trackování projektů a pro další mzdové a právní služby. Pojď ho vyzkoušet i s naší exkluzivní lifetime slevou – 15%.',
    item5:
      'Hledáš kvalitní prostor pro tvou práci? Přidej se do jedinečné HubHub komunity i s 15% slevou! Stačí, když klikneš na tlačítko níže a požádáš o FLEXI/FIX DESK členství s promokódem.',
  },
  statusText: {
    waiting: '🕔 Čeká na schválení',
    unpublished: '⛔️ Skrytý',
    banned: '⛔️ Zamítnutý',
    online: '✅ Online',
  },
  added: 'Přidán',
  seen: 'Zobrazen',
  categoryName: {
    marketeri: {
      one: 'marketér',
      other: 'marketéri',
      many: 'marketérů',
      for: 'marketéry',
      whom: 'marketéra',
    },
    developeri: {
      one: 'developer',
      other: 'developeri',
      many: 'developerů',
      for: 'developery',
      whom: 'developera',
    },
    kreativci: {
      one: 'kreativec',
      other: 'kreativci',
      many: 'kreativců',
      for: 'kreativce',
      whom: 'kreativce',
    },
    dizajneri: {
      one: 'designér',
      other: 'designéri',
      many: 'designérů',
      for: 'designéry',
      whom: 'designéra',
    },
    nontech: 'non-tech',
    other: 'Jiný',
  },
  hiddenProfile: 'Skrytý profil',
  picture: 'Foto',
  background: 'Vybrat barvu profilu',

  proModal: {
    title: 'Využívej PRO členství naplno!',
    description: 'Přidej svou fotografii, video a změň barvu svého profilu.',
    submit: 'Dokončit',
  },

  invisibleProfileNotebox: {
    heading: 'Neviditelný profil. Teď tě nikdo nemůže najít!',
    text: 'Tvůj profil se zobrazuje pouze lidem, kterým pošleš odkaz. Přejdi na PRO a uvidí tě všichni.',
  },
  invisibleProfile: 'Neviditelný profil',
  pro: {
    label: '🔥 PRO',
    discountPill: 'SLEVA -25 % Platí jen do konce roku!',
    activateFor: 'Aktivovat za {{price}}',
    monthlyPayment: 'Měsíční platba?',
    name: 'Freelancer',
    editSubscription: 'Upravit členství',
    editSubscriptionLong: 'Upravit PRO členství',
    microsite: {
      title: 'Přidej se do seznamu freelancerů',
      featuresTitle: 'S PRO ČLENSTVÍM<br />ZÍSKÁŠ',
      description:
        'S PRO členstvím čerpáš benefity, které tě dostanou do popředí.<br />Více očí, více práce, více peněz.',
      features: {
        web: {
          title: 'Zveřejnění na webu',
          description:
            'S PRO členstvím tě uvidí všichni, protože tvůj profil je veřejně zobrazen na webu. Profil ve FREE verzi je viditelný pouze prostřednictvím odkazu.',
        },
        photo: {
          title: 'Fotka v profilu',
          description:
            'První dojem je jen jeden. Úsměv, vážná tvář nebo GIF-ko? Je to na tobě.',
        },
        video: {
          title: 'Video v profilu',
          description:
            'Stále je lepší jednou vidět, než stokrát číst. Přidej do svého profilu video a ukaž všem, co děláš!',
        },
        ppc: {
          title: 'PPC kampaň',
          description:
            'Tvůj profil bude propagován i v rámci online kampaní. Tak tě uvidí potenciální klienti i mimo naší webové stránky.',
        },
        top: {
          title: 'TOP zobrazení na webu',
          description:
            'Tvůj profil se zobrazí na TOP pozici na úvodní stránce. Firmy tak budou mít tvůj profil ihned na očích.',
        },
        social: {
          title: 'Propagace na sociálních sítích s více než 22k fanoušky',
          description:
            'Věnujeme ti pořádný tlak na našem Facebooku, Instagramu a LinkedIn. Tvůj profil představíme formou příspěvků a stories.',
        },
        email: {
          title: 'Dostáváš nabídky na e-mail',
          description:
            'Nové nabídky na freelance práce ti chodí přímo na e-mail, takže ti žádná spolupráce neuteče.',
        },
        color: {
          title: 'Podfarbení profilu',
          description:
            'Odliš svůj profil od ostatních a podfarbi si ho jak jen chceš. Čím větší úlet, tím lépe.',
        },
      },
      plans: {
        title: 'Více práce, více pláce!',
        text: 'Přejdi na PRO a získej více! Čerpej z benefitů, které tě dostanou do popředí. Jdeš do toho?',
        text2: 'Jdeš do toho?',
      },
    },
    pricing: {
      description: 'Stejně zaplatíš za 1 espresso',
      month: 'měsíc',
      year: 'rok',
      badge: '10x více vidění profilu',
      cta: 'Chci být PRO!',
      ctaSubscribed: 'Aktivní',
      features: {
        web: 'Zveřejnění na webu',
        photo: 'Fotka v profilu',
        video: 'Video v profilu',
        ppc: 'PPC kampaň',
        top: 'TOP zobrazení na webu',
        social: 'Promo na sociálních sítích',
        color: 'Podfarbení profilu',
      },
      noteFree: 'Profil je viditelný pouze prostřednictvím odkazu',
      noteMonthly: 'Pravidelná platba 1-krát za měsíc',
      noteYearly: 'Při roční platbě {{price}}',
      noteYearlyWithSale:
        'Při roční platbě <span class="strike">{{priceOld}}</span> {{price}}',
    },
  },
  upsell: {
    heading: 'Až {{count}} <br/>freelancerů <br/>ve hře!',
    text: 'Hledáte marketéra, developera či designéra pro svůj projekt? Získejte přístup do největší databáze freelancerů na trhu již od {{price}}&nbsp;€/měsíc',
    detail: {
      note: 'Přejdi na PRO a získej více! Čerpej z benefitů, které tě dostanou do popředí.',
      cta: 'Zobraz více info',
    },
    menu: 'Získej více!',
    profile: 'Získej více jen za {{price}}€/měsíc',
  },
  switcher: {
    monthly: 'Měsíc',
    yearly: 'Rok',
    note: 'Ušetřete 50%',
  },
  testimonial: {
    1: {
      text: 'Zkušenost s Přetlakem mám nanejvýš skvělou! Za krátký čas mi přišlo několik relevantních poptávek. Velmi užitečná je i funkce dostupný/nedostupný, kterou si umím snadno upravit podle mých aktuálních kapacit.',
      textShort:
        'S Pretlakem mám velmi skvělé zkušenosti! Během krátké doby mi přišlo několik relevantních poptávek.',
      name: 'Daniel Biras',
      position: 'PPC Specialist',
    },
    2: {
      text: 'Díky Přetlaku vás může spatřit velmi mnoho lidí z kreativního průmyslu i mimo něj. Doporučuji PRO členství alespoň zkusit. Narůstající klientela vás bude motivovat a vaše portfolio se bude rozpínat jak kvantitou, tak kvalitou - což vás na konci dne povzbudí ještě víc.',
      textShort:
        'Díky Pretlaku vás může vidět velké množství lidí z kreativního průmyslu i mimo něj.',
      name: 'Karolína Uherčíková',
      position: 'Ilustrátorka / designérka',
    },
    3: {
      text: 'Za velmi krátkou dobu se mi díky PRO členství ozvalo několik relevantních firem.',
      textShort:
        'Za velmi krátkou dobu se mi díky PRO členství ozvalo několik relevantních firem.',
      name: 'Michal Slovák',
      position: 'Branding and Packaging',
    },
  },
  upsellHowToGoodProfile: {
    strong: 'Psst.',
    regular:
      'Jestli chceš nadupat svůj profil freelancera, čekuj tento rychlý návod v našem Magazínu.',
  },

  freelancersUnlock: {
    yearly: {
      title: 'Komplet rok',
    },
    monthly: {
      title: 'Komplet měsíc',
    },

    benefits: {
      unlock: 'Přístup k {{count}} freelancerům',
      email: 'Noví freelanceri emailem',
      cancel: 'Možnost kdykoliv zrušit',
    },
  },

  contactModal: {
    emailLabel: 'Váš e-mail',
    emailPlaceholder: 'Sem zadejte váš e-mail',
    textareaLabel: 'Text zprávy',
    textareaPlaceholder:
      'Sem napište zprávu pro freelancera, popis projektu, nabízenou odměnu apod.',
  },

  companyProfileUpsell: {
    title: 'Objevte přetlak {{count}}+ freelancerů!',
    text: 'Vyberte okamžitě z množství dostupných freelancerů!',
    cta: 'Zobrazit freelancery',
  },

  skillLevel: {
    plain: {
      graduate: 'Absolvent',
      junior: 'Junior',
      mid: 'Mid',
      senior: 'Senior',
      expert: 'Expert',
      standard: '',
    },
    detail: {
      graduate: '',
      junior: '(1 - 2 roky)',
      mid: '(3 - 4 roky)',
      senior: '(5 - 8 let)',
      expert: '(9+ let)',
      standard: '',
    },
  },

  profileModal: {
    title: 'Topuj svůj profil!',
    subtitle: '🔝Vystřel se až na vrch seznamu',
    description:
      'Je to rychlé a jednoduché. Stačí klik a tvůj profil se zobrazí úplně nahoře pod pozicí "doporučujeme", dokud tě nepřeskočí někdo jiný. 🚀',
  },
  availabilityStatus: {
    'not-available': 'Nedostupný',
    'available-less-30-hours': 'Méně než 30 hodin / týden',
    'available-more-30-hours': 'Více než 30 hodin / týden',
    'available-agreement': 'Podle potřeby',
    'full-time-available': 'K dispozici na full-time / kontrakt',
  },
  openToFullTime: 'K dispozici i na full-time / kontrakt',
  openToFullTimeShort: 'Full-time / kontrakt',
  proOfferModal: {
    title: 'Tvůj profil je neviditelný',
    description: 'Všem se zobrazí jen tehdy, když přejdeš na PRO členství',
  },

  upsellTop: {
    title: 'Topuj svůj profil!',
    description: 'Vystřel se až na vrch seznamu! Je to rychlé a jednoduché.',
  },

  topProfileModal: {
    title: 'Topuj profil!',
    product1: 'Topuj na webu',
    product2: 'Sdílet na sociálních sítích',
    product3: 'Zvýraznit profil',
    proDescription: 'Získej to všechno jen za 4,99€/měsíc. Přejdi na PRO!',
    tooltip: {
      product1:
        'Tvůj profil se zobrazí úplně nahoře pod pozicí "doporučujeme", dokud tě nepředběhne někdo jiný.',
      product2:
        'Tvůj profil ukážeme více než 20-tisíc lidem přes náš Facebook, Instagram a LinkedIn.',
      product3:
        'Odliš svůj profil od ostatních a podbarvi si ho jakoukoli barvou. Čím větší úlet, tím lépe.',
      product2Default:
        'Dostupné pouze pro PRO freelancery! Tvůj profil ukážeme více než 20-tisíc lidem přes náš Facebook, Instagram a LinkedIn.',
    },
  },
  activeNow: 'Teď aktivní',
  availabilityModal: {
    description: 'Dej firmám vědět, kolik máš aktuálně volných kapacit.',
  },
}
