module.exports = {
  noJobsYetContinueWithAddJob:
    'Nothing here yet. Continue by clicking the "Add job" button.',
  similarJobs: 'Similar jobs',
  activeOffers: 'Active offers',
  closedOffers: 'Closed offers',
  viewCandidates: 'Show candidates',
  contactEmail: 'Contact email',
  whatDoWeOffer: 'Benefits',
  whatDoWeOfferPlaceholder:
    "Do you offer company breakfasts, a pet-friendly office, or unlimited vacations? After all, money isn't everything. (Bullet points!)",
  addJobBadge: 'Infinite offers',
  companyNameLabel: 'Company name',
  companyNamePlaceholder: 'Pretlak - do not use inc. etc.',
  companyNameTooltip:
    'Enter the name of your brand / agency / company as will be shown in the offer',
  jobCategoryLabel: 'Job category',
  remotePositionLabel: 'Remote position',
  colabFormLabel: 'Employment type',
  salaryInGrossLabel: 'Salary',
  mentionOnlyLowerSalaryLimit: "I've only entered the low of possible salary range",
  salaryNoteLabel: 'Note on salary',
  payOffTypeLabel: 'Payment type',
  scopeOfWorkPlaceholder:
    "What kind of work awaits the applicant? Write in bullet points, straightforwardly and simply. You'll discuss details at the interview.",
  requirementsPlaceholder:
    'What education, skills, or favorite animal should your future applicant have? Write all expectations here (in bullet points). Do not provide your phone number or email address!',
  republishJobDescription:
    "You're just 1 step from making your offer valid again. Just choose - STANDARD or PREMIUM? We'll check and publish it within 24 hours",
  cvErrorTitle: 'Oops, an error has occurred.',
  requestNotSendTryAgain: 'Your application has not been sent. Please try again.',
  requestSuccessfullySent: 'Application successfully sent!',
  cvSuccessDescription:
    'The selection procedure can last for several weeks. Please be patient while waiting for an answer.',
  problemHasOccured: 'There was an error!',
  editErrorDescription:
    'There was an error while saving your changes, please, try again. If the problem persists, please contact support',
  editNotAllowedDescription:
    'Insufficient rights to edit this offer. Please check your sign in. If the problem persists, please, contact support at',
  goodJobTitle: 'Good job!',
  editSuccessDescription:
    'Your offer has been successfully changed. You can edit the offer at any time, before or after publishing it. The URL address where you can edit the offer is in the email confirming the approval of the offer.',
  republishUnknownTitle: 'Hmm, we encountered an error!',
  republishUnknownDescription:
    "Seems like duplicating won't be possible. This link is probably connected to another company profile. Please try again or continue by signing in with different account.",
  addJobDescription:
    'Choose the offer type. We will check it and publish it within 24 hours. Get an infinite offer duration, high relevance of CVs and increase in company awareness in our community! Ready?',
  lookIntoUnpublishedOffer: 'Preview of unpublished offer',
  tenderHasBeenClosed: 'Interview process has ended',
  offerHasExpired: 'Offer validity has ended.',
  offerIsPaused: 'The offer is paused',
  higherSalaryAttractMoreCandidates: 'Bigger salary will attract more candidates.',
  salaryNoteText:
    'Based on our stats the average compensation for this position starts from {{value}} € (full-time). Raise it a little.',
  upgradeJobUpsellHeading: 'Actually we have {{count}} job offers for {{categoryName}}',
  upgradeJobUpsellText:
    'Change the offer type to PREMIUM. offer will be more visible and its reach will be bigger by 50 %.',
  closeJob: 'Close job',
  pauseJob: 'Pause job',
  pauseJobDescription:
    'Your offer will not be shown temporarily. All details will be still available in your company profile.',
  statusText: {
    waiting: '🕔 Awaiting',
    expired: '⚠️ Expired',
    unpublished: '🔕 Paused',
    banned: '⛔️ Rejected',
    closed: '🔒 Closed',
    online: 'Online',
    draft: '✏️ Draft',
  },
  shareOffer: 'Share offer',
  closeJobDescription:
    "Your offer will be closed and won't be shown on the web. All details will be still available in your company profile.",
  contactEmailTooltip: 'This e-mail is used to send notifications about new applicants.',
  contactTelephone: 'Contact telephone number',

  candidateHasAddedNote: 'The candidate has added a note',
  candidateUpsellText:
    "Shhh. Check out our Freelancers. You'll find {{count}} people waiting for you.",
  approvalEmailSentToCandidate: 'An email has been sent to the candidate to be received.',
  approvalEmailSentToCandidateDate:
    'An email has been sent to the candidate to be received. ({{date}})',
  rejectionEmailSentToCandidate: 'A rejection email has been sent to the candidate.',
  rejectionEmailSentToCandidateDate:
    'A rejection email has been sent to the candidate. ({{date}})',
  doNotBeWorryClickButton: 'Don’t worry the cadidate won’t get notified.',
  cooperationFormShort: 'Form',
  todayOnly: 'Today only!',
  daysLeft: {
    one: 'Just {{count}} days',
    other: '{{count}} more days',
    many: '{{count}} more days',
  },
  jobLevelName: {
    premium: 'Premium',
    exclusive: 'Exclusive',
    standard: 'Standard',
  },
  candidateDefaultResponseMessage: `Hi {{candidateName}}.[[nl]][[nl]]Thank you for your interest in working for the position {{jobPosition}}. {{defaultMessage}}[[nl]][[nl]]Best regards, Team {{teamName}}`,
  jobFormEdit: 'Edit',
  jobFormPreview: 'Preview',
  currency: 'Currency',
  upsellHowToJobPost: {
    strong: 'Do you want to write an offer that catches the eye?',
    regular: 'We prepared a simple guide on how to do it',
  },
  refreshModal: {
    title: `Your job post is in <br/>the {{num}}. position!`,
    titleAlt: `Your job post is in <br/>the 99+ position!`,
    description: `The job offer <strong> {{jobName}} </strong> has been renewed. <br/>You can boost your offer or share it extra, and thus reach several new candidates!`,
  },
  removeDraftModal: {
    title: 'Delete job post?',
    description:
      'By confirming the button, your ongoing offer will be deleted. This step cannot be undone.',
    submit: 'Yes, delete',
    decline: 'No, I do not want to delete',
  },
  lowSalaryLimit: 'Lower limit',
  highSalaryLimit: 'Upper limit',
  promocodeActiveDaysjInfo: {
    one: 'Offer package active. {{count}} day left.',
    other: 'Offer package active. {{count}} days left.',
    many: 'Offer package active. {{count}} days left.',
  },
  promocodeActiveJobsInfo: 'Offer package active. {{count}} offers left.',
  addonsDiscount: '25% discount on everything',
}
