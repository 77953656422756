import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from '@redux-devtools/extension'
import promise from 'redux-promise-middleware'
import * as Sentry from '@sentry/react'
import { NODE_ENV } from 'utils/helpers'
import { initialStoreState, IStoreState, rootReducer } from './reducers'

const middlewares = [thunk, promise]

export const isStoreMissingInitData = (state) => {
  if (!state.user) {
    return true
  }

  if (state.user.role === 'user' && !state.profile) {
    return true
  }

  if (
    state.user.role === 'standard' &&
    (!state.company || state.jobs === null || state.products === null)
  ) {
    return true
  }

  return false
}

export const isStoreMissingAdditionalData = (state) => {
  if (!state.stats) {
    return true
  }

  return false
}

export const initStore = (state = initialStoreState) => {
  const sentryReduxEnhancer = Sentry.createReduxEnhancer({})
  const middlewareComposer =
    NODE_ENV === 'production'
      ? compose(applyMiddleware(...middlewares), sentryReduxEnhancer)
      : composeWithDevTools(applyMiddleware(...middlewares), sentryReduxEnhancer)

  return createStore<IStoreState, any, {}, {}>(rootReducer, state, middlewareComposer)
}
