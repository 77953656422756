import { FC, ReactElement } from 'react'
import useTranslation from './hooks/useTranslation'
import { TranslationFunction } from './types'

type IProps = {
  children(t: TranslationFunction, { locale }: { locale: string }): ReactElement
}

/*
Note: Used in Class components.

Usage:
<Translate>
  {(t) => (
    <div>{t('yourKey')}</div>
  )}
</Translate>
*/

const Translate: FC<IProps> = ({ children }) => {
  const { t, locale } = useTranslation()

  return children(t, { locale })
}

export default Translate
