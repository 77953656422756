import { NextRouter } from 'next/router'
import { HOST } from 'utils/helpers'
import { GrammarCaseKey, TranslationFunction } from 'translations/types'
import StringUtils from 'utils/string'
import { City, Country, FilterQuery, Tag } from 'types/filter'

export default class OgUtils {
  static getHomepageUrl(router: NextRouter) {
    const language = router.locale === 'sk' ? '' : `/${router.locale}`
    const path = router.asPath === '/' ? '' : router.asPath

    return `${HOST}${language}${path}`
  }

  static getJobCategoriesString(jobCategories, t: TranslationFunction) {
    const categoriesLen = jobCategories.length
    let str = ''

    const categoryName = (category: string) => {
      return StringUtils.capitalize(
        t(`freelancers.categoryName[${category}]`, {
          grammarCase: GrammarCaseKey.Other,
        })
      )
    }

    if (categoriesLen === 1) {
      str += categoryName(jobCategories[0])
    } else {
      jobCategories.forEach((cat, index) => {
        str += categoryName(cat)
        if (index < categoriesLen - 1) {
          str += ', '
        }
      })
    }

    return str
  }

  static getJobTypesString(types) {
    const typesLen = types.length

    if (typesLen === 1) {
      const type = types[0]

      if (type === 'full-time') {
        return 'Full-time'
      }
      if (type === 'part-time') {
        return 'Part-time'
      }
      if (type === 'internship') {
        return 'Internship'
      }
      if (type === 'freelance') {
        return 'Freelance'
      }

      return ''
    } else {
      let str = ''

      types.forEach((type, index) => {
        if (type === 'full-time') {
          str += 'Full-time'
        }
        if (type === 'part-time') {
          str += 'Part-time'
        }
        if (type === 'internship') {
          str += 'Internship'
        }
        if (type === 'freelance') {
          str += 'Freelance'
        }

        if (index < typesLen - 1) {
          str += ', '
        }
      })

      return str
    }
  }

  static getSkillName(skill) {
    let name = ''
    switch (skill) {
      case 'junior':
        name = 'Junior'
        break
      case 'mid':
        name = 'Mid'
        break
      case 'senior':
        name = 'Senior'
        break
    }
    return name
  }

  static getSkillLevelString(types) {
    const categoriesLen = types.length
    let str = ''

    if (categoriesLen === 1) {
      str += this.getSkillName(types[0])
    } else {
      types.forEach((cat, index) => {
        str += this.getSkillName(cat)
        if (index < categoriesLen - 1) {
          str += ', '
        }
      })
    }

    return str
  }

  static getStringFromData(items, data) {
    const length = items.length
    if (length === 1) {
      const city = data.find((city) => {
        return city.slug === items[0]
      })
      return city?.name || ''
    }

    let str = ''

    for (const slug of items) {
      const item = data.find((currItem) => {
        return currItem.slug === slug
      })
      str += item && item.name ? `${item.name}, ` : ', '
    }
    return str.slice(0, -2)
  }

  static getQueryTitle(
    data: {
      query: FilterQuery
      citiesData: City[]
      countriesData: Country[]
      tagsData: Tag[]
      total: number
    },
    t: TranslationFunction
  ) {
    let defaultTitle = t('og.defaultTitle')
    const { query, citiesData, countriesData, tagsData, total } = data
    const { jobCategories, jobTypes, cities, countries, tags, skillLevels } = query

    if (
      jobCategories.length === 0 &&
      jobTypes.length === 0 &&
      cities.length === 0 &&
      countries.length === 0 &&
      tags.length === 0 &&
      skillLevels.length === 0
    ) {
      return defaultTitle
    }

    if (total) {
      defaultTitle = `${t('common.jobsCount', { count: total })} | ${t(
        'og.defaultTitle'
      )}`
    }

    const categories = this.getJobCategoriesString(jobCategories, t)
    const types = this.getJobTypesString(jobTypes)
    const skills = this.getSkillLevelString(skillLevels)
    const city = this.getStringFromData(cities, citiesData)
    const country = this.getStringFromData(countries, countriesData)
    const tag = this.getStringFromData(tags, tagsData)

    if (categories || types || skills || city || country || tag) {
      let title = ''
      if (total) {
        title += `${t('common.jobsCount', { count: total })} | `
      }
      if (categories) {
        title += `${categories} | `
      }
      if (tag) {
        title += `${tag} | `
      }
      if (skills) {
        title += `${skills} | `
      }
      if (city) {
        title += `${city} | `
      }
      if (country) {
        title += `${country} | `
      }
      if (types) {
        title += `${types} | `
      }

      return `${title} Pretlak.com`
    }

    return defaultTitle
  }

  static getCategoryOgImage(query, tagsData, locale: string) {
    const { jobCategories, cities, tags } = query
    let customImage = `${HOST}/images/og/fb-share.png`
    const imagePath = `${HOST}/images/og/categories/${locale}`

    // NOTE: UKRAJINA OG HANDLER
    if (tags.length > 0) {
      const hasUaTag = tags.find((t) => t === 'creativeukraineprogram')

      if (hasUaTag) {
        return `${imagePath}/ua.png`
      }
    }

    if (jobCategories.length > 0) {
      customImage = `${imagePath}/${jobCategories[0]}.png`
    } else if (cities.length > 0 && cities.find((city) => city === 'remote')) {
      customImage = `${imagePath}/remote.png`
    } else if (tags.length > 0) {
      const tag = tagsData.find((t) => {
        return t.slug === tags[0]
      })

      if (tag?.category?.slug) {
        customImage = `${imagePath}/${tag.category.slug}.png`
      }
    }

    return customImage
  }

  static getLogoIdFromUrl(url?: string) {
    if (!url) {
      return null
    }

    const splitted = url.split('/')
    return splitted[splitted.length - 1]
  }
}
