module.exports = {
  introduction:
    "We want to be transparent and that's why we always share current data about our statistics. It's all real, nothing made up 🤟",
  webViewsPerMonth: 'Web views per month',
  jobSearchersPerMonth: 'Job seekers per month',
  sentCVsPerMonth: 'CVs sent per month',
  addedJobsPerMonth: 'Added jobs per month',
  socialNetworkFans: 'Fans on social media',
  newsletterSubscribers: 'Newsletter subscribers',
}
