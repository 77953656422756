import React, { FC } from 'react'
import Head from 'next/head'
import { jsonLdScriptProps } from 'react-schemaorg'
import { Organization } from 'schema-dts'
import { useRouter } from 'next/router'
import { HOST } from 'src/utils/helpers'
import useTranslation from 'translations/hooks/useTranslation'
import { GrammarCaseKey } from 'translations/types'

interface IProps {
  pageConf?: {
    title: string
    app?: string
    description?: string
    context?: string
  }
  originalUrl?: string
}

const LOCALES = {
  sk: 'sk_SK',
  cs: 'cs_CZ',
  en: 'en_US',
}

const SocialMeta: FC<IProps> = ({ pageConf, originalUrl }) => {
  const { t, locale } = useTranslation()
  const router = useRouter()

  const ogImageName = pageConf?.app === 'freelancers' ? 'freelancers-share' : 'share'
  const ogImagePath = pageConf?.app === 'freelancers' ? `og/categories/${locale}` : 'og'

  const title = t(`meta.title[${pageConf?.title ? pageConf.title : 'default'}]`)
  const description = t(
    `meta.description[${pageConf?.description ? pageConf.description : 'default'}]`
  )

  const getUrl = () => {
    const actualUrl = originalUrl || router.asPath || ''
    return `${HOST}${actualUrl}`
  }

  let schemaData: any = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    legalName: 'Pretlak s.r.o.',
    name: 'Pretlak',
    description:
      'Remote, Full-time a Part-time pracovné ponuky pre dizajnérov, developerov, marketérov a kreatívcov.',
    url: 'https://pretlak.com/',
    logo: 'https://pretlak.com/images/favicons/android-chrome-512x512.png',
    sameAs: [
      'https://www.facebook.com/pretlakpretlakpretlak',
      'https://www.instagram.com/pretlakpretlakpretlak',
      'https://www.linkedin.com/company/pretlakpretlakpretlak',
    ],
  }

  if (pageConf?.context === 'index' && !router.query.jobCategories) {
    schemaData['potentialAction'] = {
      '@type': 'SearchAction',
      target: {
        '@type': 'EntryPoint',
        urlTemplate: 'https://pretlak.com/?searchTerm={search__input}',
      },
      'query-input': 'required name=search__input',
    }
  }

  if (pageConf?.context === 'index' && router.query.jobCategories) {
    const categories = router.query.jobCategories

    if (typeof categories === 'string') {
      const splitedCategories = categories.split(',')

      if (splitedCategories.length > 0) {
        schemaData = {
          '@context': 'https://schema.org',
          '@type': 'BreadcrumbList',
        }

        schemaData['itemListElement'] = [
          {
            '@type': 'ListItem',
            position: 1,
            name: t(`freelancers.categoryName[${splitedCategories[0]}]`, {
              grammarCase: GrammarCaseKey.Other,
            }),
            item: `https://pretlak.com/?jobCategories=${splitedCategories[0]}`,
          },
        ]
      }
    }
  }

  return (
    <Head>
      <title>{title + ' | Pretlak.com'}</title>
      <meta name="keywords" content={t('meta.keywords')} />
      <meta name="description" content={description} />
      <meta key="title" property="og:title" content={title} />
      <meta key="description" property="og:description" content={description} />
      <meta key="url" property="og:url" content={getUrl()} />
      <meta
        key="image"
        property="og:image"
        content={`${HOST}/images/${ogImagePath}/fb-${ogImageName}.png`}
      />
      <meta property="og:site_name" content="Pretlak" />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content={LOCALES[locale]} />
      <meta property="fb:app_id" content="395027521327895" />
      <meta key="twitter:title" name="twitter:title" content={title} />
      <meta key="twitter:description" name="twitter:description" content={description} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        key="twitter:image"
        name="twitter:image"
        content={`${HOST}/images/${ogImagePath}/tw-${ogImageName}.png`}
      />

      {/*@ts-ignore*/}
      <script key="json:ltd" {...jsonLdScriptProps<Organization>(schemaData)} />
    </Head>
  )
}

export default SocialMeta
