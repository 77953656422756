module.exports = {
  newTitle: 'New',
  sendUsArticleIdea: 'Send us a TIP on an article',
  relatedPostsTitle: 'Similar articles',
  thankForArticle: 'Say thanks for the article',
  latestPosts: 'Magazine articles',
  showAllArticles: 'Show all articles',
  noArticleFound: 'No article has been found..',
  newsletter: {
    title: 'Magazine Newsletter',
    subtitle: "Interviews, news or inspiration. You'll find all of them in your mailbox.",
  },
  boxTitle: `Article
    <br />
    in our
    <br />
    magazine`,
  article: 'article',
  readersWithCount: '{{count}} readers',
  boxText:
    "Do you need to increase awareness about your brand or company? Do you want to show candidates the inner workings of your company, your company's culture and increase your attractivity? We will help you...",
  mailTo: {
    subject: 'I am interested in an article in the magazine 📰',
    body: 'Greetings Pretlak, %0D%0A %0D%0A We would like to order an Article in the magazine from you 📰 . %0D%0A %0D%0A Thank you very much. You are the best! %0D%0A %0D%0A Sincerely,',
  },
}
