import { Reducer } from 'redux'
import { GeneralAction } from 'redux/action-creators/general'
import { IGeneral } from 'redux/interfaces/general'

export const defaultGeneralState: IGeneral = {
  prevPageContext: null,
  cookiesVisible: false,
}

const general: Reducer<IGeneral, GeneralAction> = (
  state = defaultGeneralState,
  action
) => {
  switch (action.type) {
    case 'SET_GENERAL_PREV_PAGE_CONTEXT':
      return {
        ...state,
        prevPageContext: action.payload,
      }
    case 'SET_GENERAL_COOKIES_VISIBLE':
      return {
        ...state,
        cookiesVisible: action.payload,
      }
    default:
      return state
  }
}

export default general
