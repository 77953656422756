var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"4.7.1"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs'
import { CaptureConsole, HttpClient } from '@sentry/integrations'
import { DEV_ENV, SENTRY_DSN, APP_VER, CURRENT_ENV } from 'server/constants'
import sentrySharedConfig from './sentry.shared.config'

Sentry.init({
  ...sentrySharedConfig,
  enabled: process.env.NODE_ENV !== DEV_ENV,
  debug: process.env.NODE_ENV !== 'production',
  dsn: SENTRY_DSN,
  release: APP_VER.toString(),
  environment: CURRENT_ENV,
  integrations: [
    new CaptureConsole({ levels: ['error'] }),
    new HttpClient({
      failedRequestStatusCodes: [[500, 505], 507],
    }),
  ],
  sendDefaultPii: true,
})
