import { IProfile } from 'redux/interfaces/profile'
import {
  PROFILE_FETCH,
  PROFILE_PARTIAL_UPDATE,
  PROFILE_UPDATE,
} from 'redux/managers/profile'

export const defaultProfileState: IProfile = null

const user = (state = defaultProfileState, action) => {
  switch (action.type) {
    case PROFILE_FETCH:
    case PROFILE_UPDATE:
      return action.payload
    case PROFILE_PARTIAL_UPDATE:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export default user
