module.exports = {
  company: {
    subtitle: "Enter your company details once and we won't ask for them again.",
    form: {
      companyNameLabel: 'Company name (including inc. etc.)',
      iAmPersonalAgency: "I'm a personnel agency",
      iAmOutsideEu: "I'm a company outside EU",
      streetLabel: 'Street and street number (invoice address)',
      emailTooltip: 'This email serves as login for company profile.',
    },
  },
  freelancer: {
    subtitle: 'Be a part of the top freelance community and join us. For free!',
    fillTheForm: 'Fill in the form and be creative with your profile',
    beVisibleForCompanies: 'Be seen by more than {{count}} companies',
    offersToEmail: 'Job offers will be sent to you by email',
    form: {
      salaryNoteLabel: 'Note about hourly rate',
      aboutMeLabel: 'About me',
    },
    topBadgeTitle: 'quality profile badge',
    topBadgeText:
      'Spend some time on your profile and increase your chances of attracting a prospective client. The best profiles get our TOP badge.',
  },
  doYouHaveAccountAlready: 'Have you already created your account?',
}
