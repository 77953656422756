module.exports = {
  'job-edit-ok': 'Your job offer has been successfully changed.',
  'company-edit-ok': 'Company details has been successfully changed.',
  'profile-edit-ok': 'Profile has been successfully changed.',
  'settings-edit-ok': 'Password has been successfully changed.',
  'notification-edit-ok': 'Email preferences were successfully changed.',
  'notification-edit-ko': 'There was an error while saving email changes.',
  'signup-ok': 'Your registration was successful. Welcome!',
  registrationSuccess: 'Your registration was successful. Welcome!',
  urlOfPostWasCopied: 'Offer URL has been copied!',
  postWasSuccessfullyEdited: 'The offer has been successfully updated!',
}
