import { get } from 'utils/axios'

export const PROMOCODES_FETCH = 'PROMOCODES_FETCH'

export const getPublicData = (data: any) => {
  if (!data.promoCodes) {
    return null
  }

  return data.promoCodes.filter((promoCode) => {
    const isUsageType = typeof promoCode.usage_limit === 'number'
    const remaining = isUsageType && promoCode.usage_limit
    const isDateActive = promoCode.end_date && new Date(promoCode.end_date) > new Date()

    if ((isUsageType && remaining === 0) || (promoCode.end_date && !isDateActive)) {
      return false
    }

    return true
  })
}

export const getCompanyPromoCodes =
  (companyId: string) =>
  async (
    dispatch
  ): Promise<{ success: boolean; promoCodes?: any; error?: string; msg?: string }> => {
    try {
      const { data } = await get(`api/promo-code/company/${companyId}`)

      if (data.status === 'ok') {
        dispatch({ type: PROMOCODES_FETCH, payload: getPublicData(data) })
        return { success: true, promoCodes: data.promoCodes }
      } else {
        return { success: false, error: data.error, msg: data.msg }
      }
    } catch (err) {
      return { success: false, error: err }
    }
  }
