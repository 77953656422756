import ProductUtils from 'utils/product'
import JobUtils from 'utils/job'
import { NODE_ENV, logEvents } from './helpers'
import type { IProfile } from 'redux/interfaces/profile'
import type { IJob } from 'redux/interfaces/job'
import type { ICompany } from 'redux/interfaces/company'

declare const window: {
  dataLayer?: any
  gtag?: any
}

interface IItem {
  type: string
  properties: {
    [key: string]: any
  }
}

export default class TrackingUtils {
  static event(
    event_tracking_name: string,
    properties: {
      event_action?: string
      event_data1?: any
      event_data2?: any
      event_data3?: any
      event_data4?: any
      [key: string]: any
    } = {}
  ) {
    const { event_action, event_data1, event_data2, event_data3, event_data4 } =
      properties

    TrackingUtils.dataLayerPush('event_tracking', {
      event_tracking_name,
      event_action: event_action || '',
      event_data1: event_data1 || '',
      event_data2: event_data2 || '',
      event_data3: event_data3 || '',
      event_data4: event_data4 || '',
    })
  }

  static dataLayerPush(event: string, properties?: { [key: string]: any }) {
    const trackingProps = {
      ...properties,
      event,
      job_pagetype: event,
    }

    if (NODE_ENV !== 'production') {
      logEvents('gtm', '#01AD01', ['dataLayer#push', '\n', event, trackingProps])
    }

    if (window.dataLayer) {
      window.dataLayer.push(trackingProps)
    }
  }

  static updateConsent({
    analytics,
    ad,
    wait_for_update,
  }: {
    analytics: boolean
    ad: boolean
    wait_for_update?: number
  }) {
    let data = {
      // required
      functionality_storage: 'granted',
      security_storage: 'granted',
      // optional
      ad_storage: 'denied',
      ad_user_data: 'denied',
      ad_personalization: 'denied',
      analytics_storage: 'denied',
      personalization_storage: 'denied',
    }

    if (wait_for_update) {
      data['wait_for_update'] = wait_for_update
    }

    if (analytics) {
      data['analytics_storage'] = 'granted'
    }
    if (ad) {
      data['ad_storage'] = 'granted'
      data['ad_user_data'] = 'granted'
      data['ad_personalization'] = 'granted'
      data['personalization_storage'] = 'granted'
    }

    if (NODE_ENV !== 'production') {
      logEvents('gtm', '#01AD01', [
        'dataLayer#updateConsent',
        '\n',
        'consent',
        'update',
        data,
      ])
    }

    if (window.gtag) {
      window.gtag('consent', 'update', data)
    }
    TrackingUtils.trackConsent({ analytics, ad })
    TrackingUtils.dataLayerPush('cookie_consent_update', data)
  }

  static trackConsent({ analytics, ad }: { analytics: boolean; ad: boolean }) {
    let data = {
      // required
      functionality_storage: 'granted',
      security_storage: 'granted',
      // optional
      ad_storage: 'denied',
      ad_user_data: 'denied',
      ad_personalization: 'denied',
      analytics_storage: 'denied',
      personalization_storage: 'denied',
    }

    if (analytics) {
      data['analytics_storage'] = 'granted'
    }
    if (ad) {
      data['ad_storage'] = 'granted'
      data['ad_user_data'] = 'granted'
      data['ad_personalization'] = 'granted'
      data['personalization_storage'] = 'granted'
    }

    TrackingUtils.dataLayerPush('cookie_consent_update', data)
  }

  static getUnifiedJobTrackingData(job: any) {
    let jobProps = {}

    jobProps['job_id'] = String(job._id)

    const locationLength = job.job_location.length
    let location = ''

    job.job_location.forEach((loc, index) => {
      if (index < locationLength - 1) {
        location += `${loc.name}, `
      } else {
        location += loc.name
      }
    })

    jobProps['job_locid'] = location
    jobProps['job_category'] = job.job_category.name

    return jobProps
  }

  static getUnifiedFreelancerTrackingData(profile: IProfile) {
    let jobProps = {}

    jobProps['job_id'] = String(profile._id)
    jobProps['job_locid'] = profile.city?.name
    jobProps['job_category'] = `Freelanceri_${profile.job_category?.name}`

    return jobProps
  }

  static getTransactionProductName(code: string) {
    switch (code) {
      case 'ADDON_SOCIAL_SHARE':
        return 'extra-share'
      case 'ADDON_TOP':
        return 'top'
      case 'ADDON_TOP_LONG':
        return 'top-long'
      case 'ADDON_CUSTOM_COLOR':
        return 'custom-color'
    }
  }

  static getTransactionProducts(
    items: IItem[],
    data: {
      job: IJob | null
      jobFormData: { [key: string]: any } | null
    }
  ) {
    const transactionProducts = []

    items.forEach((item: IItem) => {
      const product = ProductUtils.getProductByCode(item.type)

      if (!product) {
        if (item.type === 'CV_SEND') {
          transactionProducts.push({
            item_id: 'cv',
            name: 'job',
            price: 0,
            quantity: 1,
            category: data.job?.job_category.slug || 'n/a',
            brand: data.job
              ? ProductUtils.getJobInfo(data.job.job_level.toString())?.code
              : 'n/a',
          })
        } else if (item.type === 'FREELANCER_INTEREST') {
          transactionProducts.push({
            item_id: 'freelancer',
            name: 'interest',
            price: 0,
            quantity: 1,
            category: item.properties.profile?.email || 'n/a',
            brand: 'n/a',
          })
        } else if (item.type === 'FREELANCER_CONTACT_NOT_PAID') {
          transactionProducts.push({
            item_id: 'freelancer',
            name: 'contact-not-paid',
            price: 0,
            quantity: 1,
            category: item.properties.profile?.email || 'n/a',
            brand: item.properties.companyType || 'n/a',
          })
        } else if (item.type === 'FREELANCER_CONTACT_PAID') {
          transactionProducts.push({
            item_id: 'freelancer',
            name: 'contact-paid',
            price: 0,
            quantity: 1,
            category: item.properties.profile?.email || 'n/a',
            brand: 'n/a',
          })
        }
      } else if (product.type === 'ADDON') {
        transactionProducts.push({
          item_id: 'addon',
          name: TrackingUtils.getTransactionProductName(product.code),
          price: product.price,
          quantity: 1,
          category: data.job?.job_category.slug || 'n/a',
          brand: data.job
            ? ProductUtils.getJobInfo(data.job.job_level.toString())?.code
            : 'n/a',
        })
      } else if (product.type === 'JOB') {
        transactionProducts.push({
          item_id: 'job',
          name: JobUtils.getJobLevelName(Number(data.jobFormData.jobLevel)),
          price: product.price,
          quantity: 1,
          category: data.jobFormData?.jobCategory || 'n/a',
          item_variant: data.jobFormData?.skillLevels || 'n/a',
        })
      } else if (product.type === 'PACKAGE') {
        transactionProducts.push({
          item_id: data.jobFormData ? 'package+job' : 'package',
          name: product.text,
          price: product.price,
          category: 'n/a',
          quantity: 1,
        })
      } else if (product.type === 'TALENTBOX') {
        transactionProducts.push({
          item_id: 'talentbox',
          name: product.text,
          price: product.price,
          category: 'n/a',
          quantity: 1,
        })
      } else if (product.type === 'FREELANCERS') {
        transactionProducts.push({
          item_id: 'freelancers',
          name: product.text,
          price: product.price,
          category: 'n/a',
          quantity: 1,
        })
      } else if (product.type === 'SUBSCRIPTION_FREELANCERPROFILE') {
        transactionProducts.push({
          item_id: 'subscription-freelancer-profile',
          name: product.text,
          price: product.price,
          category: 'n/a',
          quantity: 1,
        })
      }
    })

    return transactionProducts
  }

  static transaction(data: {
    transactionId: number
    company?: ICompany
    price: number
    priceWithoutTax: number
    tax: number
    items: IItem[]
    job?: IJob | null
    jobFormData?: { [key: string]: any } | null
  }) {
    const transactionProducts = TrackingUtils.getTransactionProducts(data.items, {
      job: data.job || null,
      jobFormData: data.jobFormData || null,
    })

    TrackingUtils.dataLayerPush('ec.transaction', {
      transactionId: data.transactionId,
      transactionAffiliation: data.company?.name || 'n/a',
      transactionShipping: 0,
      transactionTotal: data.price,
      transactionTax: data.tax,
      transactionProducts,
    })
  }
}
