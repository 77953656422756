import React from 'react'
import { normalizeHtmlContent } from 'utils/helpers'
import EmojiParser from './EmojiParser'

interface IProps {
  children?: React.ReactNode
  className?: string
  tag?: string
}

const Emoji: React.FC<IProps> = ({ children, className, tag = 'span' }) => {
  return (
    <EmojiParser options={{ className: `emoji-component ${className}` }} tag={tag}>
      {children}
    </EmojiParser>
  )
}

export const EmojiWithContent: React.FC<{
  content: any
  className?: string
  dataCy?: string
}> = ({ content, className, dataCy }) => {
  return (
    <Emoji className={className} tag="div">
      <div
        data-cy={dataCy}
        dangerouslySetInnerHTML={{
          __html: normalizeHtmlContent(content),
        }}
      />
    </Emoji>
  )
}

export default Emoji
