const common = require('./common')
const filter = require('./filter')
const navigation = require('./navigation')
const magazine = require('./magazine')
const testimonials = require('./testimonials')
const freelancers = require('./freelancers')
const label = require('./label')
const pricing = require('./pricing')
const signup = require('./signup')
const stats = require('./stats')
const jobs = require('./jobs')
const notifications = require('./notifications')
const meta = require('./meta')
const invoices = require('./invoices')
const countries = require('./countries')
const manifest = require('./manifest')

module.exports = {
  common,
  filter,
  navigation,
  magazine,
  testimonials,
  freelancers,
  invoices,
  countries,
  manifest,
  newsletter: {
    title: 'Nové nabídky jobů',
    introduction:
      'Každý týden naše Tlačenka přímo do tvého mailboxu. Vyber si kategorii a zadej tvůj e-mail.',
    confirmDesc: `Děkujeme ti za zájem. Nyní ještě musíme potvrdit tvou e-mailovou adresu, ať
    je všechno na parádu. Pokud chceš dokončit tento cool proces, klikni prosím na odkaz
    v e-mailové zprávě, kterou jsme ti právě poslali.`,
    successDesc:
      'Cool, byl jsi přidán do našeho newsletteru. Každý týden můžeš čekat fresh nabídku jobů!',
    confirmTitle: 'Na 99% už to máme...',
  },
  label,
  pricing,
  signup,
  stats,
  jobs,
  notifications,
  meta,
  aboutUs: {
    stat1: 'Založení portálu',
    stat2: 'Hledačů práce',
    stat3: 'Odeslaných CV-ček',
    stat4: 'Přidaných nabídek',
    p1: 'Jsme Pretlak, moderní job portál s novými pracovními nabídkami z oblasti marketingu, designu, kreatívy, IT a souvisejících odvětví.',
    p2: 'Na jedné straně jsou skvělé příležitosti od top firem a na druhé straně jsou relevantní lidé z branže, kteří tyto příležitosti vyhledávají. To vše vám chceme servírovat co nejjednodušeji a bez zbytečných kliků navíc, protože v jednoduchosti je práce. I krása.',
    p3: 'Kromě toho podporujeme kreativní komunitu (sami jsme její součástí), freelancery, práci na dálku a kvalitu před kvantitou.',
    p4: 'Těšíme se, že i díky vám máme přetlak práce!',
  },
  og: {
    defaultTitle: 'Joby pro developery, designéry, marketéry, IT a Remote| Pretlak.com',
  },
  email: {
    welcome: {
      subject: '👋 Dobrá práce!',
    },
    noJobs: {
      subject: '👋 Potřebujete poradit?',
    },
    sendPublished: {
      subject: '✅ Nabídka práce byla schválena',
    },
    sendNotPublished: {
      subject: '⛔️ Nabídka práce nebyla schválena',
    },
    jobApplyUser: {
      subject: '✉️ Tvoje žádost byla odeslána',
    },
    jobApplyCompany: {
      subject1: 'Cool, máte nového zájemce!',
      subject2: 'Máte nového zájemce!',
    },
    jobEndClosed: {
      subject: `🤝 Nabídka byla uzavřena`,
    },
    jobEndExpired: {
      subject1: '🔝 Vaši nabídku můžete topovat',
      subject2: '💎 Prémiové zobrazení skončilo',
      subject3: '👑 Topované zobrazení skončilo',
    },
    jobCloseReminder: {
      subject: '🔔 Nabídka práce je stále aktivní',
    },
    jobCloseLastReminder: {
      subject: '⛔️ Pracovní nabídka bude smazána...',
    },
    jobPaymentReminder: {
      subject: '🧾 Faktura je neuhrazená',
    },
    jobLowPerf: {
      subject: '🔔 Nabídka práce je stále aktivní',
    },
    packageExpired: {
      subject: `📦 Platnost výhodného balíku skončila`,
    },
    forgotPassword: {
      subject: '🔑 Zapomněli jste své heslo?',
    },
    sendFreelancerPublished: {
      subject: '👋 Vítej mezi námi!',
    },
    sendFreelancerAdminContact: {
      subject: '👋 Tvůj profil freelancera potřebuje úpravu',
    },
    sendJobDraftAdminContact: {
      subject: '👋 Tvůj job čeká na dokončení',
    },
    candidateRejection: {
      subject: '😞 Hm, tentokrát to nevyšlo...',
    },
    candidateApproval: {
      subject: '🎉 Dobrá zpráva!',
    },
    failedPayment: {
      subject: '🚨 Platba nebyla úspěšná...',
    },
    invoicePaid: {
      subject: '🚨 Důležité upozornění k faktuře',
    },
    sendNoActiveCompany: {
      subject: '👋 Dávno jsme se neviděli...',
    },
    promocodeWillExpire: {
      subject: `🚨 Platnost promokódu brzy skončí`,
    },
    promocodeStillOnline: {
      subject: `🚨 Máte nevyužité nabídky...`,
    },
    jobDraft: {
      subject: `✏️ Rozpracovaná nabídka...`,
    },
    sendFreelancerContact: {
      subject: '💡 Aha, nový návrh na spolupráci',
    },
    topFreelancer: {
      subject: 'Chceš být na vrcholu seznamu? Topuj svůj profil 👀',
    },
    freelancerAvailability: {
      subject: '👁️ Dlouho jsme se neviděli: Máš volné kapacity?',
    },
  },
  companyModal: {
    heading: 'Hledejte kolegy výhodněji',
    item: 'Zakupte si balík 5 nebo 10 jobů a rozbalte hiring naplno. Je to rychlejší a výhodnější. Vybrat si můžete balíky STANDARD, PREMIUM nebo EXKLUZIV s úsporou až 30 %.',
    cta: 'Zobrazit nabídku',
  },
  loginBanner: {
    pill: 'WOW',
    heading: 'HLEDEJTE <br/>KOLEGY <br/>VÝHODNĚJI',
    text: 'Kupte 5 nebo 10 jobů ve výhodném balíku a ušetřete až 30 %.',
    badge: 'SLEVA 30 %',
    cta: 'Šup, šup',
  },
  profileModal: {
    heading: 'Novinka!',
    subheading: 'Přejdi na PRO a získej více! Jen za {{price}}&nbsp;€/měsíc.',
    cta: 'Opravdu? Zobraz mi více info!',
    items: [
      '🔥 Fotka v profilu',
      '🔥 Video v profilu',
      '🔥 PPC kampaň',
      '🔥 TOP zobrazení na webu',
      '🔥 Promo na sociálních sítích',
      '🔥 Podfarbení profilu',
    ],
  },
  hiringUpsell: {
    titleRaw: 'Nemáte čas ztrácet čas hiringem?',
    title: 'Nemáte čas<br />ztrácet čas<br />hiringem?',
    text: 'Jsem Petr a prozradím vám,<br />jak celý hiring vyřídíme za vás!',
    textOneLine: 'Jsem Petr a prozradím vám, jak celý hiring vyřídíme za vás!',
    cta: 'Napište mi!',
    writeMe: 'Napište mi',
    orCallMe: 'alebo zavolejte',
    smallBannerText: 'Inzerát a celý hiring vyřídíme za vás! Zavolejte',
  },
}
