import { useRouter } from 'next/router'
import { setCookie } from 'nookies'
import { Locale } from 'translations/types'

const useLanguageSwitch = () => {
  const router = useRouter()

  const switchLanguage = (language: Locale) => {
    if (router.locale === language) return

    setCookie({}, 'NEXT_LOCALE', language, {
      maxAge: 356 * 24 * 60 * 60,
    })
    router.push(router.asPath, router.asPath, { locale: language })
  }

  return switchLanguage
}

export default useLanguageSwitch
