module.exports = {
  luigisBox: `Kvalita uchazečů byla 3x větší než přes jiný pracovní portál. Byli to relevantní uchazeči se zkušenostmi, kteří sledují nové trendy, což potvrzuje i to, že přišli přes Pretlak.`,
  slido: `Přes pretlak.com oslovujeme nový segment kvalifikovanějších
  kandidátů a nemusíme přitom zatěžovat náš náborový tým.`,
  berlinBrandsGroup: `Téměř všechny uchazeče, kteří se přihlásí na marketingové pozice přes pretlak.com zveme na pohovor. Kvalita je vysoká – šetříme tím čas při recruitingu.`,
  sygic: `Děláte to dobře. Nahirovali jsme díky vám prvního člověka. Určitě budeme nadále využívat pretlak.`,
  barneyStudio: `Pretlak nám pomohl najít skvělé lidi, kteří skvěle doplnili náš tým. Díky Pretlaku šetříme čas, protože nám nechodí CV od nerelevantních lidí.`,
  twoCreateAdvertising: `Pretlak tvoří lidé, kteří rozumí potřebám kreativního byznysu. Efektivita náboru výrazně překonává konkurenční portály..`,
  imagons: `Relevanci reakcí a kvalitu uchazečů nelze porovnat s jinými portály, odkud se lidé z marketingové branže jakoby vytratili. Za nás velká spokojenost.`,
  darenAndCurtis: `Služby Pretlaku využíváme již několik let a bereme je jako důležitou součást naší HR strategie.`,
  gecora: `Pretlak.sk využíváme při tech i non-tech job postech. Je to náš hlavní kanál, na kterej máme překlik i z webu gecora.com. Je to řešení, kde "se hledají” a kde si hledají job lidé z IT světa, co je z naší skušenosti ideální příležitost pro získaní těch nejkvalitnějších uchazeču.`,
  kesu: `Portál, který nám vydestiloval bláznivě dobré uchazeče. Rychle, levně a kvalitně (a prý to tak nejde).`,
  gymbeam: `Pretlak.sk používáme moc rádi, jelikož je to moderní pracovní portál, kterej nám pomáhá spojit se s komunitou talentovaných kreativců.`,
  tvjoj: `Děkujeme Vám za skvělou práci! Dnes jsem přidala inzerát a již mám 5 životopisů. Ty mi přicházejí také přímo na e-mail. Chtěla bych pochválit Vaši práci, marketingové záležitosti řeším pouze přes Vás. Mám ráda dobré lidi a takové jsem u Vás našla.`,
  ovb: 'Podařilo se nám najít 2 frontendové programátory velmi rychle. Děkujeme za sdílení nabídky, i když ještě nebyla uhrazena, což považuji za velmi ohleduplný krok.',
}
