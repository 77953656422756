module.exports = {
  newTitle: 'Novinka',
  sendUsArticleIdea: 'Pošlite nám TIP na článok',
  relatedPostsTitle: 'Podobné články',
  thankForArticle: 'Poďakuj za článok',
  latestPosts: 'Články z magazínu',
  showAllArticles: 'Zobraziť všetky',
  noArticleFound: 'Žiadny článok nebol nájdený.',
  newsletter: {
    title: 'Magazín Newsletter',
    subtitle:
      'Rozhovory, aktuality či inšpirácie. To všetko nájdeš pravidelne v tvojom mailboxe.',
  },
  boxTitle: `Článok
    <br />
    v našom
    <br />
    magazíne`,
  article: 'článok',
  readersWithCount: '{{count}} čitateľov',
  boxText:
    'Potrebujete posilniť povedomie o vašej značke či firme? Chcete ukázať uchádzačom zákulisie vašej firmy, alebo firemnú kultúru a tak zvýšiť vašu atraktivitu? Pomôžeme vám...',
  mailTo: {
    subject: 'Mám záujem o článok v magazíne 📰',
    body: 'Pozdravujeme Pretlak, %0D%0A %0D%0A Radi by sme si u vás objednali Článok v magazíne 📰 . %0D%0A %0D%0A Ďakujeme pekne. Ste super. %0D%0A %0D%0A So srdečným pozdravom,',
  },
}
