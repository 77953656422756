module.exports = {
  sponsored: 'Sponsorováno',
  sponsoredFreelancer: 'Doporučujeme',
  exclusive: 'Exkluzív',
  colored: 'Zabarvená',
  new: 'Nové',
  newF: 'Nová',
  newM: 'Nový',
  mostFavorite: 'NEJOBLÍBENĚJŠÍ',
  top: 'Topovaná',
  topFreelancer: 'Topovaný',
  newOfferCount: {
    one: '{{count}} Nová',
    other: '{{count}} Nové',
    many: '{{count}} Nových',
  },
  available: 'Dostupný',
  unavailable: 'Nedostupný',
  hot: '🔥 HOT',
}
