import axios, { AxiosRequestConfig } from 'axios'
import { HOST } from 'utils/helpers'

export const config: AxiosRequestConfig = {
  baseURL: `${HOST}/`,
}

export const client = axios.create(config)
export const post = client.post
export const get = client.get
export const put = client.put
