import TrackingUtils from 'utils/tracking'
import { get, post } from 'utils/axios'

export const JOBS_FETCH = 'JOBS_FETCH'
export const JOBS_ITEM_UPDATE = 'JOBS_ITEM_UPDATE'
export const JOBS_ITEM_ADD = 'JOBS_ITEM_ADD'

export const getPublicJobsData = (data: any) => {
  return data.jobs || null
}

export const jobClose =
  (id: string) =>
  async (
    dispatch
  ): Promise<{ success: boolean; job?: any; error?: string; msg?: string }> => {
    try {
      const { data } = await get(`api/job/close/${id}`)

      if (data.status === 'ok') {
        dispatch({ type: JOBS_ITEM_UPDATE, payload: data.job })

        TrackingUtils.event('job-action', {
          event_action: 'close',
        })

        return { success: true, job: data.job }
      } else {
        return { success: false, error: data.error, msg: data.msg }
      }
    } catch (err) {
      return { success: false, error: err }
    }
  }

export const jobUnpublish =
  (id: string) =>
  async (dispatch): Promise<{ success: boolean; error?: string }> => {
    try {
      const { data } = await get(`api/job/unpublish/${id}`)

      if (data.status === 'ok') {
        dispatch({ type: JOBS_ITEM_UPDATE, payload: data.job })

        TrackingUtils.event('job-action', {
          event_action: 'unpublish',
        })

        return { success: true }
      } else {
        return { success: false, error: data.error }
      }
    } catch (err) {
      return { success: false, error: err }
    }
  }

export const jobPublish =
  (id: string) =>
  async (dispatch): Promise<{ success: boolean; error?: string }> => {
    try {
      const { data } = await get(`api/job/publish/${id}`)

      if (data.status === 'ok') {
        dispatch({ type: JOBS_ITEM_UPDATE, payload: data.job })

        TrackingUtils.event('job-action', {
          event_action: 'publish',
        })

        return { success: true }
      } else {
        return { success: false, error: data.error }
      }
    } catch (err) {
      return { success: false, error: err }
    }
  }

export const jobBuyAddon =
  (buyData: any) =>
  async (
    dispatch
  ): Promise<{ success: boolean; job?: any; error?: string; msg?: string }> => {
    try {
      const { data } = await post(`api/job/buy-addon/`, buyData)

      if (data.status === 'ok') {
        dispatch({ type: JOBS_ITEM_UPDATE, payload: data.job })

        return { ...data, success: true }
      } else {
        return { success: false, error: data.error, msg: data.msg }
      }
    } catch (err) {
      return { success: false, error: err }
    }
  }

export const jobEdit =
  (nextData: any) =>
  async (
    dispatch
  ): Promise<{ success: boolean; job?: any; error?: string; msg?: string }> => {
    try {
      const { data } = await post('api/job/edit/', nextData)

      if (data.status === 'ok') {
        dispatch({ type: JOBS_ITEM_UPDATE, payload: data.job })

        return { success: true, job: data.job }
      } else {
        return { success: false, error: data.error, msg: data.msg }
      }
    } catch (err) {
      return { success: false, error: err }
    }
  }

export const jobRefresh =
  (jobId: string) =>
  async (
    dispatch
  ): Promise<{ success: boolean; job?: any; error?: string; msg?: string }> => {
    try {
      const { data } = await post('api/job/refresh', { id: jobId })

      if (data.status === 'ok') {
        dispatch({ type: JOBS_ITEM_UPDATE, payload: data.job })

        return { success: true, job: data.job }
      } else {
        return { success: false, error: data.error, msg: data.msg }
      }
    } catch (err) {
      return { success: false, error: err }
    }
  }
