module.exports = {
  invoice1: `Zveřejnění pracovní nabídky {{jobLevelName}} k pozici {{jobName}} na webu pretlak.com`,
  invoice2: '"Zvýraznění" pracovní nabídky vlastní barvou na webu pretlak.com',
  invoice3: `Topování na {{days}} dnů na webu pretlak.com`,
  invoice4: 'Extra sdílení na sociálních kanálech pretlak.com',
  invoice5: `{{productText}} pracovních nabídek na webu pretlak.com`,
  invoice6: `{{productText}} z webu pretlak.com`,
  invoice7: `Zakoupení režimu zobrazení {{productName}} na webu pretlak.com`,
  invoice8: 'Měsíční předplatné. PRO členství - pretlak.com/freelancers ',
  invoice9: 'Roční předplatné. PRO členství - pretlak.com/freelancers',
  invoice10: 'Nevyužitý čas. PRO členství - pretlak.com/freelancers',
  invoice11: 'Měsíční předplatné. Odemknutí freelancerů - pretlak.com/freelancers',
  invoice12: 'Roční předplatné. Odemknutí freelancerů - pretlak.com/freelancers',
  invoice13: 'Nevyužitý čas. Odemknutí freelancerů - pretlak.com/freelancers',
  invoice14: 'Topování profilu na webu',
  invoice15: 'Přesdílení profilu na sociálních sítích',
  invoice16: 'Zvýraznění profilu vlastní barvou',
}
