import Constants from 'server/constants'

export default class AddonUtils {
  static getCustomColorPrice(jobLevel?: number) {
    if (jobLevel === 3) {
      return 0
    }

    return Constants.PRODUCT.ADDON.CUSTOM_COLOR.price
  }

  static getPrice(
    data: { customColor?: boolean },
    options?: { jobLevel?: number; isPaid?: boolean }
  ) {
    let price = 0

    if (options?.isPaid) {
      return 0
    }

    if (data.customColor) {
      if (options?.jobLevel === 3) {
        price += 0
      } else {
        price += Constants.PRODUCT.ADDON.CUSTOM_COLOR.price
      }
    }

    return price
  }

  static getFreelancerAddonPrice(data: {
    top: boolean
    socialShare?: boolean
    customColor?: boolean
  }) {
    let price = 0

    if (data.top) {
      price += Constants.PRODUCT.FREELANCERPROFILE.TOP.fullPrice
    }

    if (data.socialShare) {
      price += Constants.PRODUCT.FREELANCERPROFILE.SOCIAL_SHARE.fullPrice
    }

    if (data.customColor) {
      price += Constants.PRODUCT.FREELANCERPROFILE.CUSTOM_COLOR.fullPrice
    }

    return Number.parseFloat(price.toFixed(2))
  }
}
