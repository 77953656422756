module.exports = {
  name: 'Neřešíme',
  tag: '#Neřešíme',
  topbar: {
    text: '#NEREŠTE spolu s námi se slevou 20 % na každou nabídku.',
    cta: 'Přidat job',
    ctaMobile: 'Zjistit více',
  },
  hp: {
    text: 'Na pracovišti bychom neměli bojovat se svou identitou. U nás najdeš joby, ve kterých můžeš být sám nebo sama sebou, nebo klidně i obojí. #neřešíme',
    cta: 'Zjistit více',
    ctaSecondary: 'Přehled jobů',
  },
  job: {
    heading: '#Neřešíme',
    text: 'Při výběrovém konání budou všichni kandidáti posuzováni stejně bez ohledu na jejich pohlaví, etnicitu, náboženství nebo sexuální orientaci.',
  },
  login: {
    heading: '#NEŘEŠÍME',
    text: 'U nás si každý najde job, kde může být sám sebou. <br/><br/>Přidejte se i vy k firmám, kterým záleží na spokojenosti všech svých zaměstnanců.',
    cta: 'Zjistit více',
  },
  microsite: {
    cta: 'Přidejte se k nám!',
    header: {
      heading: '#NEŘEŠÍME',
      subHeading: 'Pracujte v prostředí, kde můžete být sami sebou.',
      text: 'Věříme, že diverzita na pracovišti přináší mnoho benefitů a nikdo by se v práci neměl setkat s diskriminací. Zaměstnavatelé, kteří se rozhodnou náš manifest podpořit, tím veřejně deklarují, že budou pro své zaměstnance a zaměstnankyně aktivně vytvářet a udržovat inkluzivní pracovní prostředí. Uchazeči o pracovní pozici se tak mohou spolehnout na to, že ve své budoucí práci budou moci být bez obav sami sebou, bez ohledu na jejich sexuální orientaci, pohlaví, věk, etnicitu, náboženství nebo zdravotní znevýhodnění.',
    },
    intro: {
      1: {
        heading: '👀 PROČ <br/>#NEŘEŠÍME?',
        text: 'Stále je mezi náma mnoho lidí, kteří vidí svět podle zažitých představ o tom, jak má „normální“ člověk vypadat, koho má milovat, čemu má věřit a podobně. Podle nás jsou tyto „šablony dokonalosti“ prostě nesmysl. A proto bojujeme za to, abychom jako společnost přestali řešit to „jiné“ a viděli jen to podstatné.',
      },
      2: {
        heading: '🥰 Spokojení <br/>zaměstnanci <br/>jsou produktivnější',
        text: 'Zaměstnanci a zaměstnankyně, kteří musí na pracovišti skrývat svou sexuální orientaci, mají až o 73 % větší pravděpodobnost, že odejdou z práce do tří let. Více než 70 % LGBT lidí uvedlo, že pravděpodobněji přijmou práci ve společnosti, která otevřeně podporuje LGBT než v jakékoli jiné. Navíc i samotná angažovanost a produktivita LGBT zaměstnanců se snižuje až o 30 %, pokud pracovní prostředí vnímají jako nepřátelské. Zaměstnavatelé tak díky inkluzivní atmosféře získávají spokojenější a výkonnější zaměstnance. <br/><i>(Zdroj: Credit Suisse: LGBT- The Value of Diversity)</i>',
      },

      boxes: {
        1: {
          number: 'až o 30 % ',
          text: 'se snižuje produktivita LGBT zaměstnanců, pokud je pracovní prostředí nepřátelské',
        },
        2: {
          number: 'více než 70%',
          text: 'LGBT lidí uvedlo, že pravděpodobněji přijmou práci ve společnosti, která otevřeně podporuje LGBT',
        },
        3: {
          number: 'až o 73 % ',
          text: 'větší pravděpodobnost, že odejdou z práce, mají lidé, kteří musí skrývat svou sexuální orientaci',
        },
      },
    },
    main: {
      heading: '📢 TO HLAVNÍ, CO ŘEŠÍME',
      items: {
        A: '1. Ke každému člověku přistupujeme férově a bez rozdílu.',
        C: '2. Aktivně řešíme problémy na pracovišti a věnujeme se prevenci jejich vzniku.',
        E: '3. Sdružujeme zaměstnavatele, kterým záleží na spokojenosti jejich zaměstnanců a zaměstnankyň.',
        B: '4. Zajímáme se o to, jak se lidé cítí a nasloucháme jim.',
        D: '5. Šíříme osvětu v oblasti diverzity a inkluze na pracovišti.',
      },
    },

    terms: {
      heading: '🫶 Důležité pojmy',
      items: {
        1: {
          heading: 'Inkluze',
          text: 'Slovo inkluze nebo začlenění označuje vytvoření bezpečného prostředí, kde každý jednotlivec může být sám sebou. Lidé se v takovém prostředí vzájemně upřímně tolerují, podporujou a mají stejné možnosti bez rozdílu. Člověk ve fungujícím inkluzivním prostředí cítí, že „zapadá“ a nestýká se se žádnou formou diskriminace od svého okolí.',
        },
        2: {
          heading: 'Diverzita',
          text: 'Slovo diverzita nebo rozmanitost označuje rozdílnost mezi jednotlivci nebo skupinami. Hovoříme-li o viditelné rozmanitosti, máme na mysli různorodost ve smyslu věku, pohlaví, etnicity, náboženství, zdravotního postižení, sexuální orientace, vzdělání či národnosti. Jako neviditelnou diverzitu označujeme aspekty jako je rozdílnost v uvažování, v životních zkušenostech či duševním prožívání.',
        },
        3: {
          heading: 'LGBTQ+',
          text: 'Zkratka LGBTQ+ označuje lidi menšinových sexuálních orientací. Konkrétně těchto pět písmen odkazuje na lesby, gaye, bisexuály, transrodové osoby, queer a tzv. ‘questioning’ lidi, kteří po své orientaci stále pátrají. Za touhle zkratkou se často přidávají další písmena a znamení plus, které odkazují na ostatní sexuální identity jako jsou např. pansexuály, polyamory, asexuály a další.',
        },
        4: {
          heading: 'Queer',
          text: 'Queer je obecné označení pro lidi, kteří se neidentifikují jako heterosexuálové ani jako cisgendeři (lidé, jejichž sexuální identita koresponduje s pohlavím, které jim bylo přiděleno při narození). Tento pojem se často zaměňuje s pojmem Drag Queen, což je však pojmenování pro umělce, většinou muže, kteří se v rámci svého originálního uměleckého představení převlékají za ženy. Jsou typičtí extravagantním oblečením, parukami a výrazným make-upem.',
        },
      },
    },

    join: {
      heading: '#NEŘEŠTE S NÁMI!',
      subheading:
        'Souhlasíte s naším manifestem? Dejte to znát i ostatním! Je to jednoduché.',
      items: {
        1: 'Zaregistrujte vaši firmu nebo se přihlašte do svého firemního profilu na našem webu.',
        2: 'Přidejte nový job a označte jej tagem #neřešíme.',
        3: 'Budujte ve vaší firmě inkluzivní pracovní prostředí a šiřte osvětu o tomto tématu, kde se jen dá!',
      },
      cta: 'Přidejte job!',
    },

    faq: {
      heading: '🗯 Často kladené dotazy',
      items: [
        {
          question: 'Co je Charta Diverzity? ',
          answer: `Charta diverzity je projektem Evropské komise, jehož cílem je podpora a zavádění diverzity, flexibility a inkluze na pracovní trh v zemích EU. <br/><br/>

            Neváhejte se obrátit s vašimi dotazy na koordinátory Charty Diverzity v Česku a na Slovensku: <br/><br/>
            🇨🇿 <a href="https://diverzita.cz/">https://diverzita.cz/</a><br/>
            🇸🇰 <a href="https://www.chartadiverzity.sk/">https://www.chartadiverzity.sk/</a>`,
        },
        {
          question: 'Co znamená být signatářem Charty Diverzity?',
          answer: `“Podpisem Charty diverzity signatář nejen deklaruje uznání principu diverzity, ale zavazuje se tyto principy na pracovišti rozvíjet. K tomu jako podpora slouží programy a aktivity organizované BPS - Byznys pro společnost, národním koordinátorem Charty diverzity. Protože úsilí vynakládané na rozvoj diverzity vnímáme jako soustavnou a nikdy nekončící práci, je stanoveno minimální 3leté období pro přistupující signatáře.” <br/>
            <i>Zdroj: <a href="https://diverzita.cz/cs">https://diverzita.cz/cs</a></i>

          <br/><br/>

          <strong>Staňte se i vy signatářem Charty Diverzity!</strong><br/>
          Pro české zaměstnavatele: <a href="https://diverzita.cz/cs/stante-se-signatarem-charty-diverzity">https://diverzita.cz/cs/stante-se-signatarem-charty-diverzity</a> <br/>
          Pro slovenské zaměstnavatele: <a href="https://www.chartadiverzity.sk/charta-diverzity-sr/podmienky-pristupu/">https://www.chartadiverzity.sk/charta-diverzity-sr/podmienky-pristupu/</a>


            `,
        },
        {
          question:
            'Jsou organizace věnující se tomuto tématu, na které se jako zaměstnavatel mohu obrátit?',
          answer: `Ano, tady jsou některé z nich:<br /><br />

<strong>Pride business forum</strong><br/>
Výroční konference Pride Business Forum je největší konferencí v České republice zaměřená na rovnost LGBTQ+ lidí na pracovišti.<br/>
<a href="https://www.praguepride.cz/ ">https://www.praguepride.cz/</a><br/><br/>

<strong>Transparent</strong><br/>
Organizace Trans*parent z. s. usiluje o prosazování práv a pozitivních společenských změn ve prospěch transgender, nebinárních a intersex osob.<br/>
<a href="https://jsmetransparent.cz">https://jsmetransparent.cz</a><br/><br/>

<strong>Inakosť</strong><br/>
Slovenská iniciativa Inakosť spojuje lidi a organizace, které hledají řešení na zlepšení života leseb, gayů, bisexuálních, transrodových a intersexuálních lidí, aby byly plnohodnotnou součástí svých rodin, pracovních a školních kolektivů, místních společenství, církví a celé slovenské společnosti.<br/>
<a href="https://inakost.sk">https://inakost.sk</a>

`,
        },
        {
          question:
            'Jak mám jako zaměstnavatel postupovat při vytváření inkluzivního prostředí pro zaměstnance z LGBTQ+ komunity?',
          answer: `
          Veřejná ochránkyně práv ve výzkumu Být LGBT+ v Česku z roku 2019 doporučuje nasledovné kroky: <br/><br/>

“1. Realizovat opatření na podporu diverzity na pracovišti, vytvořit efektivní mechanismus předcházení obtěžování na pracovišti a plán postupu pro případ, že se obtěžování na pracovišti objeví.<br/><br/>

2. Postupovat efektivně a volit účinná opatření v případě, že se na pracovišti vyskytne obtěžování z důvodu sexuální orientace nebo genderové identity.<br/><br/>

3. Zvýšit povědomí LGBT+ zaměstnanců o zákonném stížnostním mechanismu, jehož mohou na pracovišti využít v případě, že se stanou obětí diskriminace.<br/><br/>

4. Metodicky usměrnit orgány inspekce práce ve vztahu ke kontrolám rovného zacházení na pracovišti.” <br/><br/>
<i>Zdroj: <a href="http://www.ochrance.cz/diskriminace/vyzkum/">http://www.ochrance.cz/diskriminace/vyzkum/</a></i>

          `,
        },
        {
          question:
            'Co doporučujeme jako první krok vstříc diverzitě a inkluzi ve vaší firmě my v Pretlaku?',
          answer:
            'Především přistupovat ke všem kolegům a zaměstnancům bez rozdílu a s respektem. ❤️',
        },
      ],
    },

    jobs: {
      heading: '🚀 JOBY, VE KTERÝCH #NEŘEŠÍME',
    },
  },

  companyModal: {
    heading: '#NEŘEŠÍME',
    items: [
      {
        heading: '💬 Proč #neřešíme?',
        text: 'Stále je mezi náma mnoho lidí, kteří vidí svět podle zažitých představ o tom, jak má „normální” člověk vypadat, koho má milovat, čemu má věřit a podobně. Podle nás jsou tyto „šablony dokonalosti” prostě nesmysl. A proto bojujeme za to, abychom jako společnost přestali řešit to „jiné” a viděli jen to podstatné. <a href="/manifest">Zobrazit více</a>',
      },
      {
        heading: '🫶 Neřešte s námi!',
        text: 'Přidejte nový job a označte jej tagem #neřešíme. Budujte ve vaší firmě inkluzivní pracovní prostředí a šiřte osvětu o tomto tématu, kde se jen dá!',
      },
    ],
  },
}
