import React from 'react'
import { useSelector } from 'react-redux'
import Link from 'next/link'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import type { IStoreState } from 'redux/reducers'

const FreelanerFavouriteHeaderButton: React.FC = () => {
  const { loggedCompany, loggedProfile } = useSelector((state: IStoreState) => {
    return { loggedCompany: state.company, loggedProfile: state.profile }
  })

  const getNumberOfFreelancers = () => {
    if (loggedCompany?.favouriteFreelancers?.length) {
      return loggedCompany.favouriteFreelancers?.length
    }

    if (loggedProfile?.favouriteFreelancers?.length) {
      return loggedProfile.favouriteFreelancers?.length
    }

    return 0
  }

  const getUrl = () => {
    if (loggedCompany) {
      return '/profile/freelancers#section'
    }

    return '/freelancers/profile/favourite#section'
  }

  return (
    <Link href={getUrl()} className="header__button header__button--favourite">
      <span className="button__in black">
        <span className="pill pill--super-small pill--white">
          {getNumberOfFreelancers()}
        </span>
        <span>
          <FavoriteBorderIcon className="icon" />
        </span>
      </span>
    </Link>
  )
}

export default FreelanerFavouriteHeaderButton
