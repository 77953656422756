import { post } from 'utils/axios'
import { updateCompanyAction } from '../action-creators/company'

// TODO: Type the return type! Problem is that returned data does NOT match ICompany.
// Keys like "jobs" or "modals" should not probably be here? idk
export const getPublicCompanyData = (data: any) => {
  if (!data.company) {
    return null
  }

  return {
    id: data.company._id,
    shortId: data.company.shortId,
    email: data.company.email,
    name: data.company.name,
    slug: data.company.slug,
    ico: data.company.ico,
    dic: data.company.dic,
    icdph: data.company.icdph,
    address: data.company.address,
    about: data.company.about,
    jobs: data.company.jobs,
    modals: data.company.modals,
    stats: data.company.stats,
    outsideEu: data.company.outsideEu,
    hrAgency: data.company.hrAgency,
    hrCustomUrl: data.company.hrCustomUrl,
    notifications: data.company.notifications,
    caution: data.company.caution,
    categories: data.company.categories,
    recommendation: data.company.recommendation,
    hasUnlockedFreelancers: data.company.hasUnlockedFreelancers,
    activeSubscriptions: data.company.activeSubscriptions,
    favouriteFreelancers: data.company.favouriteFreelancers,
  }
}

export const update =
  (companyData: any) =>
  async (dispatch): Promise<{ success: boolean }> => {
    try {
      const { data } = await post(`api/company/update/`, companyData)

      if (data.status === 'ok') {
        dispatch(updateCompanyAction(getPublicCompanyData(data)))

        return { success: true }
      } else {
        return { success: false }
      }
    } catch (err) {
      return { success: false }
    }
  }

export const updateModals =
  (companyData: any) =>
  async (dispatch): Promise<{ success: boolean }> => {
    try {
      const { data } = await post('api/company/update-modals/', companyData)

      if (data.status === 'ok') {
        dispatch(updateCompanyAction(getPublicCompanyData(data)))

        return { success: true }
      } else {
        return { success: false }
      }
    } catch (err) {
      return { success: false }
    }
  }

export const updateNotifications =
  (companyData: {
    shortId: string
    notifications: {
      cv: boolean
      job: boolean
    }
  }) =>
  async (dispatch): Promise<{ success: boolean }> => {
    try {
      const { data } = await post('api/company/update-notifications/', companyData)

      if (data.status === 'ok') {
        dispatch(updateCompanyAction(getPublicCompanyData(data)))

        return { success: true }
      } else {
        return { success: false }
      }
    } catch (err) {
      return { success: false }
    }
  }

export const updateStatsJobAddStart =
  (companyData: { shortId: string; date: Date }) =>
  async (dispatch): Promise<{ success: boolean }> => {
    try {
      const { data } = await post('api/company/update-stats-job-add-start/', companyData)

      if (data.status === 'ok') {
        dispatch(updateCompanyAction(getPublicCompanyData(data)))

        return { success: true }
      } else {
        return { success: false }
      }
    } catch (err) {
      return { success: false }
    }
  }

export const updateStatsJobAddFinish =
  (companyData: { shortId: string; date: Date }) =>
  async (dispatch): Promise<{ success: boolean }> => {
    try {
      const { data } = await post('api/company/update-stats-job-add-finish/', companyData)

      if (data.status === 'ok') {
        dispatch(updateCompanyAction(getPublicCompanyData(data)))

        return { success: true }
      } else {
        return { success: false }
      }
    } catch (err) {
      return { success: false }
    }
  }

export const updateAdditionalInfo =
  (companyData: { shortId: string; categories: string[]; recommendation: string }) =>
  async (dispatch): Promise<{ success: boolean }> => {
    try {
      const { data } = await post('api/company/update-additional-info', companyData)
      const success = data.status === 'ok'

      if (success) {
        dispatch(updateCompanyAction(getPublicCompanyData(data)))
      }

      return { success }
    } catch (err) {
      return { success: false }
    }
  }

export const toggleCompanyFavouriteFreelancers =
  (companyData: { shortId: string; freelancerId: string }) =>
  async (dispatch): Promise<{ success: boolean }> => {
    try {
      const { data } = await post('api/company/toggle-favourite-freelancer', companyData)
      const success = data.status === 'ok'

      if (success) {
        dispatch(updateCompanyAction(getPublicCompanyData(data)))
      }

      return { success }
    } catch (err) {
      return { success: false }
    }
  }
