import React, { useState, useRef } from 'react'
import { TwitterPicker } from 'react-color'
import useOnClickOutside from 'src/hooks/useOnClickOutside'

export const COLORS = [
  '#FF431C',
  '#9B5DE5',
  '#F15BB5',
  '#FEE440',
  '#00BBF9',
  '#00F5D4',
  '#4D6CFA',
  '#F4A259',
  '#2DB77D',
  '#000000',
]

interface IProps {
  value: string
  triangle?: string
  onChange: (color: { hex: string }) => void
}

export default function ColorPicker({ value, onChange, triangle }: IProps) {
  const ref = useRef()
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false)

  useOnClickOutside(ref, () => setDropdownVisible(false))

  const toggleColorPicker = () => {
    setDropdownVisible(!dropdownVisible)
  }

  const handlePickerClick = (e) => {
    if (e.target.className !== 'colorpicker__note-link') {
      e.preventDefault()
    }
  }

  const handleChange = (color: any, e: any) => {
    if (e.type === 'click') {
      setDropdownVisible(false)
    }

    onChange(color)
  }

  return (
    <div
      className="colorpicker colorpicker--freelancers"
      ref={ref}
      onClick={handlePickerClick}
    >
      <div className="colorpicker__handler-wrapper" onClick={toggleColorPicker}>
        <div className="colorpicker__handler" style={{ background: value }} />
      </div>
      {dropdownVisible && (
        <TwitterPicker
          colors={COLORS}
          color={value}
          triangle={triangle}
          onChangeComplete={handleChange}
        />
      )}
    </div>
  )
}
