import { IStats } from 'redux/interfaces/stats'
import { STATS_FETCH } from '../managers/stats'

export const defaultStatsState: IStats = null

const products = (state = defaultStatsState, action) => {
  switch (action.type) {
    case STATS_FETCH:
      return action.payload
    default:
      return state
  }
}

export default products
