import React, { ChangeEvent, FC } from 'react'
import classnames from 'classnames'
import Tooltip from 'components/Tooltip'
import useTranslation from 'translations/hooks/useTranslation'

interface IProps {
  label: string | React.ReactNode
  className?: string
  name: string
  checked: boolean
  error?: boolean
  errorText?: string
  tooltip?: string
  multiline?: boolean
  multilineNext?: boolean
  lineLimit?: boolean
  withPadding?: boolean
  withoutMarginTop?: boolean
  withoutMarginBottom?: boolean
  withoutErrorText?: boolean
  withBackground?: boolean
  disabled?: boolean
  type?: 'upsell-yellow' | 'manifest'
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
}

const Checkbox: FC<IProps> = ({
  label,
  name,
  checked,
  onChange,
  multiline,
  multilineNext,
  lineLimit,
  withPadding,
  withoutMarginTop,
  withoutMarginBottom,
  withoutErrorText,
  withBackground,
  tooltip,
  type,
  error,
  errorText,
  disabled,
  className,
}) => {
  const { t } = useTranslation()

  return (
    <div
      className={classnames('input-wrapper input-wrapper--checkbox', {
        [className]: Boolean(className),
        'input-wrapper--selectbox': withPadding,
        'input-wrapper--error': error,
        'margin-top-small': !withoutMarginTop,
        'input-wrapper--checkbox-multiline': multilineNext,
        'input-wrapper--checkbox-line-limit': lineLimit,
        'input-wrapper--checkbox-bg': withBackground,
        'input-wrapper--checkbox-upsell-yellow': type === 'upsell-yellow',
        'input-wrapper--checkbox-manifest': type === 'manifest',
      })}
    >
      <input
        className={classnames('styled-checkbox', {
          'styled-checkbox--multiline': multiline,
        })}
        data-cy={`checkbox-${name}`}
        type="checkbox"
        name={name}
        id={`checkbox-${name}`}
        checked={checked}
        onChange={onChange}
        key={`checkbox-${name}`}
        disabled={disabled}
      />

      <label
        className={classnames('paragraph', {
          'no-margin-bottom': withoutMarginBottom,
        })}
        title={typeof label === 'string' ? label : undefined}
        htmlFor={`checkbox-${name}`}
      >
        <span>
          {label}
          <Tooltip content={tooltip} />
        </span>
      </label>
      {error && !withoutErrorText && (
        <p className="input__error-text input__error-text-multiline input__error-text--visible">
          {errorText || t('common.requiredField')}
        </p>
      )}
    </div>
  )
}

export default Checkbox
