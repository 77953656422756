import * as Sentry from '@sentry/nextjs'
import { get, post } from 'utils/axios'
import { fetchCompanyAction } from 'redux/action-creators/company'
// import { WEB_VERSION } from 'utils/helpers'
// import MixpanelUtils from 'utils/mixpanel'
import { IUser } from 'redux/interfaces/user'
import { JOB_DRAFTS_FETCH } from 'redux/managers/jobDrafts'
import { PROMOCODES_FETCH, getPublicData } from './promoCodes'
import { getPublicCompanyData } from './company'
import { JOBS_FETCH, getPublicJobsData } from './jobs'
import { PRODUCTS_FETCH, getPublicProductsData } from './products'
import { PROFILE_FETCH, getPublicProfileData } from './profile'

export const USER_SIGNUP = 'USER_SIGNUP'
export const USER_LOGIN = 'USER_LOGIN'
export const USER_FETCH = 'USER_FETCH'
export const USER_PARTIAL_UPDATE = 'USER_PARTIAL_UPDATE'

const getPublicUserData = (data: any) => {
  return {
    _id: data.user._id,
    shortId: data.user.shortId,
    email: data.user.email,
    last_login_date: data.user.last_login_date,
    signup_date: data.user.signup_date,
    role: data.user.role,
    companies: data.user.companies,
    profile: data.user.profile,
  }
}

const handleUserIdentification = (
  data,
  dispatch,
  options?: { trackMixpanel: boolean }
) => {
  if (!data.user) {
    return { success: false }
  }

  let publicUserData = getPublicUserData(data)
  // let mixpanelData = {}

  dispatch({ type: USER_LOGIN, payload: publicUserData })

  if (data.user.role === 'standard') {
    const companyData = getPublicCompanyData(data)
    const jobsData = getPublicJobsData(data)
    const jobDraftsData = data.jobDrafts || []

    // mixpanelData = {
    //   ...publicUserData,
    //   roleType: 'company',
    //   $name: companyData.name,
    //   $avatar: companyData && companyData.about ? companyData.about.logo : undefined,
    //   jobs_published: jobsData ? jobsData.length : 0,
    //   city: companyData.address.location?.name,
    //   country: companyData.address.location?.country?.name,
    //   companyCategories: companyData.categories,
    //   companyIco: companyData.ico,
    //   companyId: companyData.id,
    //   companyShortId: companyData.shortId,
    //   companySlug: companyData.slug,
    //   companyName: companyData.name,
    //   notificationsCv: companyData.notifications.cv,
    //   notificationsJob: companyData.notifications.job,
    //   recommendation: companyData.recommendation,
    // }

    Sentry.configureScope((scope) => {
      scope.setTag('userRole', 'company')
      scope.setTag('companyName', companyData.name)
      scope.setUser({ email: data.user.email, id: data.user._id })
    })

    dispatch(fetchCompanyAction(companyData))
    dispatch({ type: JOBS_FETCH, payload: jobsData })
    dispatch({ type: JOB_DRAFTS_FETCH, payload: jobDraftsData })
    dispatch({ type: PROMOCODES_FETCH, payload: getPublicData(data) })
    dispatch({ type: PROMOCODES_FETCH, payload: getPublicData(data) })
    dispatch({ type: PRODUCTS_FETCH, payload: getPublicProductsData(data) })
  }

  if (data.user.role === 'user') {
    const profileData = getPublicProfileData(data)
    // mixpanelData = {
    //   ...publicUserData,
    //   roleType: 'user',
    //   $name: profileData.name,
    // }

    Sentry.configureScope((scope) => {
      scope.setTag('userRole', 'user')
      scope.setTag('companyName', 'n/a')
      scope.setUser({ email: data.user.email, id: data.user._id })
    })

    dispatch({ type: PROFILE_FETCH, payload: profileData })
  }

  // mixpanelData = {
  //   ...mixpanelData,
  //   web_version: WEB_VERSION,
  // }

  // if (options.trackMixpanel) {
  //   MixpanelUtils.setProfileProperties(publicUserData._id, mixpanelData)
  // }

  return { success: true, role: data.user.role }
}

export const signup =
  (signupData: any) =>
  async (dispatch): Promise<{ success: boolean; error?: string }> => {
    try {
      const { data } = await post('api/auth/signup/', signupData)

      if (data.status === 'ok') {
        return handleUserIdentification(data, dispatch, { trackMixpanel: true })
      } else {
        return { success: false, error: data.error }
      }
    } catch (err) {
      return { success: false }
    }
  }

export const userLogin =
  (signupData: any) =>
  async (dispatch): Promise<{ success: boolean; error?: string }> => {
    try {
      const { data } = await post('api/auth/login/', signupData)

      if (data.status === 'ok') {
        return handleUserIdentification(data, dispatch, { trackMixpanel: true })
      } else {
        return { success: false, error: data.error }
      }
    } catch (err) {
      return { success: false }
    }
  }

export const userFullFetch = async (dispatch, userId) => {
  try {
    const { data } = await get(`api/user/get/${userId}/full`)

    if (data.status === 'ok') {
      return handleUserIdentification(data, dispatch, { trackMixpanel: false })
    } else {
      return { success: false }
    }
  } catch (err) {
    return { success: false }
  }
}

export const updatePartialUserData = (userData: Partial<IUser>) => async (dispatch) => {
  dispatch({ type: USER_PARTIAL_UPDATE, payload: userData })
}
