import type { ICompany } from 'redux/interfaces/company'

export type FetchCompanyAction = {
  type: 'COMPANY_FETCH'
  payload: ICompany
}

export type UpdateCompanyAction = {
  type: 'COMPANY_UPDATE'
  payload: ICompany
}

export type CompanyAction = FetchCompanyAction | UpdateCompanyAction

// TODO: use "payload: ICompany" once getPublicCompanyData is typed
export const updateCompanyAction = (payload: any): UpdateCompanyAction => ({
  type: 'COMPANY_UPDATE',
  payload,
})

export const fetchCompanyAction = (payload: any): FetchCompanyAction => ({
  type: 'COMPANY_FETCH',
  payload,
})
