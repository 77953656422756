module.exports = {
  title: 'PRO subscription',
  membership: 'Membership',
  loginAdvantagesTitle: 'Join freelancers and get',
  loginAdvantages: `– Your own profile completely free (forever)
    <br />
    – Place among the top freelancers in Slovakia
    <br />
    - Visibility among more than 400 companies
    <br />
    - Tickets for conferences, meetups + bonuses from our partners`,
  noFreelancerFound: 'No freelancer was found.',
  companyUpsellHeading: 'The best <br/>database <br/>of freelancers',
  hpUpsellHeading: 'Discover <br/>{{count}} freelancers',
  hpUpsellHeadingWithoutNumber: 'Discover <br/>freelancers',
  showAllCategoryWithCount: {
    developeri: 'Show all {{count}} Developers',
    dizajneri: 'Show all {{count}} Designers',
    marketeri: 'Show all {{count}} Marketers',
    nontech: 'Show all {{count}} Non-tech',
    'non-tech': 'Show all {{count}} Non-tech',
    kreativci: 'Show all {{count}} Creatives',
  },
  proOfferModal: {
    title: 'Your profile is invisible',
    description: 'It will only be visible to everyone if you switch to PRO membership',
  },
  showAllCategory: {
    developeri: 'Show all Developers',
    dizajneri: 'Show all Designers',
    marketeri: 'Show all Marketers',
    nontech: 'Show all Non-tech',
    'non-tech': 'Show all Non-tech',
    kreativci: 'Show all Creatives',
  },
  addFavourite: 'Add to favorites',
  removeFavourite: 'Remove from favorites',
  showAllCount: 'Show all {{count}}',
  showAll: 'Show all',
  showFreelancers: 'Show freelancers',
  weAreSadThatYouAreLeaving:
    'We are sorry to see you leave. If you change your mind you know where to find us.',
  contactToFreelancerInMail: 'Message to freelancer sent successfully!',
  openingPrice: 'INTRODUCTORY PRICE',
  displayForOneMonth: 'Show for 1 month',
  showAllFreelancers: 'Show all freelancers',
  uncoverSelectedCategory: 'Unlock 1 selected category',
  currentlyUnavailable: 'Not available at the moment',
  unlockFreelancers: 'Unlock freelancers!',
  unlockFreelancersNumber: '{{count}} freelancers, for free!',
  unlockFreelancersModalDescription:
    'Gain access to the largest freelancer database on the local market is conditional upon logging in or registering.',
  unlockFreelancersModalText: "If you've already purchased",
  unlockFreelancersCta: 'Unlock freelancers',
  next: 'Next',
  contact: 'Contact',
  favourite: {
    title: 'Favorite',
    titleMany: 'Favorites',
    empty:
      "You have nothing here yet. Click the ❤️ button next to the freelancer's profile to add it to your favorites.",
  },
  bonus: {
    promoCodeExpiry: 'Expires',
    headline1: 'Special offer: 30 % OFF',
    headline2: 'Special offer for online study',
    headline3: 'FREE Hosting for 3 months',
    headline4: 'Lifetime discount of 15 %',
    headline5: '15 % discount on FLEXI/FIX DESK',
    item1:
      "Start invoicing quickly and in modern way. With Billdu, it's a piece of cake. Now with a 30% discount on all plans.",
    item2:
      'Complete 130 hours of marketing tips and advice. Sign up for a half-year study with a 10% discount or a one-year MBA study with a 5% discount. Study anytime, anywhere.',
    item3:
      'Launch your website today. When you order The Hosting Start (100 GB) for 1 year, you get 3 months extra.',
    item4:
      "Trovi might be the last tool you'll ever need for invoicing, project tracking and other wage and legal services. Try it with our exclusive 15 % lifetime discount.",
    item5:
      'Looking for a nice work place for your work? Join the unique HubHub community with up to 15 % discount! Just click the button below and request the FLEXI/FIX DESK membership with promocode.',
  },
  statusText: {
    waiting: '🕔 Awaiting',
    unpublished: '⛔️ Hidden',
    banned: '⛔️ Rejected',
    online: '✅ Online',
  },
  added: 'Added',
  seen: 'Seen',
  categoryName: {
    marketeri: {
      one: 'marketer',
      other: 'marketers',
      many: 'marketers',
      for: 'marketers',
      whom: 'marketer',
    },
    developeri: {
      one: 'developer',
      other: 'developers',
      many: 'developers',
      for: 'developers',
      whom: 'developer',
    },
    kreativci: {
      one: 'creative',
      other: 'creatives',
      many: 'creatives',
      for: 'creatives',
      whom: 'creative',
    },
    dizajneri: {
      one: 'designer',
      other: 'designers',
      many: 'designers',
      for: 'designers',
      whom: 'designer',
    },
    nontech: 'Non-tech',
    other: 'Other',
  },
  hiddenProfile: 'Hidden profile',
  picture: 'Photo',
  background: 'Choose a color for your profile',

  proModal: {
    title: 'Use the PRO membership to the fullest!',
    description: 'Add your photo, video, and change the color of your profile.',
    submit: 'Finish',
  },

  invisibleProfileNotebox: {
    heading: 'Invisible profile. Now no one can find you!',
    text: 'Your profile is only visible to people you send the link to. Upgrade to PRO and everyone will see you.',
  },
  invisibleProfile: 'Invisible profile',
  pro: {
    label: '🔥 PRO',
    discountPill: 'DISCOUNT -25% Valid only until the end of the year!',
    activateFor: 'Activate for {{price}}',
    monthlyPayment: 'Monthly payment?',
    name: 'Freelancer',
    editSubscription: 'Edit membership',
    editSubscriptionLong: 'Edit PRO membership',
    microsite: {
      title: 'Join the list of freelancers',
      featuresTitle: 'WITH PRO MEMBERSHIP<br />YOU GET',
      description:
        'With a PRO membership, you get benefits that put you in the spotlight.<br />More views, more work, more pay.',
      features: {
        web: {
          title: 'Publication on the web',
          description:
            'With PRO membership, everyone can see you because your profile is publicly displayed on the web. The FREE profile is visible only through a link.',
        },
        photo: {
          title: 'Photo with your name',
          description:
            'You have only one chance to make a first impression. Stand out from the crowd and bet on authenticity.',
        },
        video: {
          title: 'Video on your profile',
          description:
            "It is always better to see once than to read a hundred times. Add a video to your profile and show everyone what you're doing!",
        },
        ppc: {
          title: 'PPC campaign',
          description:
            'Your profile will be promoted through online campaigns as well. This way, potential clients will see you even outside our website.',
        },
        top: {
          title: 'TOP display on the web',
          description:
            'Your profile will be displayed at the TOP position on the homepage. This way, companies will have your profile right in front of their eyes.',
        },
        social: {
          title: 'Social media promotion with over 22k fans',
          description:
            'We dedicate a substantial push for you on our Facebook, Instagram, and LinkedIn. Your profile will be showcased through posts and stories.',
        },
        email: {
          title: 'You receive job offers via email',
          description:
            "New freelance job offers come directly to your email, so you won't miss out on any collaboration.",
        },
        color: {
          title: 'Custom color profile',
          description:
            'Be yourself, make your profile colorful and take it to the next level.',
        },
      },
      plans: {
        title: 'More work, more pay!',
        text: 'Upgrade to PRO and get more! Take advantage of the benefits that will be brought to the forefront. Are you in?',
        text2: 'Are you in?',
      },
    },
    pricing: {
      description: 'Equals 1 espresso',
      month: 'month',
      year: 'year',
      badge: '10x more profile views',
      cta: 'I want to be PRO!',
      ctaSubscribed: 'Active',
      features: {
        web: 'Publication on the website',
        photo: 'Photo in profile',
        video: 'Video on your profile',
        ppc: 'PPC campaign',
        top: 'TOP display on the web',
        social: 'Promotion on social media',
        color: 'Custom color profile',
      },
      noteFree: 'Profile is only visible through a direct link',
      noteMonthly: 'Regular payment once a month',
      noteYearly: 'With annual payment {{price}}',
      noteYearlyWithSale:
        'With annual payment <span class="strike">{{priceOld}}</span> {{price}}',
    },
  },
  upsell: {
    heading: 'Up to {{count}} <br/>freelancers <br/>in the game!',
    text: 'Are you looking for a marketer, developer, or designer for your project? Get access to the largest database of freelancers on the market starting from {{price}}&nbsp;€/month',
    detail: {
      note: 'Switch to PRO and get more! Enjoy benefits that put you in the spotlight.',
      cta: 'Learn more',
    },
    menu: 'Get more!',
    profile: 'Get more only for {{price}}/month',
  },
  switcher: {
    monthly: 'Monthly',
    yearly: 'Yearly',
    note: 'Save 50%',
  },
  testimonial: {
    1: {
      text: 'I have the best experience with Pretlak! I got more relevant offers during a shorter period of time. Available/Not available is a very useful feature that I can manage freely based upon my actual capacity.',
      textShort:
        'I have had an extremely positive experience with Pretlak! Within a short period of time, I received several relevant inquiries.',
      name: 'Daniel Biras',
      position: 'PPC Specialist',
    },
    2: {
      text: 'You get better visibility among more people from creative business and outside of it thanks to Pretlak. I encourage you to at least try the PRO membership. Growing list of clients will motivate you and your portfolio will be better in quality and bigger in quantity - which by the end of the day motivates you even more.',
      textShort:
        'Thanks to Pretlak, you can be seen by a large number of people from both within and outside the creative industry.',
      name: 'Karolína Uherčíková',
      position: 'Illustrator / designer',
    },
    3: {
      text: 'Thanks to my PRO membership, several relevant companies contacted me in a very short time.',
      textShort:
        'Thanks to my PRO membership, several relevant companies contacted me in a very short time.',
      name: 'Michal Slovák',
      position: 'Branding and Packaging',
    },
  },
  upsellHowToGoodProfile: {
    strong: 'Shhh.',
    regular:
      'If you want to pimp your freelancer profile, check this quick guide in our Magazine.',
  },

  freelancersUnlock: {
    yearly: {
      title: 'FULL YEAR',
    },
    monthly: {
      title: 'FULL MONTH',
    },

    benefits: {
      unlock: 'Access to{{count}} freelancers',
      email: 'New freelancers via email',
      cancel: 'Option to cancel anytime',
    },
  },

  contactModal: {
    emailLabel: 'Your email',
    emailPlaceholder: 'Enter your email here',
    textareaLabel: 'Message Text',
    textareaPlaceholder:
      'Write here a message for the freelancer, a description of the project, the offered reward, etc.',
  },

  companyProfileUpsell: {
    title: 'Discover {{count}}+ freelancers!',
    text: 'Choose instantly from a multitude of available freelancers!',
    cta: 'View freelancers',
  },

  skillLevel: {
    plain: {
      graduate: 'Graduate',
      junior: 'Junior',
      mid: 'Mid',
      senior: 'Senior',
      expert: 'Expert',
      standard: '',
    },
    detail: {
      graduate: '',
      junior: '(1 - 2 years)',
      mid: '(3 - 4 years)',
      senior: '(5 - 8 years)',
      expert: '(9+ years)',
      standard: '',
    },
  },

  profileModal: {
    title: 'Top your profile!',
    subtitle: '🔝Shoot to the top of the list',
    description:
      'It is quick and easy. Just one click and your profile will be displayed at the very top under the "recommended" position, until someone else overtakes you. 🚀',
  },
  availabilityStatus: {
    'not-available': 'Unavailable',
    'available-less-30-hours': 'Less than 30 hours / week',
    'available-more-30-hours': 'More than 30 hours / week',
    'available-agreement': 'As needed',
    'full-time-available': 'Open for full-time / contract work',
  },
  openToFullTime: 'Open for full-time / contract work',
  openToFullTimeShort: 'Full-time / contract',
  upsellTop: {
    title: 'Top your profile!',
    description: 'Shoot to the top of the list! It is quick and easy.',
  },

  topProfileModal: {
    title: 'Top your profile!',
    product1: 'Top on the web',
    product2: 'Share on social networks',
    product3: 'Highlight profile',
    proDescription: 'Get all this for only 4,99€/month. Switch to PRO!',
    tooltip: {
      product1:
        'Your profile will appear at the very top under the "recommended" position until someone else overtakes you.',
      product2:
        'We will show your profile to more than 20K people on our Facebook, Instagram and LinkedIn.',
      product3: 'Stand out and colour your profile. The crazier the better. Go wild!',
      product2Default:
        'Available only for PRO freelancers! We will show your profile to more than 20K people on our Facebook, Instagram and LinkedIn.',
    },
  },
  activeNow: 'Currently active',
  availabilityModal: {
    description: 'Let companies know how much capacity you currently have.',
  },
}
