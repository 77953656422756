import { get } from 'utils/axios'

export const STATS_FETCH = 'STATS_FETCH'

export const getPublicData = (data: any) => {
  if (!data.cached_values) {
    return []
  }

  return data.cached_values
}

export const getStats = async (dispatch) => {
  try {
    const { data } = await get(`api/caches/`)

    if (data.status === 'ok') {
      dispatch({ type: STATS_FETCH, payload: getPublicData(data) })
      return { success: true, stats: data.cached_values }
    } else {
      return { success: false, error: data.error, msg: data.msg }
    }
  } catch (err) {
    return { success: false, error: err }
  }
}
