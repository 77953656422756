import type { IGeneral } from 'redux/interfaces/general'

export type SetGeneralPrevPageContextAction = {
  type: 'SET_GENERAL_PREV_PAGE_CONTEXT'
  payload: IGeneral['prevPageContext']
}

export type SetGeneralCookiesVisibleAction = {
  type: 'SET_GENERAL_COOKIES_VISIBLE'
  payload: IGeneral['cookiesVisible']
}

export type GeneralAction =
  | SetGeneralPrevPageContextAction
  | SetGeneralCookiesVisibleAction

export const setGeneralPrevPageContext = (
  payload: any
): SetGeneralPrevPageContextAction => ({
  type: 'SET_GENERAL_PREV_PAGE_CONTEXT',
  payload,
})

export const setGeneralCookiesVisibleContext = (
  payload: any
): SetGeneralCookiesVisibleAction => ({
  type: 'SET_GENERAL_COOKIES_VISIBLE',
  payload,
})
