import { parseCookies, setCookie } from 'nookies'
import TrackingUtils from 'utils/tracking'

const cookieDuration = 356 * 24 * 60 * 60
const cookieName = 'cc_settings'

export default class CookiesBannerUtils {
  static getPolicyCookie() {
    const value = parseCookies()[cookieName]
    return value ? JSON.parse(value) : null
  }

  static setCookiesPolicy({
    required,
    analytics,
    ad,
  }: {
    required: boolean
    analytics: boolean
    ad: boolean
  }) {
    setCookie(null, cookieName, JSON.stringify({ required, analytics, ad }), {
      maxAge: cookieDuration,
      path: '/',
    })

    TrackingUtils.updateConsent({ analytics, ad })
  }

  static allowAll() {
    CookiesBannerUtils.setCookiesPolicy({
      required: true,
      analytics: true,
      ad: true,
    })
  }

  static disallow() {
    CookiesBannerUtils.setCookiesPolicy({
      required: true,
      analytics: false,
      ad: false,
    })
  }

  static set({ analytics, ad }: { analytics: boolean; ad: boolean }) {
    CookiesBannerUtils.setCookiesPolicy({
      required: true,
      analytics,
      ad,
    })
  }
}
