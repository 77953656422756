module.exports = {
  company: {
    subtitle:
      'Len 1-krát zadáte vaše firemné údaje a už ich viac od vás nebudeme potrebovať.',
    form: {
      companyNameLabel: 'Název firmy (včetně s.r.o., spol. s.r.o., a.s., ...)',
      iAmPersonalAgency: 'Jsem personální agentura',
      iAmOutsideEu: 'Jsem firma mimo EÚ',
      streetLabel: 'Ulice a číslo (Fakturační adresa)',
      emailTooltip: 'Tento e-mail slouží k přihlášení do firemního profilu.',
    },
  },
  freelancer: {
    subtitle: 'Buď součástí špičky freelance komunity a přidej se k nám. Zdarma!',
    fillTheForm: 'Vyplň formulář a vyšperkuj si profil',
    beVisibleForCompanies: 'Buď na očích u více než {{count}} firem',
    offersToEmail: 'Nabídky na práci ti budou chodit e-mailem',
    form: {
      salaryNoteLabel: 'Poznámka k hodinové sazbě',
      aboutMeLabel: 'O mně',
    },
    topBadgeTitle: 'badge za kvalitní profil',
    topBadgeText:
      'Dej si na svém profilu záležet a zvyš tak šanci zaujmout potencionálního klienta. Ty nejlepší od nás dostanou TOP badge.',
  },
  doYouHaveAccountAlready: 'Máte již vytvořen svůj účet?',
}
