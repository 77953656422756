import { post } from 'utils/axios'
import { IProfile } from 'redux/interfaces/profile'

export const PROFILE_FETCH = 'PROFILE_FETCH'
export const PROFILE_UPDATE = 'PROFILE_UPDATE'
export const PROFILE_PARTIAL_UPDATE = 'PROFILE_PARTIAL_UPDATE'

export const getPublicProfileData = (data: any) => {
  if (!data.profile) {
    return null
  }

  return {
    _id: data.profile._id,
    shortId: data.profile.shortId,
    status: data.profile.status,
    available: data.profile.available,
    availability_status: data.profile.availability_status,
    full_time_available: data.profile.full_time_available,
    public: data.profile.public,
    signup_date: data.profile.signup_date,
    born_year: data.profile.born_year,
    top: data.profile.top,

    addons: data.profile.addons || null,

    name: data.profile.name,
    email: data.profile.email,
    title: data.profile.title,
    skill_level: data.profile.skill_level,
    job_category: data.profile.job_category,
    city: data.profile.city,
    country: data.profile.city?.country,

    job_salary_min: data.profile.job_salary_min,
    job_salary_max: data.profile.job_salary_max,
    job_salary_note: data.profile.job_salary_note,

    detail: data.profile.detail,
    tags: data.profile.tags,
    modals: data.profile.modals,
    stats: data.profile.stats,

    backgroundColor: data.profile.backgroundColor,
    picture: data.profile.picture,
    freelancerType: data.profile.freelancerType,
    favouriteFreelancers: data.profile.favouriteFreelancers,

    notifications: data.profile.notifications
      ? data.profile.notifications
      : {
          products: true,
        },
  }
}

export const update =
  (profileData: any) =>
  async (dispatch): Promise<{ success: boolean }> => {
    try {
      const { data } = await post('api/profile/update/', profileData)

      if (data.status === 'ok') {
        dispatch({ type: PROFILE_UPDATE, payload: getPublicProfileData(data) })

        return { success: true }
      } else {
        return { success: false }
      }
    } catch (err) {
      return { success: false }
    }
  }

export const updateModals =
  (companyData: any) =>
  async (dispatch): Promise<{ success: boolean }> => {
    try {
      const { data } = await post('api/profile/update-modals/', companyData)

      if (data.status === 'ok') {
        dispatch({ type: PROFILE_UPDATE, payload: getPublicProfileData(data) })

        return { success: true }
      } else {
        return { success: false }
      }
    } catch (err) {
      return { success: false }
    }
  }

export const toggleVisibility =
  (profileData: any) =>
  async (dispatch): Promise<{ success: boolean }> => {
    try {
      const { data } = await post('api/profile/toggle-visibility/', profileData)

      if (data.status === 'ok') {
        dispatch({ type: PROFILE_UPDATE, payload: getPublicProfileData(data) })

        return { success: true }
      } else {
        return { success: false }
      }
    } catch (err) {
      return { success: false }
    }
  }

export const updateAvailability =
  (profileData: any) =>
  async (dispatch): Promise<{ success: boolean }> => {
    try {
      const { data } = await post('api/profile/update-availability/', profileData)
      const success = data.status === 'ok'

      if (success) {
        dispatch({ type: PROFILE_UPDATE, payload: getPublicProfileData(data) })
      }

      return { success }
    } catch (e) {
      return { success: false }
    }
  }

export const updateAdditionalInfo =
  (companyData: { shortId: string; picture: string }) =>
  async (dispatch): Promise<{ success: boolean }> => {
    try {
      const { data } = await post('api/profile/update-additional-info', companyData)
      const success = data.status === 'ok'

      if (success) {
        dispatch({ type: PROFILE_UPDATE, payload: getPublicProfileData(data) })
      }

      return { success }
    } catch (err) {
      return { success: false }
    }
  }
export const updatePartialProfileData =
  (profileData: Partial<IProfile>) => async (dispatch) => {
    dispatch({ type: PROFILE_PARTIAL_UPDATE, payload: profileData })
  }

export const toggleProfileFavouriteFreelancers =
  (companyData: { shortId: string; freelancerId: string }) =>
  async (dispatch): Promise<{ success: boolean }> => {
    try {
      const { data } = await post('api/profile/toggle-favourite-freelancer', companyData)
      const success = data.status === 'ok'

      if (success) {
        dispatch({ type: PROFILE_UPDATE, payload: getPublicProfileData(data) })
      }

      return { success }
    } catch (err) {
      return { success: false }
    }
  }

export const updateProfileNotifications =
  (id: string, notifications: any) => async (dispatch) => {
    try {
      const { data } = await post('api/profile/update-notifications', {
        id,
        notifications,
      })

      if (data.status === 'ok') {
        dispatch({ type: PROFILE_UPDATE, payload: getPublicProfileData(data) })
      }
    } catch (err) {
      console.error(err)
    }
  }
