const sk = require('./sk')
const cs = require('./cs')
const en = require('./en')

module.exports = {
  sk,
  cs,
  en,
}

function getAvailableLanguages(nodeEnv) {
  const environment = nodeEnv ?? process.env.NODE_ENV

  return environment === 'production' ? ['sk', 'cs', 'en'] : ['sk', 'cs', 'en']
}

module.exports.getAvailableLanguages = getAvailableLanguages
