module.exports = {
  introduction:
    'Chceme být otevření, proto vždy zveřejňujeme aktuální údaje o našich statistikách. Všechno jsou to reálná data. Nic smyšleného 🤟',
  webViewsPerMonth: 'Zobrazení webu za měsíc',
  jobSearchersPerMonth: 'Hledačů práce za měsíc',
  sentCVsPerMonth: 'Odeslaných CV za měsíc',
  addedJobsPerMonth: 'Přidaných jobů za měsíc',
  socialNetworkFans: 'Fans na sociálních sítích',
  newsletterSubscribers: 'Odběratelů newsletteru',
}
