module.exports = {
  controls: {
    forCompanies: 'Pro firmy',
    viewProfile: 'Zobrazit profil',
    accountSettings: 'Nastavení účtu',
    registration: 'Registrace',
    myCompany: 'Moje firma',
    forFreelancer: 'Pro freelancera',
  },
}
