module.exports = {
  'ostrov-ascension': '🇦🇨 Ascension Island',
  andorra: '🇦🇩 Andorra',
  'spojene-arabske-emiraty': '🇦🇪 United Arab Emirates',
  afganistan: '🇦🇫 Afghanistan',
  'antigua-a-barbuda': '🇦🇬 Antigua & Barbuda',
  anguilla: '🇦🇮 Anguilla',
  albansko: '🇦🇱 Albania',
  armensko: '🇦🇲 Armenia',
  angola: '🇦🇴 Angola',
  antarktida: '🇦🇶 Antarctica',
  argentina: '🇦🇷 Argentina',
  'americka-samoa': '🇦🇸 American Samoa',
  rakusko: '🇦🇹 Austria',
  australia: '🇦🇺 Australia',
  aruba: '🇦🇼 Aruba',
  'alandske-ostrovy': '🇦🇽 Åland Islands',
  azerbajdzan: '🇦🇿 Azerbaijan',
  'bosna-a-hercegovina': '🇧🇦 Bosnia & Herzegovina',
  barbados: '🇧🇧 Barbados',
  banglades: '🇧🇩 Bangladesh',
  belgicko: '🇧🇪 Belgium',
  'burkina-faso': '🇧🇫 Burkina Faso',
  bulharsko: '🇧🇬 Bulgaria',
  bahrajn: '🇧🇭 Bahrain',
  burundi: '🇧🇮 Burundi',
  benin: '🇧🇯 Benin',
  'svaty-bartolomej': '🇧🇱 St. Barthélemy',
  bermudy: '🇧🇲 Bermuda',
  brunej: '🇧🇳 Brunei',
  bolivia: '🇧🇴 Bolivia',
  'karibske-holandsko': '🇧🇶 Caribbean Netherlands',
  brazilia: '🇧🇷 Brazil',
  bahamy: '🇧🇸 Bahamas',
  bhutan: '🇧🇹 Bhutan',
  'ostrov-bouvet': '🇧🇻 Bouvet Island',
  botswana: '🇧🇼 Botswana',
  bielorusko: '🇧🇾 Belarus',
  belize: '🇧🇿 Belize',
  kanada: '🇨🇦 Canada',
  'kokosove-keeling-ostrovy': '🇨🇨 Cocos (Keeling) Islands',
  'kongo-kinshasa': '🇨🇩 Congo - Kinshasa',
  'stredoafricka-republika': '🇨🇫 Central African Republic',
  'kongo-brazzaville': '🇨🇬 Congo - Brazzaville',
  svajciarsko: '🇨🇭 Switzerland',
  'pobrezie-slonoviny': '🇨🇮 Côte d’Ivoire',
  'cookove-ostrovy': '🇨🇰 Cook Islands',
  cile: '🇨🇱 Chile',
  kamerun: '🇨🇲 Cameroon',
  cina: '🇨🇳 China',
  kolumbia: '🇨🇴 Colombia',
  'clipperton-island': '🇨🇵 Clipperton Island',
  kostarika: '🇨🇷 Costa Rica',
  kuba: '🇨🇺 Cuba',
  kapverdy: '🇨🇻 Cape Verde',
  curacao: '🇨🇼 Curaçao',
  'vianocny-ostrov': '🇨🇽 Christmas Island',
  cyprus: '🇨🇾 Cyprus',
  cesko: '🇨🇿 Czechia',
  nemecko: '🇩🇪 Germany',
  'diego-garcia': '🇩🇬 Diego Garcia',
  dzibutsko: '🇩🇯 Djibouti',
  dansko: '🇩🇰 Denmark',
  dominica: '🇩🇲 Dominica',
  'dominikanska-republika': '🇩🇴 Dominican Republic',
  alzirsko: '🇩🇿 Algeria',
  'ceuta-a-melilla': '🇪🇦 Ceuta & Melilla',
  ekvador: '🇪🇨 Ecuador',
  estonsko: '🇪🇪 Estonia',
  egypt: '🇪🇬 Egypt',
  'zapadna-sahara': '🇪🇭 Western Sahara',
  eritrea: '🇪🇷 Eritrea',
  spanielsko: '🇪🇸 Spain',
  etiopia: '🇪🇹 Ethiopia',
  'europska-unia': '🇪🇺 European Union',
  finsko: '🇫🇮 Finland',
  fiji: '🇫🇯 Fiji',
  'falklandske-ostrovy': '🇫🇰 Falkland Islands',
  mikronezia: '🇫🇲 Micronesia',
  'faerske-ostrovy': '🇫🇴 Faroe Islands',
  francuzsko: '🇫🇷 France',
  gabon: '🇬🇦 Gabon',
  'spojene-kralovstvo': '🇬🇧 United Kingdom',
  grenada: '🇬🇩 Grenada',
  gruzinsko: '🇬🇪 Georgia',
  'francuzska-guiana': '🇬🇫 French Guiana',
  guernsey: '🇬🇬 Guernsey',
  ghana: '🇬🇭 Ghana',
  gibraltar: '🇬🇮 Gibraltar',
  gronsko: '🇬🇱 Greenland',
  gambia: '🇬🇲 Gambia',
  guinea: '🇬🇳 Guinea',
  guadeloupe: '🇬🇵 Guadeloupe',
  'rovnikova-guinea': '🇬🇶 Equatorial Guinea',
  grecko: '🇬🇷 Greece',
  'juzna-georgia-and-south-sandwich-islands': '🇬🇸 South Georgia & South Sandwich Islands',
  guatemala: '🇬🇹 Guatemala',
  guam: '🇬🇺 Guam',
  'guinea-bissau': '🇬🇼 Guinea-Bissau',
  guyana: '🇬🇾 Guyana',
  'hongkong-sar-cina': '🇭🇰 Hong Kong SAR China',
  'heard-and-mcdonald-islands': '🇭🇲 Heard & McDonald Islands',
  honduras: '🇭🇳 Honduras',
  chorvatsko: '🇭🇷 Croatia',
  haiti: '🇭🇹 Haiti',
  madarsko: '🇭🇺 Hungary',
  'kanarske-ostrovy': '🇮🇨 Canary Islands',
  indonezia: '🇮🇩 Indonesia',
  irsko: '🇮🇪 Ireland',
  izrael: '🇮🇱 Israel',
  'ostrov-man': '🇮🇲 Isle of Man',
  india: '🇮🇳 India',
  'britske-indicke-oceanske-uzemie': '🇮🇴 British Indian Ocean Territory',
  irak: '🇮🇶 Iraq',
  iran: '🇮🇷 Iran',
  island: '🇮🇸 Iceland',
  taliansko: '🇮🇹 Italy',
  jersey: '🇯🇪 Jersey',
  jamajka: '🇯🇲 Jamaica',
  jordansko: '🇯🇴 Jordan',
  japonsko: '🇯🇵 Japan',
  kena: '🇰🇪 Kenya',
  kirgizsko: '🇰🇬 Kyrgyzstan',
  kambodza: '🇰🇭 Cambodia',
  kiribati: '🇰🇮 Kiribati',
  komory: '🇰🇲 Comoros',
  'svaty-kristof-a-nevis': '🇰🇳 St. Kitts & Nevis',
  'severna-korea': '🇰🇵 North Korea',
  'juzna-korea': '🇰🇷 South Korea',
  kuvajt: '🇰🇼 Kuwait',
  'kajmanske-ostrovy': '🇰🇾 Cayman Islands',
  kazachstan: '🇰🇿 Kazakhstan',
  laos: '🇱🇦 Laos',
  libanon: '🇱🇧 Lebanon',
  'svata-lucia': '🇱🇨 St. Lucia',
  lichtenstajnsko: '🇱🇮 Liechtenstein',
  'sri-lanka': '🇱🇰 Sri Lanka',
  liberia: '🇱🇷 Liberia',
  lesotho: '🇱🇸 Lesotho',
  litva: '🇱🇹 Lithuania',
  luxembursko: '🇱🇺 Luxembourg',
  lotyssko: '🇱🇻 Latvia',
  libya: '🇱🇾 Libya',
  maroko: '🇲🇦 Morocco',
  monaco: '🇲🇨 Monaco',
  moldavsko: '🇲🇩 Moldova',
  'cierna-hora': '🇲🇪 Montenegro',
  'svaty-martin': '🇲🇫 St. Martin',
  madagaskar: '🇲🇬 Madagascar',
  'marsalove-ostrovy': '🇲🇭 Marshall Islands',
  'severne-macedonsko': '🇲🇰 North Macedonia',
  mali: '🇲🇱 Mali',
  'mjanmarsko-barma': '🇲🇲 Myanmar (Burma)',
  mongolsko: '🇲🇳 Mongolia',
  'oao-macao-cina': '🇲🇴 Macao SAR China',
  'severne-mariany': '🇲🇵 Northern Mariana Islands',
  martinique: '🇲🇶 Martinique',
  mauritania: '🇲🇷 Mauritania',
  montserrat: '🇲🇸 Montserrat',
  malta: '🇲🇹 Malta',
  mauricius: '🇲🇺 Mauritius',
  maldivy: '🇲🇻 Maldives',
  malawi: '🇲🇼 Malawi',
  mexiko: '🇲🇽 Mexico',
  malajzia: '🇲🇾 Malaysia',
  mozambik: '🇲🇿 Mozambique',
  namibia: '🇳🇦 Namibia',
  'nova-kaledonia': '🇳🇨 New Caledonia',
  niger: '🇳🇪 Niger',
  'ostrov-norfolk': '🇳🇫 Norfolk Island',
  nigeria: '🇳🇬 Nigeria',
  nikaragua: '🇳🇮 Nicaragua',
  holandsko: '🇳🇱 Netherlands',
  norsko: '🇳🇴 Norway',
  nepal: '🇳🇵 Nepal',
  nauru: '🇳🇷 Nauru',
  niue: '🇳🇺 Niue',
  'novy-zeland': '🇳🇿 New Zealand',
  oman: '🇴🇲 Oman',
  panama: '🇵🇦 Panama',
  peru: '🇵🇪 Peru',
  'francuzska-polynezia': '🇵🇫 French Polynesia',
  'papua-nova-guinea': '🇵🇬 Papua New Guinea',
  filipiny: '🇵🇭 Philippines',
  pakistan: '🇵🇰 Pakistan',
  polsko: '🇵🇱 Poland',
  'st-pierre-and-miquelon': '🇵🇲 St. Pierre & Miquelon',
  'pitcairnove-ostrovy': '🇵🇳 Pitcairn Islands',
  portoriko: '🇵🇷 Puerto Rico',
  'palestinske-uzemia': '🇵🇸 Palestinian Territories',
  portugalsko: '🇵🇹 Portugal',
  palau: '🇵🇼 Palau',
  paraguaj: '🇵🇾 Paraguay',
  katar: '🇶🇦 Qatar',
  reunion: '🇷🇪 Réunion',
  rumunsko: '🇷🇴 Romania',
  srbsko: '🇷🇸 Serbia',
  rusko: '🇷🇺 Russia',
  rwanda: '🇷🇼 Rwanda',
  'saudska-arabia': '🇸🇦 Saudi Arabia',
  'salamunove-ostrovy': '🇸🇧 Solomon Islands',
  seychely: '🇸🇨 Seychelles',
  sudan: '🇸🇩 Sudan',
  svedsko: '🇸🇪 Sweden',
  singapur: '🇸🇬 Singapore',
  'svata-helena': '🇸🇭 St. Helena',
  slovinsko: '🇸🇮 Slovenia',
  'svalbard-and-jan-mayen': '🇸🇯 Svalbard & Jan Mayen',
  slovensko: '🇸🇰 Slovakia',
  'sierra-leone': '🇸🇱 Sierra Leone',
  'san-marino': '🇸🇲 San Marino',
  senegal: '🇸🇳 Senegal',
  somalsko: '🇸🇴 Somalia',
  suriname: '🇸🇷 Suriname',
  'juzny-sudan': '🇸🇸 South Sudan',
  'sao-tome-and-principe': '🇸🇹 São Tomé & Príncipe',
  'el-salvador': '🇸🇻 El Salvador',
  'sint-maarten': '🇸🇽 Sint Maarten',
  syria: '🇸🇾 Syria',
  eswatini: '🇸🇿 Eswatini',
  'tristan-da-cunha': '🇹🇦 Tristan da Cunha',
  'ostrovy-turks-and-caicos': '🇹🇨 Turks & Caicos Islands',
  cad: '🇹🇩 Chad',
  'francuzske-juzne-uzemia': '🇹🇫 French Southern Territories',
  ist: '🇹🇬 Togo',
  thajsko: '🇹🇭 Thailand',
  tadzikistan: '🇹🇯 Tajikistan',
  tokelau: '🇹🇰 Tokelau',
  'vychodny-timor': '🇹🇱 Timor-Leste',
  turkmensko: '🇹🇲 Turkmenistan',
  tunisko: '🇹🇳 Tunisia',
  tonga: '🇹🇴 Tonga',
  turecko: '🇹🇷 Turkey',
  'trinidad-a-tobago': '🇹🇹 Trinidad & Tobago',
  tuvalu: '🇹🇻 Tuvalu',
  taiwan: '🇹🇼 Taiwan',
  tanzania: '🇹🇿 Tanzania',
  ukrajina: '🇺🇦 Ukraine',
  uganda: '🇺🇬 Uganda',
  'odlahle-ostrovy-usa': '🇺🇲 U.S. Outlying Islands',
  'spojene-narody': '🇺🇳 United Nations',
  'spojene-staty': '🇺🇸 United States',
  uruguaj: '🇺🇾 Uruguay',
  uzbekistan: '🇺🇿 Uzbekistan',
  vatikan: '🇻🇦 Vatican City',
  'svaty-vincent-a-grenadiny': '🇻🇨 St. Vincent & Grenadines',
  venezuela: '🇻🇪 Venezuela',
  'britske-panenske-ostrovy': '🇻🇬 British Virgin Islands',
  'americke-panenske-ostrovy': '🇻🇮 U.S. Virgin Islands',
  vietnam: '🇻🇳 Vietnam',
  vanuatu: '🇻🇺 Vanuatu',
  'wallis-and-futuna': '🇼🇫 Wallis & Futuna',
  samoa: '🇼🇸 Samoa',
  kosovo: '🇽🇰 Kosovo',
  jemen: '🇾🇪 Yemen',
  mayotte: '🇾🇹 Mayotte',
  'juzna-afrika': '🇿🇦 South Africa',
  zambia: '🇿🇲 Zambia',
  zimbabwe: '🇿🇼 Zimbabwe',
  anglicko: '🏴󠁧󠁢󠁥󠁮󠁧󠁿 England',
  skotsko: '🏴󠁧󠁢󠁳󠁣󠁴󠁿 Scotland',
  wales: '🏴󠁧󠁢󠁷󠁬󠁳󠁿 Wales',
  bangladesh: '🇧🇩 Bangladesh',
  bulgaria: '🇧🇬 Bulgaria',
  brazil: '🇧🇷 Brazil',
  'ceska-republika': '🇨🇿 Czech Republic',
  'velka-britania': '🇬🇧 Great Britain',
  'slovenska-republika': '🇸🇰 Slovak Republic',
  usa: '🇺🇸 USA',
  remote: '🌎 Remote',
}
