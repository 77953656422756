module.exports = {
  luigisBox: `Kvalita uchádzačov bola 3x väčšia ako cez iný pracovný portál.
    Boli to relevantní uchádzači so skúsenosťami, ktorí sledujú nové
    trendy, čo potvrdzuje aj to, že prišli cez Pretlak`,
  slido: `Cez pretlak.com oslovujeme nový segment kvalifikovanejších
    kandidátov a nemusíme pritom zaťažovať náš náborový tím.`,
  berlinBrandsGroup: `Takmer všetkých uchádzačov, ktorí sa prihlásia na marketingové
    pozície cez Pretlak.com, pozývame na pohovor. Kvalita je vysoká –
    šetríme tým čas pri recruitingu.`,
  sygic: `Robíte to dobre. Nahirovali sme vďaka vám prvého človeka. Určite budeme naďalej využívať pretlak.`,
  barneyStudio: `Pretlak nám pomohol nájsť skvelých ľudí, ktorí skvelo doplnili náš tím. Vďaka Pretlaku šetríme čas, pretože nám nechodia CV od nerelevantných ľudí.`,
  twoCreateAdvertising: `Pretlak tvoria ľudia, ktorí rozumejú potrebám kreatívneho biznisu. Efektivita náboru výrazne prekonáva konkurenčné portály.`,
  imagons: `Relevancia reakcií a kvalita uchádzačov sa nedá porovnať s inými portálmi, odkiaľ sa ľudia z marketingovej branže akoby vytratili. Za nás veľká spokojnosť.`,
  darenAndCurtis: `Služby Pretlaku využívame už niekoľko rokov a berieme ich ako dôležitú súčasť našej HR stratégie.`,
  gecora: `Pretlak.sk využívame pri tech aj non-tech job postoch. Je to náš hlavný kanál, na ktorý máme preklik aj z webu gecora.com. Je to riešenie, kde "sa hľadajú" a kde si hľadajú job ľudia z IT sveta, čo je z našej skúsenosti ideálna príležitosť pre získanie tých najkvalitnejších uchádzačov.`,
  kesu: `Portál, ktorý nám vydestiloval bláznivo dobrých uchádzačov. Rýchlo, lacno a kvalitne (a ževraj to tak nejde).`,
  gymbeam: `Pretlak.sk používame veľmi radi, keďže je to moderný pracovný portál, ktorý nám pomáha spojiť sa s komunitou talentovaných kreatívcov.`,
  tvjoj: `Ďakujeme za Pretlak, výborná práca! Dnes som pridala inzerát a už mám 5 CV. Chodia mi aj priamo na email. Chcem pochváliť Vašu prácu, marketingové pozície riešim iba cez Vás. Mám rada dobrých ľudí a to som našla u vás.`,
  ovb: 'Podarilo sa nám nájsť 2 frontend programátorov vo veľmi krátkom čase. Ďakujeme za vyzdieľanie ponuky, aj keď ešte nebola uhradená, čo považujem za veľmi ústretový krok.',
}
