module.exports = {
  invoice1: `Zverejnenie pracovnej ponuky {{jobLevelName}} k pozícii {{jobName}} na webe pretlak.com`,
  invoice2: '"Zvýraznenie" pracovnej ponuky vlastnou farbou na webe pretlak.com',
  invoice3: `Topovanie na {{days}} dní na webe pretlak.com`,
  invoice4: 'Extra zdieľanie na sociálnych kanáloch pretlak.com',
  invoice5: `{{productText}} pracovných ponúk na webe pretlak.com`,
  invoice6: `{{productText}} z webu pretlak.com`,
  invoice7: `Zakúpenie režimu zobrazenia {{productName}} na webe pretlak.com`,
  invoice8: 'Mesačné predplatné. PRO členstvo - pretlak.com/freelancers',
  invoice9: 'Ročné predplatné. PRO členstvo - pretlak.com/freelancers',
  invoice10: 'Nevyužitý čas. PRO členstvo - pretlak.com/freelancers',
  invoice11: 'Mesačné predplatné. Odomknutie freelancerov - pretlak.com/freelancers',
  invoice12: 'Ročné predplatné. Odomknutie freelancerov - pretlak.com/freelancers',
  invoice13: 'Nevyužitý čas. Odomknutie freelancerov - pretlak.com/freelancers',
  invoice14: 'Topovanie profilu na webe',
  invoice15: 'Prezdieľanie profilu na sociálnych sieťach',
  invoice16: 'Zvýraznenie profilu vlastnou farbou',
}
