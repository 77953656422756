const packageJSON = require('../package.json')

module.exports = {
  MIN_PICTURE_DIMENSION: {
    'profile-picture': 1080,
    other: 360,
  },
  DATE_FROM: new Date('2020-07-24'),

  DEFAULT_COLOR: '#FFD62B',

  PROMOCODE_PRODUCT_IDS: [
    process.env.STRIPE_ID_PRODUCT_JOB_STANDARD,
    process.env.STRIPE_ID_PRODUCT_JOB_STANDARD_SMALL,
    process.env.STRIPE_ID_PRODUCT_JOB_STANDARD_LARGE,
    process.env.STRIPE_ID_PRODUCT_JOB_EXKLUZIV,
    process.env.STRIPE_ID_PRODUCT_JOB_EXKLUZIV_SMALL,
    process.env.STRIPE_ID_PRODUCT_JOB_EXKLUZIV_LARGE,
    process.env.STRIPE_ID_PRODUCT_JOB_PREMIUM,
    process.env.STRIPE_ID_PRODUCT_JOB_PREMIUM_SMALL,
    process.env.STRIPE_ID_PRODUCT_JOB_PREMIUM_LARGE,
  ],

  CUSTOM_COLORS: [
    // TODO - use this in the frontend
    '#FF431C',
    '#9B5DE5',
    '#F15BB5',
    '#FEE440',
    '#00BBF9',
    '#00F5D4',
    '#4D6CFA',
    '#F4A259',
    '#2DB77D',
    '#000000',
  ],

  PRODUCT: {
    JOB: {
      STANDARD: {
        jobLevel: '2',
        type: 'JOB',
        stripe_id: process.env.STRIPE_ID_JOB_STANDARD,
        code: 'JOB_STANDARD',
        text: 'Ponuka Štandard',
        price: 119,
        translation: {
          sk: 'Ponuka Štandard',
          cs: 'Nabídka Standard',
          en: 'Standard Offer',
        },
        days: 14,
      },
      PREMIUM: {
        jobLevel: '1',
        type: 'JOB',
        stripe_id: process.env.STRIPE_ID_JOB_PREMIUM,
        code: 'JOB_PREMIUM',
        text: 'Ponuka Prémium',
        price: 149,
        translation: {
          sk: 'Ponuka Prémium',
          cs: 'Nabídka Premium',
          en: 'Premium Offer',
        },
        days: 14,
      },
      EXCLUSIVE: {
        jobLevel: '3',
        type: 'JOB',
        stripe_id: process.env.STRIPE_ID_JOB_EXCLUSIVE,
        code: 'JOB_EXCLUSIVE',
        text: 'Ponuka Exkluzív',
        price: 399,
        translation: {
          sk: 'Ponuka Exkluzív',
          cs: 'Nabídka Excluziv',
          en: 'Exclusive Offer',
        },
        days: 14,
      },
    },
    ADDON: {
      SOCIAL_SHARE: {
        type: 'ADDON',
        stripe_id: process.env.STRIPE_ID_ADDON_SOCIAL_SHARE,
        code: 'ADDON_SOCIAL_SHARE',
        text: 'Extra zdieľanie',
        oldPrice: 49,
        price: 39,
        translation: {
          sk: 'Extra zdieľanie',
          cs: 'Extra sdílení',
          en: 'Extra sharing',
        },
        icon: '🥁',
      },
      TOP: {
        type: 'ADDON',
        stripe_id: process.env.STRIPE_ID_ADDON_TOP,
        code: 'ADDON_TOP',
        text: 'Topovanie - 7 dní',
        translation: {
          sk: 'Topovanie - 7 dní',
          cs: 'Topování - 7 dní',
          en: 'Boosting - 7 days',
        },
        days: 7,
        oldPrice: 29,
        price: 19,
        icon: '🚀',
      },
      TOP_LONG: {
        type: 'ADDON',
        stripe_id: process.env.STRIPE_ID_ADDON_TOP_LONG,
        code: 'ADDON_TOP_LONG',
        text: 'Topovanie - 14 dní',
        translation: {
          sk: 'Topovanie - 14 dní',
          cs: 'Topování - 14 dní',
          en: 'Boosting - 14 days',
        },
        days: 14,
        oldPrice: 39,
        price: 29,
        icon: '🚀',
      },
      CUSTOM_COLOR: {
        type: 'ADDON',
        stripe_id: process.env.STRIPE_ID_ADDON_CUSTOM_COLOR,
        code: 'ADDON_CUSTOM_COLOR',
        text: 'Zafarbenie ponuky',
        translation: {
          sk: 'Zafarbenie ponuky',
          cs: 'Zabarvení nabídky',
          en: 'Custom color of the Offer',
        },
        oldPrice: 49,
        price: 39,
        icon: '🎨',
      },
    },

    PACKAGE: {
      STANDARD: {
        SMALL: {
          type: 'PACKAGE',
          stripe_id: process.env.STRIPE_ID_PACKAGE_STANDARD_SMALL,
          code: 'PACKAGE_STANDARD_SMALL',
          text: 'Malý balík štandard',
          translation: {
            sk: 'Malý balík štandard',
            cs: 'Malý balík standard',
            en: 'Small Package Standard',
          },
          promoCode: {
            prefix: 'malyBalik',
            subtitle: 'Štandard',
            title: 'Malý balík',
            type: 2,
          },
          price: 476,
          size: 5,
          sizeDesc: '4+1',
        },
        MEDIUM: {
          type: 'PACKAGE',
          stripe_id: process.env.STRIPE_ID_PACKAGE_STANDARD_MEDIUM,
          code: 'PACKAGE_STANDARD_MEDIUM',
          text: 'Stredný balík štandard',
          translation: {
            sk: 'Stredný balík štandard',
            cs: 'Stredný balík standard',
            en: 'Medium Package Standard',
          },
          promoCode: {
            prefix: 'strednyBalik',
            subtitle: 'Štandard',
            title: 'Stredný balík',
            type: 2,
          },
          price: 833,
          size: 10,
          sizeDesc: '7+3',
        },
        LARGE: {
          type: 'PACKAGE',
          stripe_id: process.env.STRIPE_ID_PACKAGE_STANDARD_LARGE,
          code: 'PACKAGE_STANDARD_LARGE',
          text: 'Nekonečný balík štandard',
          translation: {
            sk: 'Nekonečný balík štandard',
            cs: 'Nekonečný balík standard',
            en: 'Infinite Package Standard',
          },
          promoCode: {
            prefix: 'nekonecnyBalik',
            subtitle: 'Štandard',
            title: 'Nekonečný balík',
            type: 2,
          },
          price: 2380,
          size: '∞',
        },
      },
      PREMIUM: {
        SMALL: {
          type: 'PACKAGE',
          stripe_id: process.env.STRIPE_ID_PACKAGE_PREMIUM_SMALL,
          code: 'PACKAGE_PREMIUM_SMALL',
          text: 'Malý balík prémium',
          translation: {
            sk: 'Malý balík prémium',
            cs: 'Malý balík prémium',
            en: 'Small Package Premium',
          },
          promoCode: {
            prefix: 'malyBalik',
            subtitle: 'Prémium',
            title: 'Malý balík',
            type: 1,
          },
          price: 596,
          size: 5,
          sizeDesc: '4+1',
        },
        MEDIUM: {
          type: 'PACKAGE',
          stripe_id: process.env.STRIPE_ID_PACKAGE_PREMIUM_MEDIUM,
          code: 'PACKAGE_PREMIUM_MEDIUM',
          text: 'Stredný balík prémium',
          translation: {
            sk: 'Stredný balík prémium',
            cs: 'Stredný balík prémium',
            en: 'Medium Package Premium',
          },
          promoCode: {
            prefix: 'strednyBalik',
            subtitle: 'Prémium',
            title: 'Stredný balík',
            type: 1,
          },
          price: 1043,
          size: 10,
          sizeDesc: '7+3',
        },
        LARGE: {
          type: 'PACKAGE',
          stripe_id: process.env.STRIPE_ID_PACKAGE_PREMIUM_LARGE,
          code: 'PACKAGE_PREMIUM_LARGE',
          text: 'Nekonečný balík prémium',
          translation: {
            sk: 'Nekonečný balík prémium',
            cs: 'Nekonečný balík prémium',
            en: 'Infinite Package Premium',
          },
          promoCode: {
            prefix: 'nekonecnyBalik',
            subtitle: 'Prémium',
            title: 'Nekonečný balík',
            type: 1,
          },
          price: 2980,
          size: '∞',
        },
      },
      EXCLUSIVE: {
        SMALL: {
          type: 'PACKAGE',
          stripe_id: process.env.STRIPE_ID_PACKAGE_EXCLUSIVE_SMALL,
          code: 'PACKAGE_EXCLUSIVE_SMALL',
          text: 'Malý balík exkluzív',
          translation: {
            sk: 'Malý balík exkluzív',
            cs: 'Malý balík exkluziv',
            en: 'Small Package Exclusive',
          },
          promoCode: {
            prefix: 'malyBalik',
            subtitle: 'Exkluzív',
            title: 'Malý balík',
            type: 3,
          },
          price: 1596,
          size: 5,
          sizeDesc: '4+1',
        },
        MEDIUM: {
          type: 'PACKAGE',
          stripe_id: process.env.STRIPE_ID_PACKAGE_EXCLUSIVE_MEDIUM,
          code: 'PACKAGE_EXCLUSIVE_MEDIUM',
          text: 'Stredný balík exkluzív',
          translation: {
            sk: 'Stredný balík exkluzív',
            cs: 'Stredný balík exkluziv',
            en: 'Medium Package Exclusive',
          },
          promoCode: {
            prefix: 'strednyBalik',
            subtitle: 'Exkluzív',
            title: 'Stredný balík',
            type: 3,
          },
          price: 2793,
          size: 10,
          sizeDesc: '7+3',
        },
        LARGE: {
          type: 'PACKAGE',
          stripe_id: process.env.STRIPE_ID_PACKAGE_EXCLUSIVE_LARGE,
          code: 'PACKAGE_EXCLUSIVE_LARGE',
          text: 'Nekonečný balík exkluzív',
          translation: {
            sk: 'Nekonečný balík exkluzív',
            cs: 'Nekonečný balík exkluziv',
            en: 'Infinite Package Exclusive',
          },
          promoCode: {
            prefix: 'nekonecnyBalik',
            subtitle: 'Exkluzív',
            title: 'Nekonečný balík',
            type: 3,
          },
          price: 3990,
          size: '∞',
        },
      },
    },

    TALENTBOX: {
      CATEGORY: {
        type: 'TALENTBOX',
        stripe_id: process.env.STRIPE_ID_TALENTBOX_CATEGORY,
        code: 'TALENTBOX_CATEGORY',
        text: 'Databáza životopisov',
        translation: {
          sk: 'Databáza životopisov',
          cs: 'Databáze životopisů',
          en: 'CVs Database',
        },
        price: 299,
      },
    },

    FREELANCERS: {
      ALL: {
        type: 'FREELANCERS',
        stripe_id: process.env.STRIPE_ID_FREELANCERS_ALL,
        code: 'FREELANCERS_ALL',
        text: 'Freelanceri - Komplet',
        price: 99,
        translation: {
          sk: 'Freelanceri - Komplet',
          cs: 'Freelanceri - Komplet',
          en: 'Freelancers - Complete',
        },
        emoji: '🔑',
      },
      CATEGORY: {
        type: 'FREELANCERS',
        stripe_id: process.env.STRIPE_ID_FREELANCERS_CATEGORY,
        code: 'FREELANCERS_CATEGORY',
        text: 'Freelanceri - Kategória',
        translation: {
          sk: 'Freelanceri - Kategória',
          cs: 'Freelanceri - Kategorie',
          en: 'Freelancers - Category',
        },
        price: 69,
        emoji: '🧩',
      },
    },

    FREELANCERPROFILE: {
      TOP: {
        type: 'FREELANCERPROFILE',
        stripe_id: process.env.STRIPE_ID_FREELANCER_PROFILE_ADDON_TOP,
        stripe_ids: [process.env.STRIPE_ID_FREELANCER_PROFILE_ADDON_TOP],
        code: 'FREELANCERPROFILE_TOP',
        price: 4.16,
        fullPrice: 4.99,
        text: 'Freelancer profil - TOP',
        icon: '🔝',
      },
      SOCIAL_SHARE: {
        type: 'FREELANCERPROFILE',
        stripe_id: process.env.STRIPE_ID_FREELANCER_PROFILE_ADDON_SOCIAL_SHARE,
        stripe_ids: [process.env.STRIPE_ID_FREELANCER_PROFILE_ADDON_SOCIAL_SHARE],
        code: 'FREELANCERPROFILE_SOCIAL_SHARE',
        price: 8.32,
        fullPrice: 9.99,
        text: 'Freelancer profil - Social Share',
        icon: '📲',
      },
      CUSTOM_COLOR: {
        type: 'FREELANCERPROFILE',
        stripe_id: process.env.STRIPE_ID_FREELANCER_PROFILE_ADDON_CUSTOM_COLOR,
        stripe_ids: [process.env.STRIPE_ID_FREELANCER_PROFILE_ADDON_CUSTOM_COLOR],
        code: 'FREELANCERPROFILE_CUSTOM_COLOR',
        price: 16.65,
        fullPrice: 19.99,
        text: 'Freelancer profil - Custom Color',
        icon: '👨🏻‍🎨',
      },
    },

    SUBSCRIPTION: {
      FREELANCERPROFILE: {
        MONTHLY: {
          type: 'SUBSCRIPTION_FREELANCERPROFILE',
          code: 'SUBSCRIPTION_FREELANCERPROFILE_MONTHLY',
          price: 16.99,
          text: 'Freelance Subscription - MONTHLY',
          stripe_id: process.env.STRIPE_ID_SUBSCRIPTION_FREELANCERPROFILE_MONTHLY,
          stripe_ids:
            process.env.ACCEPTED_STRIPE_IDS_SUBSCRIPTION_FREELANCERPROFILE_MONTHLY?.split(
              ','
            ),
        },
        YEARLY: {
          type: 'SUBSCRIPTION_FREELANCERPROFILE',
          code: 'SUBSCRIPTION_FREELANCERPROFILE_YEARLY',
          price: 83.88,
          priceMonthly: 6.99,
          sale: 0.75,
          text: 'Freelance Subscription - YEARLY',
          stripe_id: process.env.STRIPE_ID_SUBSCRIPTION_FREELANCERPROFILE_YEARLY,
          stripe_ids:
            process.env.ACCEPTED_STRIPE_IDS_SUBSCRIPTION_FREELANCERPROFILE_YEARLY?.split(
              ','
            ),
        },
      },
      FREELANCERSUNLOCK: {
        MONTHLY: {
          type: 'SUBSCRIPTION_FREELANCERSUNLOCK',
          stripe_id: process.env.STRIPE_ID_SUBSCRIPTION_COMPANYFREELANCERS_MONTHLY,
          code: 'SUBSCRIPTION_FREELANCERSUNLOCK_MONTHLY',
          price: 99,
          text: 'Freelancers unlock - MONTHLY',
        },
        YEARLY: {
          type: 'SUBSCRIPTION_FREELANCERSUNLOCK',
          stripe_id: process.env.STRIPE_ID_SUBSCRIPTION_COMPANYFREELANCERS_YEARLY,
          code: 'SUBSCRIPTION_FREELANCERSUNLOCK_YEARLY',
          price: 588,
          priceMonthly: 49,
          text: 'Freelancers unlock - YEARLY',
        },
      },
    },
  },

  SUBSCRIPTION_FREELANCERPROFILY_YEARLY_COUPON:
    process.env.SUBSCRIPTION_FREELANCERPROFILY_YEARLY_COUPON,

  JOB_NEW_MAX_AGE: 3,
  CV_EXPIRE_MONTHS: 12,

  CACHE_HOMEPAGE_LIMIT: 99,

  LAZY_LOADING: {
    LIMIT: 40,
    COMPANY_LIMIT: 42,
  },

  HOT_COMPANIES_DEFAULT_LIMIT: 4,

  COMPANIES: {
    TOTAL: 850,
  },

  USER_DB: {
    PRICE: 299,
    TOTAL: 3900,
    MARKETERS: 1350,
    DESIGNERS: 650,
    DEVELOPERS: 300,
    CREATIVES: 850,
    NONTECH: 750,
  },

  FREELANCERS: {
    TOTAL: 1500,
    DEVELOPERS: 190,
    DESIGNERS: 450,
    CREATIVES: 400,
    MARKETERS: 250,
    NONTECH: 50,
  },

  DEV_ENV: 'development',
  CURRENT_ENV: process.env.NODE_ENV || this.DEV_ENV,
  APP_VER: packageJSON.version,
  APP_PORT: process.env.PORT || 3000,
  DB_URL: process.env.DB_URL,

  // SLACK
  SLACK_TOKEN: process.env.SLACK_TOKEN,
  SLACK_CONVERSATION_JOBS: 'CD550HH5F',
  SLACK_CONVERSATION_JOBS_EDIT: 'CK50G8D44',
  SLACK_CONVERSATION_CV: 'CESC2TZ5E',
  SLACK_CONVERSATION_MANUAL_ACTION: 'C02GDF2J2JG',
  SLACK_CONVERSATION_COMPANY: 'CQRFB0KF0',
  SLACK_CONVERSATION_ERRORS: 'C01RXP25SMC',
  SLACK_CONVERSATION_USERS: 'C011L6YJUAF',
  SLACK_CONVERSATION_SUBSCRIPTIONS: 'C05QWNZ0ETA',
  SLACK_CONVERSATION_BUY: 'C01QR8XV39U',
  SLACK_CONVERSATION_INVOICE: 'C01RELW6FR7',
  SLACK_CONVERSATION_DEVELOPMENT: 'C01QYJM1ZFF',
  SLACK_CONVERSATION_NOTIFICATIONS: 'C014ZRQCKT9',

  // SENTRY
  SENTRY_ORG: process.env.SENTRY_ORG,
  SENTRY_PROJECT: process.env.SENTRY_PROJECT,
  SENTRY_AUTH_TOKEN: process.env.SENTRY_AUTH_TOKEN,
  SENTRY_DSN: process.env.SENTRY_DSN,

  // AMAZON
  AMAZON_ACCESS_KEY: (process.env.AWS_ACCESS_KEY_ID =
    process.env.AWS_ACCESS_KEY_ID || ''),
  AMAZON_SECRET_KEY: (process.env.AWS_SECRET_ACCESS_KEY =
    process.env.AWS_SECRET_ACCESS_KEY || ''),
  AMAZON_S3_BUCKET: (process.env.S3_BUCKET_NAME = process.env.S3_BUCKET_NAME || ''),
  AMAZON_S3_REGION: 'eu-central-1',

  // SUPERFAKTURA
  SUPERFAKTURA_URL: process.env.SUPERFAKTURA_URL,
  SUPERFAKTURA_AUTH: process.env.SUPERFAKTURA_AUTH,

  // OTHER SERVICE KEYS
  MAILCHIMP_KEY: process.env.MAILCHIMP_KEY || '',
  SOCIAL_POST_ID: process.env.SOCIAL_POST_ID,
  GTM_TRACKING_ID: process.env.GTM_TRACKING_ID,

  // SOCIAL IMAGE GENERATION
  SOCIAL_IMAGE: {
    WIDTH: 1080,
    HEIGHT: 1080,
    PADDING: 40,
    FONT_SIZE: 100,
    COMPANY_FONT_SIZE: 40,
    TOP_BADGE_X: 730,
    TOP_BADGE_Y: 100,
    NOJUDGMENT_BADGE_X: 50,
    NOJUDGMENT_BADGE_Y: 100,
    PRETLAK_LOGO_WIDTH: 270,
    PRETLAK_LOGO_HEIGHT: 70,
    MAX_TEXT_WIDTH: 1080 - 40 * 2, // WIDTH - PADDING * 2
    MAIN_TEXT_OFFSET_Y: 0,
    SALARY_Y: null,
    COMPANY_OFFSET_Y: 100,
    LOGO_OFFSET_Y: 100,
  },

  FEED_IMAGE: {
    WIDTH: 1080,
    HEIGHT: 1920,
    PADDING: 40,
    FONT_SIZE: 130,
    COMPANY_FONT_SIZE: 48,
    PRETLAK_LOGO_WIDTH: 370,
    PRETLAK_LOGO_HEIGHT: 96,
    MAX_TEXT_WIDTH: 1080 - 40 * 2, // WIDTH - PADDING * 2
    TOP_BADGE_X: 730,
    TOP_BADGE_Y: 100,
    MAIN_TEXT_OFFSET_Y: -130,
    SALARY_Y: 1433,
    COMPANY_OFFSET_Y: 200,
    LOGO_OFFSET_Y: 200,
  },

  FREELANCER_SOCIAL_IMAGE: {
    WIDTH: 1080,
    HEIGHT: 1080,
    PRO_BADGE_X: 0,
    PRO_BADGE_Y: 60,
    TITLE_FONT_SIZE: 80,
    TITLE_OFFSET_X: 119,
    TITLE_LAST_LINE_OFFSET_Y: 881,
    NAME_FONT_SIZE: 40,
    NAME_OFFSET_X: 119,
    NAME_OFFSET_Y: 961,
    MAX_TEXT_WIDTH: 842, // WIDTH - TITLE_OFFSET_X * 2
    DEFAULT_OVERLAY_COLOR: '#000000',
    DEFAULT_OVERLAY_OPACITY: 0.35,
  },

  STRIPE_SECRET_KEY: process.env.STRIPE_SECRET_KEY,
  STRIPE_ENDPOINT_SECRET: process.env.STRIPE_ENDPOINT_SECRET,

  STRIPE_TAX_RATE_ID_SLOVAKIA: process.env.STRIPE_TAX_RATE_ID_SLOVAKIA,
  STRIPE_TAX_RATE_ID_SLOVAKIA_INCLUSIVE:
    process.env.STRIPE_TAX_RATE_ID_SLOVAKIA_INCLUSIVE,

  OPENSEARCH_URL: process.env.OPENSEARCH_URL,
  OPENSEARCH_USERNAME: process.env.OPENSEARCH_USERNAME,
  OPENSEARCH_PASSWORD: process.env.OPENSEARCH_PASSWORD,
  OPENSEARCH_JOBS_INDEX: process.env.OPENSEARCH_JOBS_INDEX,
  OPENSEARCH_PROFILES_INDEX: process.env.OPENSEARCH_PROFILES_INDEX,
  OPENSEARCH_COMPANIES_INDEX: process.env.OPENSEARCH_COMPANIES_INDEX,

  COMPANY_PARENT_CATEGORIES: [
    {
      name: 'Agentúry & štúdiá',
      code: 'AGENCY',
    },
    {
      name: 'Médiá',
      code: 'MEDIA',
    },
    {
      name: 'Tech & Inovácie',
      code: 'TECH',
    },
    {
      name: 'Telco, financie, verejná správa',
      code: 'TELCO',
    },
    {
      name: 'Kultúra & Neziskový sektor',
      code: 'CULTURE',
    },
    {
      name: 'Ostatné',
      code: 'OTHERS',
    },
  ],

  COMPANY_CATEGORIES: [
    {
      name: 'Audio & Video produkcia',
      code: 'AUDIO_VIDEO',
      parentCode: 'AGENCY',
    },
    {
      name: 'Web dizajn & development',
      code: 'WEB_DESIGN_DEVELOPMENT',
      parentCode: 'AGENCY',
    },
    {
      name: 'Grafický dizajn',
      code: 'GRAPHICS_DESIGN',
      parentCode: 'AGENCY',
    },
    {
      name: 'Social Media & Content',
      code: 'SOCIAL_MEDIA_CONTENT',
      parentCode: 'AGENCY',
    },
    {
      name: 'PR a komunikácia',
      code: 'PR_COMMUNICATION',
      parentCode: 'AGENCY',
    },
    {
      name: 'Event',
      code: 'EVENT',
      parentCode: 'AGENCY',
    },
    {
      name: 'Tlač & výroba reklamy',
      code: 'PRINT_ADVERTISING',
      parentCode: 'AGENCY',
    },
    {
      name: 'Mediálna agentúra',
      code: 'MEDIA_AGENCY',
      parentCode: 'AGENCY',
    },
    {
      name: 'HR',
      code: 'HR',
      parentCode: 'AGENCY',
    },
    {
      name: 'Fullservis',
      code: 'FULLSERVICE',
      parentCode: 'AGENCY',
    },
    {
      name: 'Vývoj IT projektov',
      code: 'IT_PROJECTS_DEVELOPMENT',
      parentCode: 'AGENCY',
    },
    {
      name: 'TV',
      code: 'TV',
      parentCode: 'MEDIA',
    },
    {
      name: 'Rádio',
      code: 'RADIO',
      parentCode: 'MEDIA',
    },
    {
      name: 'Print',
      code: 'PRINT',
      parentCode: 'MEDIA',
    },
    {
      name: 'Vydavateľstvo',
      code: 'PUBLISHING_HOUSE',
      parentCode: 'MEDIA',
    },
    {
      name: 'Indoor / Outdoor',
      code: 'INDOOR_OUTDOOR',
      parentCode: 'MEDIA',
    },
    {
      name: 'Startupy',
      code: 'STARTUPS',
      parentCode: 'TECH',
    },
    {
      name: 'E-commerce',
      code: 'E_COMMERCE',
      parentCode: 'TECH',
    },
    {
      name: 'Saas',
      code: 'SAAS',
      parentCode: 'TECH',
    },
    {
      name: 'Gaming',
      code: 'GAMING',
      parentCode: 'TECH',
    },
    {
      name: 'Bankovníctvo',
      code: 'BANKING',
      parentCode: 'TELCO',
    },
    {
      name: 'Poisťovníctvo',
      code: 'INSURANCE',
      parentCode: 'TELCO',
    },
    {
      name: 'Telekomunikácie',
      code: 'TELECOMMUNICATIONS',
      parentCode: 'TELCO',
    },
    {
      name: 'Verejná správa',
      code: 'GOVERNMENT',
      parentCode: 'TELCO',
    },
    {
      name: 'Gastro & Hotelierstvo',
      code: 'GASTRONOMY_HOSPITALITY',
      parentCode: 'TELCO',
    },
    {
      name: 'Kultúra',
      code: 'CULTURE',
      parentCode: 'CULTURE',
    },
    {
      name: 'Umenie',
      code: 'ART',
      parentCode: 'CULTURE',
    },
    {
      name: 'Občianske združenie',
      code: 'CIVIC_ASSOCIATION',
      parentCode: 'CULTURE',
    },
    {
      name: 'Neziskové organizácie',
      code: 'NON_PROFIT_ORGANIZATIONS',
      parentCode: 'CULTURE',
    },
    {
      name: 'Vzdelávanie',
      code: 'EDUCATION',
      parentCode: 'CULTURE',
    },
    {
      name: 'Stavebníctvo a reality',
      code: 'CONSTRUCTIONS',
      parentCode: 'OTHERS',
    },
    {
      name: 'Architektúra a interiérový dizajn',
      code: 'ARCHITECTURE_INTERIOR_DESIGN',
      parentCode: 'OTHERS',
    },
    {
      name: 'Retail',
      code: 'RETAIL',
      parentCode: 'OTHERS',
    },
    {
      name: 'Iná katégoria',
      code: 'OTHER_CATEGORY',
      parentCode: 'OTHERS',
    },
  ],

  COMPANY_RECOMMENDATION: [
    {
      value: 'FRIEND',
      text: {
        sk: 'Odporúčanie od známeho',
        cs: 'Doporučení od známého',
        en: 'Recommendation',
      },
    },
    {
      value: 'INTERNET_AD',
      text: {
        sk: 'Reklama na internete',
        cs: 'Reklama na internetu',
        en: 'Online ad',
      },
    },
    {
      value: 'MAGAZINE_AD',
      text: {
        sk: 'Reklama v časopise',
        cs: 'Reklama v časopise',
        en: 'Magazine ad',
      },
    },
    {
      value: 'YOUTUBE_AD',
      text: {
        sk: 'Reklama na Youtube',
        cs: 'Reklama na Youtube',
        en: 'Youtube ad',
      },
    },
    {
      value: 'TV_AD',
      text: {
        sk: 'Reklama v TV',
        cs: 'Reklama v TV',
        en: 'TV ad',
      },
    },
    {
      value: 'GOOGLE',
      text: {
        sk: 'Google',
        cs: 'Google',
        en: 'Google',
      },
    },
  ],

  CDN_URL: process.env.CDN_URL,
  APP_URL: process.env.APP_URL,
  CATEGORY_SLUG: {
    DEVELOPERS: 'developeri',
    DESIGNERS: 'dizajneri',
    CREATIVES: 'kreativci',
    MARKETERS: 'marketeri',
    NONTECH: 'nontech',
    OTHER: 'other',
  },
  SLOVAK_COUNTRY_SLUGS: ['sk', 'slovenska-republika', 'slovensko'],
  CZECH_COUNTRY_SLUGS: ['cz', 'ceska-republika', 'cesko', 'cs'],
  SUPERFAKTURA_LANGUAGE: {
    SK: 'slo',
    CS: 'cze',
    EN: 'eng',
  },
  COUNTRIES: [
    { value: 'ostrov-ascension', label: '🇦🇨 Ostrov Ascension' },
    { value: 'andorra', label: '🇦🇩 Andorra' },
    {
      value: 'spojene-arabske-emiraty',
      label: '🇦🇪 Spojené Arabské Emiráty',
    },
    { value: 'afganistan', label: '🇦🇫 Afganistan' },
    { value: 'antigua-a-barbuda', label: '🇦🇬 Antigua a Barbuda' },
    { value: 'anguilla', label: '🇦🇮 Anguilla' },
    { value: 'albansko', label: '🇦🇱 Albánsko' },
    { value: 'armensko', label: '🇦🇲 Arménsko' },
    { value: 'angola', label: '🇦🇴 Angola' },
    { value: 'antarktida', label: '🇦🇶 Antarktida' },
    { value: 'argentina', label: '🇦🇷 Argentína' },
    { value: 'americka-samoa', label: '🇦🇸 Americká Samoa' },
    { value: 'rakusko', label: '🇦🇹 Rakúsko' },
    { value: 'australia', label: '🇦🇺 Austrália' },
    { value: 'aruba', label: '🇦🇼 Aruba' },
    { value: 'alandske-ostrovy', label: '🇦🇽 Alandské ostrovy' },
    { value: 'azerbajdzan', label: '🇦🇿 Azerbajdžan' },
    { value: 'bosna-a-hercegovina', label: '🇧🇦 Bosna a Hercegovina' },
    { value: 'barbados', label: '🇧🇧 Barbados' },
    { value: 'banglades', label: '🇧🇩 Bangladéš' },
    { value: 'belgicko', label: '🇧🇪 Belgicko' },
    { value: 'burkina-faso', label: '🇧🇫 Burkina Faso' },
    { value: 'bulharsko', label: '🇧🇬 Bulharsko' },
    { value: 'bahrajn', label: '🇧🇭 Bahrajn' },
    { value: 'burundi', label: '🇧🇮 Burundi' },
    { value: 'benin', label: '🇧🇯 Benin' },
    { value: 'svaty-bartolomej', label: '🇧🇱 Svätý Bartolomej' },
    { value: 'bermudy', label: '🇧🇲 Bermudy' },
    { value: 'brunej', label: '🇧🇳 Brunej' },
    { value: 'bolivia', label: '🇧🇴 Bolívia' },
    { value: 'karibske-holandsko', label: '🇧🇶 Karibské Holandsko' },
    { value: 'brazilia', label: '🇧🇷 Brazília' },
    { value: 'bahamy', label: '🇧🇸 Bahamy' },
    { value: 'bhutan', label: '🇧🇹 Bhután' },
    { value: 'ostrov-bouvet', label: '🇧🇻 Ostrov Bouvet' },
    { value: 'botswana', label: '🇧🇼 Botswana' },
    { value: 'bielorusko', label: '🇧🇾 Bielorusko' },
    { value: 'belize', label: '🇧🇿 Belize' },
    { value: 'kanada', label: '🇨🇦 Kanada' },
    {
      value: 'kokosove-keeling-ostrovy',
      label: '🇨🇨 Kokosové (Keeling) ostrovy',
    },
    { value: 'kongo-kinshasa', label: '🇨🇩 Kongo – Kinshasa' },
    {
      value: 'stredoafricka-republika',
      label: '🇨🇫 Stredoafrická republika',
    },
    { value: 'kongo-brazzaville', label: '🇨🇬 Kongo – Brazzaville' },
    { value: 'svajciarsko', label: '🇨🇭 Švajčiarsko' },
    { value: 'pobrezie-slonoviny', label: '🇨🇮 Pobrežie Slonoviny' },
    { value: 'cookove-ostrovy', label: '🇨🇰 Cookove ostrovy' },
    { value: 'cile', label: '🇨🇱 Čile' },
    { value: 'kamerun', label: '🇨🇲 Kamerun' },
    { value: 'cina', label: '🇨🇳 Čína' },
    { value: 'kolumbia', label: '🇨🇴 Kolumbia' },
    { value: 'clipperton-island', label: '🇨🇵 Clipperton Island' },
    { value: 'kostarika', label: '🇨🇷 Kostarika' },
    { value: 'kuba', label: '🇨🇺 Kuba' },
    { value: 'kapverdy', label: '🇨🇻 Kapverdy' },
    { value: 'curacao', label: '🇨🇼 Curaçao' },
    { value: 'vianocny-ostrov', label: '🇨🇽 vianočný ostrov' },
    { value: 'cyprus', label: '🇨🇾 Cyprus' },
    { value: 'ceska-republika', label: '🇨🇿 Česká republika' },
    { value: 'nemecko', label: '🇩🇪 Nemecko' },
    { value: 'diego-garcia', label: '🇩🇬 Diego Garcia' },
    { value: 'dzibutsko', label: '🇩🇯 Džibutsko' },
    { value: 'dansko', label: '🇩🇰 Dánsko' },
    { value: 'dominica', label: '🇩🇲 Dominica' },
    {
      value: 'dominikanska-republika',
      label: '🇩🇴 Dominikánska republika',
    },
    { value: 'alzirsko', label: '🇩🇿 Alžírsko' },
    { value: 'ceuta-a-melilla', label: '🇪🇦 Ceuta a Melilla' },
    { value: 'ekvador', label: '🇪🇨 Ekvádor' },
    { value: 'estonsko', label: '🇪🇪 Estónsko' },
    { value: 'egypt', label: '🇪🇬 Egypt' },
    { value: 'zapadna-sahara', label: '🇪🇭 Západná Sahara' },
    { value: 'eritrea', label: '🇪🇷 Eritrea' },
    { value: 'spanielsko', label: '🇪🇸 Španielsko' },
    { value: 'etiopia', label: '🇪🇹 Etiópia' },
    { value: 'europska-unia', label: '🇪🇺 Európska únia' },
    { value: 'finsko', label: '🇫🇮 Fínsko' },
    { value: 'fiji', label: '🇫🇯 Fiji' },
    { value: 'falklandske-ostrovy', label: '🇫🇰 Falklandské ostrovy' },
    { value: 'mikronezia', label: '🇫🇲 Mikronézia' },
    { value: 'faerske-ostrovy', label: '🇫🇴 Faerské ostrovy' },
    { value: 'francuzsko', label: '🇫🇷 Francúzsko' },
    { value: 'gabon', label: '🇬🇦 Gabon' },
    { value: 'spojene-kralovstvo', label: '🇬🇧 Spojene kralovstvo' },
    { value: 'grenada', label: '🇬🇩 Grenada' },
    { value: 'gruzinsko', label: '🇬🇪 Gruzínsko' },
    { value: 'francuzska-guiana', label: '🇬🇫 Francúzska Guiana' },
    { value: 'guernsey', label: '🇬🇬 Guernsey' },
    { value: 'ghana', label: '🇬🇭 Ghana' },
    { value: 'gibraltar', label: '🇬🇮 Gibraltár' },
    { value: 'gronsko', label: '🇬🇱 Grónsko' },
    { value: 'gambia', label: '🇬🇲 Gambia' },
    { value: 'guinea', label: '🇬🇳 Guinea' },
    { value: 'guadeloupe', label: '🇬🇵 Guadeloupe' },
    { value: 'rovnikova-guinea', label: '🇬🇶 Rovníková Guinea' },
    { value: 'grecko', label: '🇬🇷 Grécko' },
    {
      value: 'juzna-georgia-and-south-sandwich-islands',
      label: '🇬🇸 Južná Georgia & South Sandwich Islands',
    },
    { value: 'guatemala', label: '🇬🇹 Guatemala' },
    { value: 'guam', label: '🇬🇺 Guam' },
    { value: 'guinea-bissau', label: '🇬🇼 Guinea-Bissau' },
    { value: 'guyana', label: '🇬🇾 Guyana' },
    { value: 'hongkong-sar-cina', label: '🇭🇰 Hongkong SAR Čína' },
    {
      value: 'heard-and-mcdonald-islands',
      label: '🇭🇲 Heard & McDonald Islands',
    },
    { value: 'honduras', label: '🇭🇳 Honduras' },
    { value: 'chorvatsko', label: '🇭🇷 Chorvátsko' },
    { value: 'haiti', label: '🇭🇹 Haiti' },
    { value: 'madarsko', label: '🇭🇺 Maďarsko' },
    { value: 'kanarske-ostrovy', label: '🇮🇨 Kanarske ostrovy' },
    { value: 'indonezia', label: '🇮🇩 Indonézia' },
    { value: 'irsko', label: '🇮🇪 Írsko' },
    { value: 'izrael', label: '🇮🇱 Izrael' },
    { value: 'ostrov-man', label: '🇮🇲 Ostrov Man' },
    { value: 'india', label: '🇮🇳 India' },
    {
      value: 'britske-indicke-oceanske-uzemie',
      label: '🇮🇴 Britské indické oceánske územie',
    },
    { value: 'irak', label: '🇮🇶 Irak' },
    { value: 'iran', label: '🇮🇷 Irán' },
    { value: 'island', label: '🇮🇸 Island' },
    { value: 'taliansko', label: '🇮🇹 Taliansko' },
    { value: 'jersey', label: '🇯🇪 Jersey' },
    { value: 'jamajka', label: '🇯🇲 Jamajka' },
    { value: 'jordansko', label: '🇯🇴 Jordánsko' },
    { value: 'japonsko', label: '🇯🇵 Japonsko' },
    { value: 'kena', label: '🇰🇪 Keňa' },
    { value: 'kirgizsko', label: '🇰🇬 Kirgizsko' },
    { value: 'kambodza', label: '🇰🇭 Kambodža' },
    { value: 'kiribati', label: '🇰🇮 Kiribati' },
    { value: 'komory', label: '🇰🇲 komory' },
    {
      value: 'svaty-kristof-a-nevis',
      label: '🇰🇳 Svätý Krištof a Nevis',
    },
    { value: 'severna-korea', label: '🇰🇵 Severná Kórea' },
    { value: 'juzna-korea', label: '🇰🇷 Južná Kórea' },
    { value: 'kuvajt', label: '🇰🇼 Kuvajt' },
    { value: 'kajmanske-ostrovy', label: '🇰🇾 Kajmanské ostrovy' },
    { value: 'kazachstan', label: '🇰🇿 Kazachstan' },
    { value: 'laos', label: '🇱🇦 Laos' },
    { value: 'libanon', label: '🇱🇧 Libanon' },
    { value: 'svata-lucia', label: '🇱🇨 Svätá Lucia' },
    { value: 'lichtenstajnsko', label: '🇱🇮 Lichtenštajnsko' },
    { value: 'sri-lanka', label: '🇱🇰 Srí Lanka' },
    { value: 'liberia', label: '🇱🇷 Libéria' },
    { value: 'lesotho', label: '🇱🇸 Lesotho' },
    { value: 'litva', label: '🇱🇹 Litva' },
    { value: 'luxembursko', label: '🇱🇺 Luxembursko' },
    { value: 'lotyssko', label: '🇱🇻 Lotyšsko' },
    { value: 'libya', label: '🇱🇾 Líbya' },
    { value: 'maroko', label: '🇲🇦 Maroko' },
    { value: 'monaco', label: '🇲🇨 Monaco' },
    { value: 'moldavsko', label: '🇲🇩 Moldavsko' },
    { value: 'cierna-hora', label: '🇲🇪 Čierna hora' },
    { value: 'svaty-martin', label: '🇲🇫 Svätý Martin' },
    { value: 'madagaskar', label: '🇲🇬 Madagaskar' },
    { value: 'marsalove-ostrovy', label: '🇲🇭 Maršalove ostrovy' },
    { value: 'severne-macedonsko', label: '🇲🇰 Severné Macedónsko' },
    { value: 'mali', label: '🇲🇱 Mali' },
    { value: 'mjanmarsko-barma', label: '🇲🇲 Mjanmarsko (Barma)' },
    { value: 'mongolsko', label: '🇲🇳 Mongolsko' },
    { value: 'oao-macao-cina', label: '🇲🇴 OAO Macao Čína' },
    { value: 'severne-mariany', label: '🇲🇵 Severné Mariány' },
    { value: 'martinique', label: '🇲🇶 Martinique' },
    { value: 'mauritania', label: '🇲🇷 Mauritánia' },
    { value: 'montserrat', label: '🇲🇸 Montserrat' },
    { value: 'malta', label: '🇲🇹 Malta' },
    { value: 'mauricius', label: '🇲🇺 Maurícius' },
    { value: 'maldivy', label: '🇲🇻 Maldivy' },
    { value: 'malawi', label: '🇲🇼 Malawi' },
    { value: 'mexiko', label: '🇲🇽 Mexiko' },
    { value: 'malajzia', label: '🇲🇾 Malajzia' },
    { value: 'mozambik', label: '🇲🇿 Mozambik' },
    { value: 'namibia', label: '🇳🇦 Namíbia' },
    { value: 'nova-kaledonia', label: '🇳🇨 Nová Kaledónia' },
    { value: 'niger', label: '🇳🇪 Niger' },
    { value: 'ostrov-norfolk', label: '🇳🇫 Ostrov Norfolk' },
    { value: 'nigeria', label: '🇳🇬 Nigéria' },
    { value: 'nikaragua', label: '🇳🇮 Nikaragua' },
    { value: 'holandsko', label: '🇳🇱 Holandsko' },
    { value: 'norsko', label: '🇳🇴 Nórsko' },
    { value: 'nepal', label: '🇳🇵 Nepál' },
    { value: 'nauru', label: '🇳🇷 Nauru' },
    { value: 'niue', label: '🇳🇺 Niue' },
    { value: 'novy-zeland', label: '🇳🇿 Nový Zéland' },
    { value: 'oman', label: '🇴🇲 Omán' },
    { value: 'panama', label: '🇵🇦 Panama' },
    { value: 'peru', label: '🇵🇪 Peru' },
    { value: 'francuzska-polynezia', label: '🇵🇫 Francúzska Polynézia' },
    { value: 'papua-nova-guinea', label: '🇵🇬 Papua-Nová Guinea' },
    { value: 'filipiny', label: '🇵🇭 Filipíny' },
    { value: 'pakistan', label: '🇵🇰 Pakistan' },
    { value: 'polsko', label: '🇵🇱 Poľsko' },
    {
      value: 'st-pierre-and-miquelon',
      label: '🇵🇲 St. Pierre & Miquelon',
    },
    { value: 'pitcairnove-ostrovy', label: '🇵🇳 Pitcairnove ostrovy' },
    { value: 'portoriko', label: '🇵🇷 Portoriko' },
    { value: 'palestinske-uzemia', label: '🇵🇸 Palestínske územia' },
    { value: 'portugalsko', label: '🇵🇹 Portugalsko' },
    { value: 'palau', label: '🇵🇼 Palau' },
    { value: 'paraguaj', label: '🇵🇾 Paraguaj' },
    { value: 'katar', label: '🇶🇦 Katar' },
    { value: 'reunion', label: '🇷🇪 Réunion' },
    { value: 'rumunsko', label: '🇷🇴 Rumunsko' },
    { value: 'srbsko', label: '🇷🇸 Srbsko' },
    { value: 'rusko', label: '🇷🇺 Rusko' },
    { value: 'rwanda', label: '🇷🇼 Rwanda' },
    { value: 'saudska-arabia', label: '🇸🇦 Saudská Arábia' },
    { value: 'salamunove-ostrovy', label: '🇸🇧 Šalamúnove ostrovy' },
    { value: 'seychely', label: '🇸🇨 Seychely' },
    { value: 'sudan', label: '🇸🇩 Sudán' },
    { value: 'svedsko', label: '🇸🇪 Švédsko' },
    { value: 'singapur', label: '🇸🇬 Singapur' },
    { value: 'svata-helena', label: '🇸🇭 Svätá Helena' },
    { value: 'slovinsko', label: '🇸🇮 Slovinsko' },
    {
      value: 'svalbard-and-jan-mayen',
      label: '🇸🇯 Svalbard & Jan Mayen',
    },
    { value: 'slovenska-republika', label: '🇸🇰 Slovenská republika' },
    { value: 'sierra-leone', label: '🇸🇱 Sierra Leone' },
    { value: 'san-marino', label: '🇸🇲 San Marino' },
    { value: 'senegal', label: '🇸🇳 Senegal' },
    { value: 'somalsko', label: '🇸🇴 Somálsko' },
    { value: 'suriname', label: '🇸🇷 Suriname' },
    { value: 'juzny-sudan', label: '🇸🇸 Južný Sudán' },
    { value: 'sao-tome-and-principe', label: '🇸🇹 São Tomé & Principe' },
    { value: 'el-salvador', label: '🇸🇻 El Salvador' },
    { value: 'sint-maarten', label: '🇸🇽 Sint Maarten' },
    { value: 'syria', label: '🇸🇾 Sýria' },
    { value: 'eswatini', label: '🇸🇿 Eswatini' },
    { value: 'tristan-da-cunha', label: '🇹🇦 Tristan da Cunha' },
    {
      value: 'ostrovy-turks-and-caicos',
      label: '🇹🇨 Ostrovy Turks & Caicos',
    },
    { value: 'cad', label: '🇹🇩 Čad' },
    {
      value: 'francuzske-juzne-uzemia',
      label: '🇹🇫 Francúzske južné územia',
    },
    { value: 'ist', label: '🇹🇬 Ísť' },
    { value: 'thajsko', label: '🇹🇭 Thajsko' },
    { value: 'tadzikistan', label: '🇹🇯 Tadžikistan' },
    { value: 'tokelau', label: '🇹🇰 Tokelau' },
    { value: 'vychodny-timor', label: '🇹🇱 Východný Timor' },
    { value: 'turkmensko', label: '🇹🇲 Turkménsko' },
    { value: 'tunisko', label: '🇹🇳 Tunisko' },
    { value: 'tonga', label: '🇹🇴 Tonga' },
    { value: 'turecko', label: '🇹🇷 Turecko' },
    { value: 'trinidad-a-tobago', label: '🇹🇹 Trinidad a Tobago' },
    { value: 'tuvalu', label: '🇹🇻 Tuvalu' },
    { value: 'taiwan', label: '🇹🇼 Taiwan' },
    { value: 'tanzania', label: '🇹🇿 Tanzánia' },
    { value: 'ukrajina', label: '🇺🇦 Ukrajina' },
    { value: 'uganda', label: '🇺🇬 Uganda' },
    { value: 'odlahle-ostrovy-usa', label: '🇺🇲 Odľahlé ostrovy USA' },
    { value: 'spojene-narody', label: '🇺🇳 Spojené národy' },
    { value: 'spojene-staty', label: '🇺🇸 Spojené štáty' },
    { value: 'uruguaj', label: '🇺🇾 Uruguaj' },
    { value: 'uzbekistan', label: '🇺🇿 Uzbekistan' },
    { value: 'vatikan', label: '🇻🇦 Vatikán' },
    {
      value: 'svaty-vincent-a-grenadiny',
      label: '🇻🇨 Svätý Vincent a Grenadíny',
    },
    { value: 'venezuela', label: '🇻🇪 Venezuela' },
    {
      value: 'britske-panenske-ostrovy',
      label: '🇻🇬 Britské Panenské ostrovy',
    },
    {
      value: 'americke-panenske-ostrovy',
      label: '🇻🇮 Americké Panenské ostrovy',
    },
    { value: 'vietnam', label: '🇻🇳 Vietnam' },
    { value: 'vanuatu', label: '🇻🇺 Vanuatu' },
    { value: 'wallis-and-futuna', label: '🇼🇫 Wallis & Futuna' },
    { value: 'samoa', label: '🇼🇸 Samoa' },
    { value: 'kosovo', label: '🇽🇰 Kosovo' },
    { value: 'jemen', label: '🇾🇪 Jemen' },
    { value: 'mayotte', label: '🇾🇹 Mayotte' },
    { value: 'juzna-afrika', label: '🇿🇦 Južná Afrika' },
    { value: 'zambia', label: '🇿🇲 Zambia' },
    { value: 'zimbabwe', label: '🇿🇼 Zimbabwe' },
    { value: 'anglicko', label: '🏴󠁧󠁢󠁥󠁮󠁧󠁿 Anglicko' },
    { value: 'skotsko', label: '🏴󠁧󠁢󠁳󠁣󠁴󠁿 Škótsko' },
    { value: 'wales', label: '🏴󠁧󠁢󠁷󠁬󠁳󠁿 Wales' },
    {
      value: 'remote',
      label: '🌎 Remote',
    },
  ],
  FREELANCER_POSITION_NAME_MAX_LENGTH: 90,
}
