import React, { FC } from 'react'
import TrackingUtils from 'utils/tracking'
import useTranslation from 'translations/hooks/useTranslation'

const TopBar: FC = () => {
  const { t } = useTranslation()

  const trackClick = (type: string) => {
    TrackingUtils.event('topbar-click', {
      type: type,
    })

    window.open('https://bratiska.sk/vysledky-vyhladavania?search=pretlak', '_blank')
  }

  return (
    <div className="top-bar">
      <div
        className="top-bar__item top-bar__item--merch"
        onClick={() => trackClick('merch-banner')}
      >
        <span className="desktop">
          {t('common.topBar.merch')}{' '}
          <span className="underline">{t('common.topBar.merchUnderline')}.</span>
        </span>

        <span className="mobile">{t('common.topBar.merchMobile')}</span>
      </div>
    </div>
  )
}

export default TopBar
