import { Locale } from 'translations/types'

export const LANGUAGE_SHORTCUT_LABEL = {
  [Locale.Sk]: 'SK',
  [Locale.Cs]: 'CZ',
  [Locale.En]: 'EN',
}

export const LANGUAGE_ICON = {
  [Locale.Sk]: '🇸🇰',
  [Locale.Cs]: '🇨🇿',
  [Locale.En]: '🇬🇧',
}
