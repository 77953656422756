import { IProduct } from 'redux/interfaces/product'
import {
  PRODUCTS_FETCH,
  PRODUCTS_ITEM_UPDATE,
  PRODUCTS_ITEM_ADD,
} from '../managers/products'

export const defaultProductsState: IProduct[] = null

const updateItem = (item, state) => {
  const items = state.map((currentItem) => {
    if (currentItem._id === item._id) {
      return item
    }

    return currentItem
  })

  return items
}

const addItem = (item, state) => {
  const items = state
  items.unshift(item)

  return items
}

const products = (state = defaultProductsState, action) => {
  switch (action.type) {
    case PRODUCTS_ITEM_ADD:
      return addItem(action.payload, state)
    case PRODUCTS_ITEM_UPDATE:
      return updateItem(action.payload, state)
    case PRODUCTS_FETCH:
      return action.payload
    default:
      return state
  }
}

export default products
