import React, { PureComponent } from 'react'
import classnames from 'classnames'
import Translate from 'translations/Translate'

interface IProps {
  type?: string
  id?: string
}

interface IState {
  visible: boolean
}

export default class NotificationQueryBar extends PureComponent<IProps, IState> {
  state: IState = {
    visible: this.props.type ? true : false,
  }

  componentDidMount() {
    if (this.props.type) {
      this.hide()
    }
  }

  componentDidUpdate(prevProps: IProps) {
    const { type, id } = this.props

    if (type && id !== prevProps.id) {
      this.setState({ visible: true }, () => {
        this.hide()
      })
    }
  }

  hide = () => {
    setTimeout(() => {
      this.setState({ visible: false })
    }, 10000)
  }

  typeExist = (type) => {
    return [
      'job-edit-ok',
      'company-edit-ok',
      'settings-edit-ok',
      'profile-edit-ok',
      'signup-ok',
      'notification-edit-ok',
      'notification-edit-ko',
    ].includes(type)
  }

  getEmoji = () => {
    switch (this.props.type) {
      case 'job-edit-ok':
      case 'company-edit-ok':
      case 'settings-edit-ok':
      case 'profile-edit-ok':
      case 'signup-ok':
      case 'notification-edit-ok':
        return '🙌'
      case 'notification-edit-ko':
        return '😳'
      default:
        return null
    }
  }

  isError = () => {
    return this.props.type === 'notification-edit-ko'
  }

  render() {
    if (!this.props.type || !this.state.visible) {
      return null
    }

    const emoji = this.getEmoji()

    return (
      <Translate>
        {(t) => (
          <div
            className={classnames('notification', {
              'notification--error': this.isError(),
            })}
          >
            <p className="paragraph paragraph--small">
              {emoji && <span className="notification__emoji">{emoji} </span>}
              {t(
                this.typeExist(this.props.type)
                  ? `notifications[${this.props.type}]`
                  : 'notifications.registrationSuccess'
              )}
            </p>
          </div>
        )}
      </Translate>
    )
  }
}
