import { IUser } from 'redux/interfaces/user'
import {
  USER_FETCH,
  USER_LOGIN,
  USER_SIGNUP,
  USER_PARTIAL_UPDATE,
} from 'redux/managers/user'

export const defaultUserState: IUser = null

const user = (state = defaultUserState, action) => {
  switch (action.type) {
    case USER_SIGNUP:
    case USER_LOGIN:
    case USER_FETCH:
      return action.payload
    case USER_PARTIAL_UPDATE:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export default user
