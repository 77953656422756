import React, { FC, useState } from 'react'
import Link from 'next/link'

type IProps = {
  appType: string
  variant?: 'default' | 'black'
  isStatic?: boolean
  onClick?(): void
}

const HeaderLogo: FC<IProps> = ({ appType, variant = 'default', isStatic, onClick }) => {
  const [logoIndex, setLogoIndex] = useState(0)

  const generateLogoIndex = () => {
    const min = 0
    const max = 6
    let random

    do {
      random = Math.floor(Math.random() * (max - min)) + min
    } while (random === logoIndex)

    setLogoIndex(random)
  }

  const path = variant === 'default' ? 'logo' : 'logo/logo-black'
  const index = isStatic ? 1 : logoIndex + 1

  const getHomepageUrl = () => {
    if (appType === 'freelancers') {
      return '/freelancers'
    }

    return '/'
  }

  return (
    <Link
      href={getHomepageUrl()}
      className="header__logo-wrapper"
      onClick={onClick}
      onMouseEnter={generateLogoIndex}
    >
      <img
        className="header__logo header__logo--visible"
        src={`/images/${path}/logo_${index}.svg`}
        alt="Pretlak logo"
      />
    </Link>
  )
}

export default React.memo(HeaderLogo, (prevP, nextP) => prevP === nextP)
