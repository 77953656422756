import {
  JOB_DRAFT_DELETE,
  JOB_DRAFT_SAVE,
  JOB_DRAFTS_FETCH,
} from 'redux/managers/jobDrafts'

export const defaultJobDraftsState = []

const jobDrafts = (state = defaultJobDraftsState, action) => {
  switch (action.type) {
    case JOB_DRAFTS_FETCH:
      return action.payload
    case JOB_DRAFT_SAVE:
      if (state.find((jobDraft) => jobDraft.shortId === action.payload.shortId)) {
        return [...state].map((jobDraft) => {
          if (jobDraft.shortId === action.payload.shortId) {
            return action.payload
          }

          return jobDraft
        })
      }

      return [action.payload, ...state]
    case JOB_DRAFT_DELETE:
      return [...state].filter((jobDraft) => jobDraft.shortId !== action.payload)
    default:
      return state
  }
}

export default jobDrafts
