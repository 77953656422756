import axios from 'axios'
import { HOST } from 'utils/helpers'
import TrackingUtils from 'utils/tracking'

export const PRODUCTS_FETCH = 'PRODUCTS_FETCH'
export const PRODUCTS_ITEM_UPDATE = 'PRODUCTS_ITEM_UPDATE'
export const PRODUCTS_ITEM_ADD = 'PRODUCTS_ITEM_ADD'

export const getPublicProductsData = (data: any) => {
  return data.products || null
}

// export const jobBuyAddon =
//   (buyData: any) =>
//   async (
//     dispatch
//   ): Promise<{ success: boolean; job?: any; error?: string; msg?: string }> => {
//     try {
//       const { data } = await axios({
//         method: 'post',
//         url: `${HOST}/api/job/buy-addon/`,
//         data: buyData,
//       })

//       if (data.status === 'ok') {
//         dispatch({ type: JOBS_ITEM_UPDATE, payload: data.job })

//         return { ...data, success: true }
//       } else {
//         return { success: false, error: data.error, msg: data.msg }
//       }
//     } catch (err) {
//       return { success: false, error: err }
//     }
//   }
