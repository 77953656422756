import { Reducer } from 'redux'
import { CompanyAction } from 'redux/action-creators/company'
import { ICompany } from 'redux/interfaces/company'

export const defaultCompanyState: ICompany = null

const company: Reducer<ICompany, CompanyAction> = (
  state = defaultCompanyState,
  action
) => {
  switch (action.type) {
    case 'COMPANY_FETCH':
      return action.payload
    case 'COMPANY_UPDATE':
      return action.payload
    default:
      return state
  }
}

export default company
