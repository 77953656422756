module.exports = {
  introduction:
    'Chceme byť otvorení a tak vždy zverejňujeme aktuálne údaje o našich štatistikách. Všetko sú to reálne dáta. Nič vymyslené 🤟',
  webViewsPerMonth: 'Zobrazení webu za mesiac',
  jobSearchersPerMonth: 'Hľadačov práce za mesiac',
  sentCVsPerMonth: 'Odoslaných CV za mesiac',
  addedJobsPerMonth: 'Pridaných jobov za mesiac',
  socialNetworkFans: 'Fans na sociálnych sieťach',
  newsletterSubscribers: 'Odberaťeľov newslettra',
}
