import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import updateLocale from 'dayjs/plugin/updateLocale'

export const initDayjs = () => {
  dayjs.extend(relativeTime)
  dayjs.extend(updateLocale)
  dayjs.updateLocale('en', {
    relativeTime: {
      future: 'dnes',
      past: 'dnes',
      s: 'dnes',
      ss: 'dnes',
      m: 'dnes',
      mm: 'dnes',
      h: 'dnes',
      hh: 'dnes',
      d: '1d',
      dd: '%dd',
      //@ts-ignore
      w: '1t',
      ww: '%dt',
      M: '1m',
      MM: '%dm',
      y: '1y',
      yy: '%dy',
    },
  })
}
