import {
  setGeneralPrevPageContext,
  setGeneralCookiesVisibleContext,
} from '../action-creators/general'

export const setPrevPageContext = (prevPageContext: string) => async (dispatch) => {
  dispatch(setGeneralPrevPageContext(prevPageContext))
}

export const setCookiesVisibleContext = (cookiesVisible: boolean) => async (dispatch) => {
  dispatch(setGeneralCookiesVisibleContext(cookiesVisible))
}
