module.exports = {
  tracesSampleRate: 0.1,
  ignoreErrors: [
    'TypeError: Failed to fetch',
    'TypeError: NetworkError when attempting to fetch resource.',
    'Non-Error exception captured',
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
    // Generic error code from errors outside the security sandbox
    // You can delete this if using raven.js > 1.0, which ignores these automatically.
    'Script error.',
    // Avast extension error
    '_avast_submit',

    // pretlak customs
    'Loading initial props cancelled',
    'Route did not complete loading',
    'bq is not defined',
    'Exception thrown from pushed function',
    'Hydration failed because the initial UI does',
    'There was an error while hydrating.',
    'Text content does not match server-rendered HTML.',
    /Network Error/i,
    /Request Aborted/i,
    /timeout/i,
    /Cancel rendering route/i,
    /Illegal argument/i,
    /bq is not defined/i,
    /Failed to load script/i,
    /The same @mention is only allowed once every/i,
    /TypeError: Failed to fetch/i,
    /RangeError/i,
    /ReferenceError/i,
    /Maximum call stack size exceeded/i,
  ],
  denyUrls: [
    // Google Adsense
    /pagead\/js/i,
    /pagead2\/js/i,
    /pagead2\.googlesyndication\.com/i,
    /googlesyndication\.com/i,
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
}
