import { FC } from 'react'
import Tippy, { TippyProps } from '@tippyjs/react'
import classNames from 'classnames'
import useHasMounted from 'hooks/useHasMounted'
import 'tippy.js/dist/tippy.css'

type IProps = TippyProps & {
  white?: boolean
  defaultCursor?: boolean
}

const Tooltip: FC<IProps> = ({ children, ...props }) => {
  const hasMounted = useHasMounted()

  if (!hasMounted) {
    return null
  }

  const { white, defaultCursor, ...tippyProps } = props

  if (!tippyProps.content) {
    return null
  }

  return (
    <Tippy
      {...tippyProps}
      animation={false}
      className={classNames('tippy-custom', {
        'tippy-white': white,
      })}
    >
      {children ? (
        <span
          className={classNames('tooltip__custom-button', {
            'tooltip__custom-button--cursor-default': defaultCursor,
          })}
        >
          {children}
        </span>
      ) : (
        <span
          className={classNames('tooltip__button', {
            'tooltip__button--white': white,
          })}
        />
      )}
    </Tippy>
  )
}

export default Tooltip
