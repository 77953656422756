import React, { useEffect } from 'react'
import twemoji from 'twemoji'

type ParseCallback = (icon: string, options: object, variant: string) => string | false

type TwemojiOptions = {
  /**
   * Default: MaxCDN
   */
  base?: string
  /**
   * Default: .png
   */
  ext?: string
  /**
   * Default: emoji
   */
  className?: string
  /**
   * Default: 72x72
   */
  size?: string | number
  /**
   * To render with SVG use `folder: svg, ext: .svg`
   */
  folder?: string
  /**
   * The function to invoke in order to generate image src(s).
   */
  callback?: ParseCallback
  /**
   * The function to invoke in order to generate additional, custom attributes for the image tag.
   * Default () => ({})
   * @param icon the lower case HEX code point i.e. "1f4a9"
   * @param variant variant the optional \uFE0F ("as image") variant, in case this info is anyhow meaningful. By default this is ignored.
   *
   */
  attributes?(icon: string, variant: string): object
}

interface IProps {
  children: React.ReactNode
  // NOTE: When it is true, Twemoji will not render a wrapping element (with tag) to contain its children. Note that since twemoji.parse needs a DOM element reference, any direct pure text child of Twemoji is not parsed when noWrapper is true. E.g. foo in <Twemoji noWrapper={true}>foo<p>bar</p></Twemoji> is not parsed.
  noWrapper?: boolean
  // NOTE: The tag of the wrapping element. This option is ignored when noWrapper is true.
  tag?: string
  // NOTE: twemoji.parse options
  options?: TwemojiOptions
}

const EmojiParser: React.FC<IProps> = ({ children, noWrapper, options, tag }) => {
  const childrenRefs = React.createRef<HTMLElement>()
  const rootRef = React.createRef<HTMLElement>()
  const baseUrl = 'https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/'

  const parseTwemoji = () => {
    if (noWrapper) {
      for (const i in childrenRefs) {
        const node = childrenRefs[i].current
        twemoji.parse(node, { ...options, base: baseUrl })
      }
    } else {
      const node = rootRef.current
      twemoji.parse(node, { ...options, base: baseUrl })
    }
  }

  useEffect(() => {
    parseTwemoji()
  }, [children])

  if (noWrapper) {
    return (
      <>
        {React.Children.map(children, (c, i) => {
          if (typeof c === 'string') {
            // eslint-disable-next-line no-console
            console.warn(
              `Twemoji can't parse string child when noWrapper is set. Skipping child "${c}"`
            )
            return c
          }
          childrenRefs[i] = childrenRefs[i] || React.createRef()

          if (React.isValidElement(c)) {
            return React.cloneElement(c, {
              ref: childrenRefs[i],
            } as React.HTMLAttributes<unknown>)
          }

          // Fallback
          return null
        })}
      </>
    )
  } else {
    return React.createElement(
      tag,
      { ref: rootRef } as React.HTMLAttributes<unknown>,
      children
    )
  }
}

export default EmojiParser
