import Constants from 'server/constants'
import { getSlug, HOST } from 'utils/helpers'
import { IJob } from 'redux/interfaces/job'
import AddonUtils from 'utils/addon'
import { COLORS } from 'components/form/ColorPicker'
import { CategorySlug } from 'types/category'
import { TranslationFunction } from 'translations/types'
import OgUtils from 'utils/og'
import type { ICompany } from 'redux/interfaces/company'

const datediff = (first, second) => {
  return Math.round((first - second) / (1000 * 60 * 60 * 24))
}

export default class JobUtils {
  static getJobLevelName(jobLevel: number) {
    switch (jobLevel) {
      case 1:
        return 'Prémium'
      case 3:
        return 'Exkluzív'
      case 2:
      default:
        return 'Štandard'
    }
  }

  static getJobEmoji(job) {
    switch (job.job_level) {
      case 1:
        return '💎'
      case 3:
        return '👑'
      case 2:
      default:
        return '🎈'
    }
  }

  static getJobDays(job) {
    switch (job.job_level) {
      case 1:
        return Constants.PRODUCT.JOB.PREMIUM.days
      case 3:
        return Constants.PRODUCT.JOB.EXCLUSIVE.days
      case 2:
      default:
        return Constants.PRODUCT.JOB.STANDARD.days
    }
  }

  static normalizeStr(str) {
    if (!str) {
      return str
    }

    return getSlug(str.trim())
  }

  static getPrice(jobLevel) {
    if (jobLevel === 1) {
      return Constants.PRODUCT.JOB.PREMIUM.price
    } else if (jobLevel === 2) {
      return Constants.PRODUCT.JOB.STANDARD.price
    } else if (jobLevel === 3) {
      return Constants.PRODUCT.JOB.EXCLUSIVE.price
    }
  }

  static getJobDefaultLenght(job) {
    if (job.job_level === 1) {
      return Constants.PRODUCT.JOB.PREMIUM.days
    } else if (job.job_level === 2) {
      return Constants.PRODUCT.JOB.STANDARD.days
    } else if (job.job_level === 3) {
      return Constants.PRODUCT.JOB.EXCLUSIVE.days
    }
  }

  static getJobAddonDatePrice(job) {
    if (job.job_level === 1 || job.job_level === 3) {
      return 29
    } else if (job.job_level === 2) {
      return 19
    }

    return 19
  }

  static getJobAddonPrice(
    data: {
      top: boolean
      topLong: boolean
      socialShare: boolean
      customColor: boolean
    },
    jobLevel?: number
  ) {
    let price = 0

    if (data.top) {
      price += Constants.PRODUCT.ADDON.TOP.price
    }

    if (data.topLong) {
      price += Constants.PRODUCT.ADDON.TOP_LONG.price
    }

    if (data.socialShare) {
      price += Constants.PRODUCT.ADDON.SOCIAL_SHARE.price
    }

    if (data.customColor) {
      price += AddonUtils.getCustomColorPrice(jobLevel)
    }

    return price
  }

  static isPremiumOrExclusive(job) {
    return job.job_level === 1 || job.job_level === 3
  }

  static isExclusiveTopActive(job) {
    if (job.job_level === 3) {
      const endDate = new Date(job.create_date)
      endDate.setDate(endDate.getDate() + Constants.PRODUCT.JOB.EXCLUSIVE.days)

      return endDate > new Date()
    }

    return false
  }

  static isPremiumOrExclusivePositionActive(job) {
    if (job.sponsored) {
      return true
    }

    if (job.job_level === 1 || job.job_level === 3) {
      const endDate = new Date(job.create_date)
      const expireDays =
        job.job_level === 1
          ? Constants.PRODUCT.JOB.PREMIUM.days
          : Constants.PRODUCT.JOB.EXCLUSIVE.days
      endDate.setDate(endDate.getDate() + expireDays)

      return endDate > new Date()
    }

    return false
  }

  static isExpired(job) {
    if (job.public && job.status === 'online') {
      const expireDate = new Date(job.create_date)

      if (expireDate < Constants.DATE_FROM) {
        if (job.job_level === 1) {
          expireDate.setDate(expireDate.getDate() + Constants.PRODUCT.JOB.PREMIUM.days)
        } else if (job.job_level === 2) {
          expireDate.setDate(expireDate.getDate() + Constants.PRODUCT.JOB.STANDARD.days)
        } else if (job.job_level === 3) {
          expireDate.setDate(expireDate.getDate() + Constants.PRODUCT.JOB.EXCLUSIVE.days)
        }

        return expireDate < new Date()
      }

      return false
    }

    return false
  }

  static getOnlineJobsLength(jobs) {
    let publicJobs = 0

    jobs.forEach((job) => {
      if (job.public && job.status === 'online' && !JobUtils.isExpired(job)) {
        publicJobs = publicJobs + 1
      }
    })

    return publicJobs
  }

  static getStatus(job) {
    if (JobUtils.isExpired(job)) {
      return 'expired'
    }

    return job.status
  }

  static getContactEmail(job) {
    if (job.email) {
      return job.email
    }

    return job.company_full.email
  }

  static isJobOnlineOrPaused(job) {
    return job.status === 'online' || job.status === 'unpublished'
  }

  static sortOnlineJobs(jobs) {
    return jobs.sort((a, b) => {
      if (JobUtils.isExpired(a) && !JobUtils.isExpired(b)) {
        return 1
      }

      if (!JobUtils.isExpired(a) && JobUtils.isExpired(b)) {
        return -1
      }

      if (a.status === 'closed' && b.status !== 'closed') {
        return 1
      }

      if (a.status !== 'closed' && b.status === 'closed') {
        return -1
      }

      if (a.create_date > b.create_date) {
        return -1
      }

      if (a.create_date < b.create_date) {
        return 1
      }

      return 0
    })
  }

  static getTopDaysLeft(job) {
    if (job.addons.topStart) {
      const daysPublished = datediff(new Date(), new Date(job.addons.topStart))

      return job.addons.topDays - daysPublished
    }

    const daysPublished = datediff(new Date(), new Date(job.create_date))
    const expireDays =
      job.job_level === 1
        ? Constants.PRODUCT.JOB.PREMIUM.days
        : Constants.PRODUCT.JOB.EXCLUSIVE.days

    return expireDays - daysPublished
  }

  static getIsNewLimitDate() {
    const isNewArticleData = new Date()
    isNewArticleData.setDate(isNewArticleData.getDate() - Constants.JOB_NEW_MAX_AGE)

    return isNewArticleData
  }

  static getTopDaysLeftText(job, t: TranslationFunction) {
    const daysLeft = JobUtils.getTopDaysLeft(job)

    return daysLeft === 0
      ? t('jobs.todayOnly')
      : t('jobs.daysLeft', {
          count: daysLeft,
        })
  }

  static isAddonTopActive(job) {
    if (!job) {
      return null
    }

    const { addons } = job

    if (addons.topStart) {
      const endDate = new Date(addons.topStart)
      endDate.setDate(endDate.getDate() + addons.topDays)

      return endDate > new Date()
    }

    return false
  }

  static isTopActive(job) {
    if (!job) {
      return null
    }

    const { addons } = job

    if (addons.topStart) {
      const endDate = new Date(addons.topStart)
      endDate.setDate(endDate.getDate() + addons.topDays)

      return endDate > new Date()
    }

    return JobUtils.isExclusiveTopActive(job)
  }

  static getTopText(job, t: TranslationFunction) {
    const isActive = JobUtils.isTopActive(job)

    if (isActive) {
      return JobUtils.getTopDaysLeftText(job, t)
    }

    return t(
      job.addons.topStart || job.job_level === 1 || job.job_level === 3
        ? 'common.finished'
        : 'common.notUsed'
    )
  }

  static getCategoryDbUsers(categorySlug: CategorySlug) {
    switch (categorySlug) {
      case CategorySlug.Developers:
        return Constants.USER_DB.DEVELOPERS
      case CategorySlug.Designers:
        return Constants.USER_DB.DESIGNERS
      case CategorySlug.Creatives:
        return Constants.USER_DB.CREATIVES
      case CategorySlug.Marketers:
        return Constants.USER_DB.MARKETERS
      case CategorySlug.Nontech:
        return Constants.USER_DB.NONTECH
    }
  }

  static getPublishedDays(job: IJob) {
    return datediff(new Date(), new Date(job.create_date))
  }

  static getCompanyName(job) {
    return job.company_public_name || job.company_full.name
  }

  static getJobName(job) {
    return job.name
  }

  /**
   * Returns text for skill levels of a job, requires t translation function.
   *
   * @param {IJob} job - The job object containing the skill levels.
   * @param {TranslationFunction} t - The translation function used for localization.
   * @param {boolean} [emoji=true] - Whether to include emoji in the skill level names.
   * @return {string} The text with skill level(s) of the job, separated by commas.
   */
  static getSkillLevelText(job, t) {
    return job.skillLevels
      .sort((a, b) => a.position - b.position)
      .map((sl) => JobUtils.skillLevelSlugToText(sl.slug, t))
      .filter((sl) => sl !== null)
      .join(', ')
  }

  static skillLevelSlugToText(slug, t) {
    const text = t(`freelancers.skillLevel.plain.${slug}`)
    if (!text) return null
    else return text
  }

  static getLocations(job) {
    let locations = ''

    job.job_location.map((loc, index) => {
      locations += index === 0 ? loc.name : ', ' + loc.name
    })

    return locations
  }

  static getMetaDescription(job) {
    let jobSalary = JobUtils.getSalary(job)
    let metaDescription = ''

    if (jobSalary !== '-') {
      metaDescription += `${jobSalary} | `
    }

    metaDescription += `${job.job_type.name} | `

    job.job_location.forEach((loc, index) => {
      metaDescription += index === 0 ? loc.name : ', ' + loc.name
    })

    return metaDescription
  }

  static getMetaImage(job) {
    const logo = job.company_job_logo || job.company_full.about.logo

    if (!logo) {
      return `${HOST}/images/og/fb-share.png`
    }

    const imageId = OgUtils.getLogoIdFromUrl(logo)

    return `${HOST}/api/og-logo?imageId=${imageId}`
  }

  static hasSalary(job) {
    if (!job.job_salary_fixed && !job.job_salary_min && !job.job_salary_max) {
      return false
    }

    return true
  }

  static getSalary(job, t?: TranslationFunction) {
    const currency = job.currency
    const currencySymbol = currency === 'CZK' ? 'Kč' : '€'

    if (!job.job_salary_fixed && !job.job_salary_min && !job.job_salary_max) {
      return '-'
    }

    const jobSalary = job.job_salary_fixed
      ? `${t ? t('common.from') : 'od'} ${job.job_salary_fixed}\u00A0${currencySymbol}`
      : job.job_salary_min === job.job_salary_max
      ? `${job.job_salary_min}\u00A0${currencySymbol}`
      : `${job.job_salary_min} — ${job.job_salary_max}\u00A0${currencySymbol}`

    return jobSalary
  }

  static getDefaultFormData() {
    return {
      email: '',
      companyPublicName: '',
      companyJobLogo: '',
      jobLevel: '',
      jobPosition: '',
      skillLevels: [],
      jobCategory: '',
      jobCity: [],
      remote: false,
      jobType: 'full-time',
      salaryNote: '',
      introductionText: '',
      about: '',
      requirements: '',
      benefits: '',
      promoCode: '',
      salarySingleFromCheckbox: false,
      salaryFrom: '',
      salaryTo: '',
      salarySingleFrom: '',
      salaryTpp: false,
      salaryInvoice: false,
      packageSmall: false,
      packageMedium: false,
      packageLarge: false,
      customColor: false,
      customColorValue: COLORS[0],
      talentboxCategory: false,
      hrUrl: '',
      promoVideo: '',
    }
  }

  static hasPackageSelected(packages: {
    packageSmall: boolean
    packageMedium: boolean
    packageLarge: boolean
  }) {
    return packages?.packageSmall || packages?.packageMedium || packages?.packageLarge
  }

  static setPrefilledPromoCode(
    promoCodes: any,
    jobLevel: number,
    packages: { packageSmall: boolean; packageMedium: boolean; packageLarge: boolean }
  ) {
    let code = ''

    if (promoCodes.length > 0) {
      promoCodes.find((promoCode) => {
        if (promoCode.type === jobLevel || promoCode.type === 0) {
          code = promoCode.code

          return true
        }
      })
    }

    if (JobUtils.hasPackageSelected(packages)) {
      return ''
    }

    return code
  }

  static isJobOwnedByCompany(job: IJob, company: ICompany) {
    return job.company_full?._id === company?.id
  }

  static isOnline(job: IJob) {
    return job.status === 'online'
  }

  static isClosed(job: IJob) {
    return job.status === 'closed'
  }
}
