module.exports = {
  name: 'Neriešime',
  tag: '#Neriešime',
  topbar: {
    text: '#NERIEŠTE spolu s nami so zľavou 20 % na každú ponuku.',
    cta: 'Pridať job',
    ctaMobile: 'Zistiť viac',
  },
  hp: {
    text: 'Na pracovisku by sme nemali bojovať so svojou identitou. Na pretlak.sk nájdeš joby, v ktorých môžeš byť sám alebo sama sebou, alebo kľudne aj oboje. #neriešime',
    cta: 'Zistiť viac ',
    ctaSecondary: 'Prehľad jobov',
  },
  job: {
    heading: '#Neriešime',
    text: 'Pri výberovom konaní budú všetci kandidáti posudzovaní rovnako bez ohľadu na ich pohlavie, etnicitu, náboženstvo alebo sexuálnu orientáciu. ',
  },
  login: {
    heading: '#NERIEŠIME',
    text: 'U nás si každý nájde job, kde môže byť sám sebou. <br/><br/> Pridajte sa aj vy k firmám, ktorým záleží na spokojnosti všetkých svojich zamestnancov.',
    cta: 'Zistiť viac',
  },
  microsite: {
    cta: 'Súhlasíte? Pridajte sa k nám!',
    header: {
      heading: '#NERIEŠIME',
      subHeading: 'Pracujte v prostredí, kde môžete byť sami sebou.',
      text: 'V Pretlaku veríme, že diverzita na pracovisku prináša mnoho benefitov a nikto by sa v práci nemal stretnúť s diskrimináciou. Zamestnávatelia, ktorí sa rozhodnú náš manifest podporiť, tým verejne deklarujú, že budú pre svojich zamestnancov a zamestnankyne aktívne vytvárať a udržiavať inkluzívne pracovné prostredie. Uchádzači o pracovnú pozíciu sa tak môžu spoľahnúť na to, že vo svojej budúcej práci budú môcť byť bez obáv sami sebou, bez ohľadu na ich sexuálnu orientáciu, pohlavie, vek, etnicitu, náboženstvo alebo zdravotné znevýhodnenie.',
    },
    intro: {
      1: {
        heading: '👀 Prečo <br/>#NERIEŠIME?',
        text: 'Stále je medzi nami veľa ľudí, ktorí vidia svet podľa zažitých predstáv o tom, ako má „normálny“ človek vyzerať, koho má milovať, čomu má veriť a podobne. Podľa nás sú tieto „šablóny dokonalosti“ jednoducho nezmysel. A preto bojujeme za to, aby sme ako spoločnosť prestali riešiť to „iné“ a videli iba to podstatné.',
      },
      2: {
        heading: '🥰 Spokojní <br/>zamestnanci <br/>sú produktívnejší',
        text: 'Zamestnanci a zamestnankyne, ktorí musia na pracovisku skrývať svoju sexuálnu orientáciu, majú až o 73 % väčšiu pravdepodobnosť, že odídu z práce do troch rokov. Viac ako 70 % LGBT ľudí uviedlo, že pravdepodobnejšie príjmu prácu v spoločnosti, ktorá otvorene podporuje LGBT než v akejkoľvek inej. Navyše aj samotná angažovanosť a produktivita LGBT zamestnancov sa znižuje až o 30 %, pokiaľ pracovné prostredie vnímajú ako nepriateľské. Zamestnávatelia tak vďaka inkluzívnej atmosfére získavajú spokojnejších a výkonnejších zamestnancov. <br/><i>(Zdroj: Credit Suisse: LGBT- The Value of Diversity)</i>',
      },

      boxes: {
        1: {
          number: 'až o 30 % ',
          text: 'sa znižuje produktivita LGBT zamestnancov, pokiaľ je pracovné prostredie nepriateľské',
        },
        2: {
          number: 'viac ako 70%',
          text: 'LGBT ľudí uviedlo, že pravdepodobnejšie príjmu prácu v spoločnosti, ktorá otvorene podporuje LGBT',
        },
        3: {
          number: 'až o 73 % ',
          text: 'väčšia pravdepodobnosť, že odídu z práce, majú ľudia, ktorí musia skrývať svoju sexuálnu orientáciu',
        },
      },
    },
    main: {
      heading: '📢 TO HLAVNÉ, ČO RIEŠIME:',
      items: {
        A: '1. Ku každému človeku pristupujeme férovo a bez rozdielu.',
        C: '2. Aktívne riešime problémy na pracovisku a venujeme sa prevencii ich vzniku.',
        E: '3. Združujeme zamestnávateľov, ktorým záleží na spokojnosti ich zamestnancov a zamestnankýň.',
        B: '4. Zaujímame sa o to, ako sa ľudia cítia a počúvame ich.',
        D: '5. Šírime osvetu v oblasti diverzity a inklúzie na pracovisku.',
      },
    },

    terms: {
      heading: '🫶 Dôležité pojmy',
      items: {
        1: {
          heading: 'Inklúzia',
          text: 'Slovo inklúzia alebo začlenenie označuje vytvorenie bezpečného prostredia, kde môže byť každý jednotlivec sám sebou. Ľudia sa v takomto prostredí vzájomne úprimne tolerujú, podporujú a majú rovnaké možnosti bez rozdielu. Človek vo fungujúcom inkluzívnom prostredí cíti, že „zapadá“ a nestretáva sa so žiadnou formou diskriminácie od svojho okolia.',
        },
        2: {
          heading: 'Diverzita',
          text: 'Slovo diverzita alebo rozmanitosť označuje rozdielnosť medzi jednotlivcami alebo skupinami. Ak hovoríme o viditeľnej rozmanitosti, máme na mysli rôznorodosť v zmysle veku, pohlavia, etnicity, náboženstva, zdravotného postihnutia, sexuálnej orientácie, vzdelania či národnosti. Ako neviditeľnú diverzitu označujeme aspekty ako je odlišnosť v uvažovaní, v životných skúsenostiach či duševnom prežívaní.',
        },
        3: {
          heading: 'LGBTQ+',
          text: 'Skratka LGBTQ+ označuje ľudí menšinových sexuálnych orientácií. Týchto päť písmen odkazuje konkrétne na lesby, gayov, bisexuálov, transrodové osoby, queer a tzv. ‘questioning’ ľudí, ktorí po svojej orientácii stále pátrajú. Za touto skratkou sa často pridávajú ďalšie písmená a znamenie plus, ktoré odkazuje na ostatné sexuálne identity ako sú napr. pansexuáli, polyamori, asexuáli a ďalší. ',
        },
        4: {
          heading: 'Queer',
          text: 'Queer je všeobecné označenie pre ľudí, ktorí sa neidentifikujú ako heterosexuáli ani ako cisgenderi (ľudia, ktorých sexuálna identita korešponduje s pohlavím, ktoré im bolo pridelené pri narodení). Tento pojem sa často zamieňa s pojmom Drag Queen, čo je však pomenovanie pre umelca, väčšinou muža, ktorý sa v rámci svojho originálneho umeleckého predstavenia prezlieka za ženy. Drag queen sú typickí extravagantným oblečením, parochňami a výrazným make-upom.',
        },
      },
    },

    join: {
      heading: '#NERIEŠTE S NAMI!',
      subheading:
        'Súhlasíte s našim manifestom? Dajte to vedieť aj ostatným! Je to jednoduché.',
      items: {
        1: 'Zaregistrujte vašu firmu alebo sa prihláste do svojho firemného profilu na našom webe.',
        2: 'Pridajte nový job a označte ho tagom #neriešime.',
        3: 'Budujte vo vašej firme inkluzívne pracovné prostredie a šírte osvetu o tejto téme, kde sa len dá!',
      },
      cta: 'Pridajte job, ktorý #nerieši.',
    },

    faq: {
      heading: '🗯 Často kladené otázky',
      items: [
        {
          question: 'Čo je Charta Diverzity?',
          answer: `Charta diverzity je projektom Európskej komisie, ktorej cieľom je podpora a zavádzanie diverzity, flexibility a inklúzie na pracovný trh v krajinách EU. <br/><br/>

          Neváhajte sa obrátiť s vašimi otázkami na koordinátorov Charty Diverzity v Česku a na Slovensku: <br/><br/>
            🇨🇿 <a href="https://diverzita.cz/">https://diverzita.cz/</a><br/>
            🇸🇰 <a href="https://www.chartadiverzity.sk/">https://www.chartadiverzity.sk/</a>`,
        },
        {
          question: 'Čo znamená byť signatárom Charty Diverzity?',
          answer: `“Podpisom Charty diverzity signatár nielenže deklaruje uznanie princípu diverzity, ale zaväzuje sa tieto princípy na pracovisku rozvíjať. K tomu ako podpora slúžia programy a aktivity organizované BPS - Byznys pro společnost, národným koordinátorom Charty diverzity. Pretože úsilie vynakladané na rozvoj diverzity vnímame ako sústavnú a nikdy nekončiacu prácu, je stanovené minimálne 3ročné obdobie pre pristupujúcich signatárov.” <br/>
            <i>Zdroj: <a href="https://diverzita.cz/cs">https://diverzita.cz/cs</a></i>

          <br/><br/>

          <strong>Staňte sa aj vy signatárom Charty Diverzity!</strong><br/>
          Pre českých zamestnávateľov:  <a href="https://diverzita.cz/cs/stante-se-signatarem-charty-diverzity">https://diverzita.cz/cs/stante-se-signatarem-charty-diverzity</a> <br/>
          Pre slovenských zamestnávateľov: <a href="https://www.chartadiverzity.sk/charta-diverzity-sr/podmienky-pristupu/">https://www.chartadiverzity.sk/charta-diverzity-sr/podmienky-pristupu/</a>`,
        },
        {
          question:
            'Sú organizácie venujúce sa tejto téme, na ktoré sa ako zamestnávateľ môžem obrátiť?',
          answer: `Áno, tu sú niektoré z nich:<br /><br />

<strong>Pride business forum</strong><br/>
Výročná konferencia Pride Business Forum je najväčšia konferencia v Českej republike zameraná na rovnosť LGBTQ+ ľudí na pracovisku.<br/>
<a href="https://www.praguepride.cz/ ">https://www.praguepride.cz/</a><br/><br/>

<strong>Transparent</strong><br/>
Organizácia Trans*parent z. s. sa snaží o presadzovanie práv a pozitívnych spoločenských zmien v prospech transgender, nebinárnych a intersex osôb.<br/>
<a href="https://jsmetransparent.cz">https://jsmetransparent.cz</a><br/><br/>

<strong>Inakosť</strong><br/>
Slovenská iniciatíva Inakosť spája ľudí a organizácie, ktoré hľadajú riešenie na zlepšenie života lesieb, gayov, bisexuálnych, transrodových a intersexuálnych ľudí, aby boli plnohodnotnou súčasťou svojich rodín, pracovných a školských kolektívov, miestnych spoločenstiev, cirkví a celej slovenskej spoločnosti.<br/>
<a href="https://inakost.sk">https://inakost.sk</a>`,
        },
        {
          question:
            'Ako mám ako zamestnávateľ postupovať pri vytváraní inkluzívneho prostredia pre zamestnancov z LGBTQ+ komunity?',
          answer: `Verejná ochrankyňa práv vo výskume Být LGBT+ v Česku z roku 2019 odporúča nasledovné kroky: <br/><br/>

“1. Realizovať opatrenia na podporu diverzity na pracovisku, vytvoriť efektívny mechanizmus predchádzania obťažovania na pracovisku a plán postupu pre prípad, že sa obťažovanie na pracovisku objaví<br/><br/>

2. Postupovať efektívne a voliť účinné opatrenia v prípade, že sa na pracovisku vyskytne obťažovanie z dôvodu sexuálnej orientácie alebo rodovej identity.<br/><br/>

3. Zvýšiť povedomie LGBT+ zamestnancov o zákonnom sťažnostnom mechanizme, ktorý môžu na pracovisku využiť v prípade, že sa stanú obeťou diskriminácie.<br/><br/>

4. Metodicky usmerniť orgány inšpekcie práce vo vzťahu ku kontrolám rovnakého zaobchádzania na pracovisku.” <br/><br/>
<i>Zdroj: <a href="http://www.ochrance.cz/diskriminace/vyzkum/">http://www.ochrance.cz/diskriminace/vyzkum/</a></i>

          `,
        },
        {
          question:
            'Čo odporúčame ako prvý krok v ústrety diverzite a inklúzii vo vašej firme my v Pretlaku?',
          answer:
            'Predovšetkým pristupovať ku všetkým kolegom a zamestnancom bez rozdielu a s rešpektom. ❤️',
        },
      ],
    },

    jobs: {
      heading: '🚀 JOBY, V KTORÝCH #NERIEŠIME',
    },
  },

  companyModal: {
    heading: '#NERIEŠIME',
    items: [
      {
        heading: '💬 Prečo #neriešime?',
        text: 'Stále je medzi nami veľa ľudí, ktorí vidia svet podľa zažitých predstáv o tom, ako má „normálny” človek vyzerať, koho má milovať, čomu má veriť a podobne. Podľa nás sú tieto „šablóny dokonalosti” jednoducho nezmysel. A preto bojujeme za to, aby sme ako spoločnosť prestali riešiť to „iné” a videli len to podstatné. <a href="/manifest">Zobrazit viac</a>',
      },
      {
        heading: '🫶 Neriešte s nami!',
        text: 'Pridajte nový job a označte ho tagom #neriešime. Budujte vo vašej firme inkluzívne pracovné prostredie a šírte osvetu o tejto téme, kde sa len dá!',
      },
    ],
  },
}
