module.exports = {
  name: 'No judgement',
  tag: '#nojudgement',
  topbar: {
    text: '#NOJUDGEMENT for a 20% discount for every offer.',
    cta: 'Add a job',
    ctaMobile: 'Learn more',
  },
  hp: {
    text: 'We shouldn’t have to fight with our identity while being in a workplace. You will find jobs where you can be yourself as a man, a woman, or even both. #nojudgement',
    cta: 'Learn more',
    ctaSecondary: 'Job list',
  },
  job: {
    heading: '#nojudgement',
    text: 'I declare that I will review all candidates equally during the hiring process, regardless of their gender, ethnicity, religion or sexual orientation.',
  },
  login: {
    heading: '#NOJUDGEMENT',
    text: 'With us everybody will find a job where they can be themselves. <br/><br/>Join the companies that care about the happiness of all of their employees.',
    cta: 'Learn more',
  },
  microsite: {
    cta: 'Do you agree? Join us!',
    header: {
      heading: '#NOJUDGEMENT',
      subHeading: 'Work in an environment where you can be yourself.',
      text: 'At Pretlak, we believe that diversity in a workplace provides a lot of benefits and nobody should be discriminated against. Employers supporting our manifesto therefore declare that they will create and maintain an inclusive workplace for their employees. That way the candidates can feel free to be themselves in their future jobs, regardless of their sexual orientation, gender, age, ethnicity, religion or medical disability.',
    },
    intro: {
      1: {
        heading: '👀 Why <br/>#NOJUDGEMENT?',
        text: 'There are still a lot of people among us that have an outdated notion about how the “normal” person should look like, who should he or she love, what he or she should believe in, etc. We think that these “perfect templates” are just nonsense. And that’s why we fight to stop the judgment of the “different” and look only at the substantial as a society.',
      },
      2: {
        heading: '🥰 Happy <br/>employees <br/>are more productive',
        text: '73 % of employees hiding their sexual orientation are more likely to leave their jobs within three years. More than 70 % LGBT people stated that they’re more likely to accept a job in a company openly supporting LGBT than in any other. Furthermore, engagement and productivity alone of LGBT employees diminishes in a hostile workplace by 30 %. Employers providing an inclusive atmosphere gain employees that are happier and more productive. <br/><i>(Source: Credit Suisse: LGBT- The Value of Diversity)</i>',
      },

      boxes: {
        1: {
          number: 'by 30 %',
          text: 'diminishes the engagement and productivity of LGBT employees in a hostile workplace',
        },
        2: {
          number: 'more than 70 %',
          text: 'stated that they’re more likely to accept a job in a company openly supporting LGBT',
        },
        3: {
          number: 'as much as 73 % ',
          text: 'higher is the probability that people hiding their sexual orientation will leave their jobs',
        },
      },
    },
    main: {
      heading: '📢 Principles we care about:',
      items: {
        A: '1. We treat each person fairly and exactly the same.',
        C: '2. We solve problems in the workplace and we take measures to prevent them.',
        E: '3. We unite employers who care about the happiness of their employees.',
        B: '4. We’re interested in how people feel and we’re listening to them.',
        D: '5. We spread awareness about diversity and inclusion in the workplace.',
      },
    },

    terms: {
      heading: '🫶 Important terms',
      items: {
        1: {
          heading: 'Inclusion',
          text: 'Inclusion is all about creating a safe environment in which each individual can be themselves. In this bias-free environment people tolerate and support each other and they have the same opportunities. A person in a functioning inclusive environment feels they fit in and doesn’t have to deal with any form of discrimination.',
        },
        2: {
          heading: 'Diversity',
          text: 'The meaning of diversity or variety is a difference, or rather differences, between individuals or groups. Visible variety is a diversity in age, gender, ethnicity, religion, medical disability, sexual orientation, education or nationality. When talking about invisible diversity, we touch aspects such as different thinking, life experience or mental life.',
        },
        3: {
          heading: 'LGBTQ+',
          text: 'Abbreviation LGBTQ+ is for sexual minority populations. These five letters represent lesbians, gays, bisexuals, transgender, queer or ‘questioning’ people who are still not sure about their sexual preference. There are often other letters and the plus sign added for other sexual identities such as pansexuals, polyamorous people, asexuals, etc.',
        },
        4: {
          heading: 'Queer',
          text: 'Queer is a common term for people not identified as heterosexual or cisgender (people with gender identity which matches the sex one was assigned at birth). This term is often interchanged with the term Drag Queen - an artist, often man, who dresses up in women’s clothes for an original artistic performance. Drag queens typically wear extravagant clothes, wigs and heavy make-up.',
        },
      },
    },

    join: {
      heading: 'Don’t judge with us!',
      subheading: 'Do you agree with our manifesto? Let the others know! It’s easy.',
      items: {
        1: 'Register your company or sign in to your company profile on our website.',
        2: 'Add a new job and use the tag #nojudgement.',
        3: 'Build an inclusive work environment within your company and spread the word about this topic wherever you can!',
      },
      cta: 'Add job with #nojudgement.',
    },

    faq: {
      heading: '🗯 FAQ',
      items: [
        {
          question: 'What is the Diversity charter?',
          answer: `The Diversity charter is a European Union’s project aiming to support and implement diversity, flexibility and inclusion to the job market in EU countries.  <br/><br/>

          Do not hesitate to bring up your questions directly to the coordinators of the Diversity charter in Czech republic and Slovakia: <br/><br/>
            🇨🇿 <a href="https://diverzita.cz/">https://diverzita.cz/</a><br/>
            🇸🇰 <a href="https://www.chartadiverzity.sk/">https://www.chartadiverzity.sk/</a>`,
        },
        {
          question: 'What does it mean to be a signatory of the Diversity charter?',
          answer: `“By signing the Diversity charter the signatory not only declares the recognition of the diversity principle, but commits to cultivate it in the workplace. There are supporting programs and activities organized by BPS - Byznys pro společnost, the national coordinator of the Diversity charter.  The effort related to diversity cultivation is a continuous and never ending work, hence the minimum 3 year period for acceding signatories.” <br/>
            <i>Source: <a href="https://diverzita.cz/cs">https://diverzita.cz/cs</a></i>

          <br/><br/>

          <strong>Join the signatories of the Diversity charter!</strong><br/>
          For employers from Czech Republic:  <a href="https://diverzita.cz/cs/stante-se-signatarem-charty-diverzity">https://diverzita.cz/cs/stante-se-signatarem-charty-diverzity</a> <br/>
          For employers from Slovakia:  <a href="https://www.chartadiverzity.sk/charta-diverzity-sr/podmienky-pristupu/">https://www.chartadiverzity.sk/charta-diverzity-sr/podmienky-pristupu/</a>`,
        },
        {
          question:
            'Are there any organizations specializing in this topic that can help me as an employer?',
          answer: `Yes, here are some of  them:<br /><br />

<strong>Pride business forum</strong><br/>
The annual conference Pride Business Forum ist the biggest conference aimed at promoting the equality of LGBTQ+ people in the workplace in Czech Republic.<br/>
<a href="https://www.praguepride.cz/ ">https://www.praguepride.cz/</a><br/><br/>

<strong>Transparent</strong><br/>
Trans*parent z. s. is an organization trying to fight for the rights of the transgender, non-binary and intersex people and inspire a positive change in the society regarding this topic.<br/>
<a href="https://jsmetransparent.cz">https://jsmetransparent.cz</a><br/><br/>

<strong>Inakosť</strong><br/>
Slovak initiative bringing together people and organizations looking for solutions to better the life of lesbians, gays, bisexuals, transgender and intersex people so they can be a part of their families, work and school teams, local communities, church and the whole Slovak society in all regards.<br/>
<a href="https://inakost.sk">https://inakost.sk</a>`,
        },
        {
          question:
            'How should I, as an employer, proceed with creating an inclusive environment for employees from LGBTQ+ community?',
          answer: `The ombudsman, based on the survey Být LGBT+ v Česku from 2019, recommends these steps: <br/><br/>

“1. Bring into effect the measures meant to support diversity in the workplace, create an effective mechanism to prevent harassment in the workplace and have a plan ready in case the harassment presents itself.<br/><br/>

2. Proceed effectively and choose functional measures in case the harassment based on sexual orientation or gender identity appears in your workplace.<br/><br/>

3. Raise awareness of the legal complaint mechanism for LGBT+ people they can use if they become the victim of discrimination.<br/><br/>

4. Guide work inspection authorities methodically in relation to control of fair treatment in the workplace.” <br/><br/>
<i>Source: <a href="http://www.ochrance.cz/diskriminace/vyzkum/">http://www.ochrance.cz/diskriminace/vyzkum/</a></i>

          `,
        },
        {
          question:
            'What we at Pretlak think you can do as a first step towards diversity and inclusion in your company?',
          answer:
            'First and foremost, approach your colleagues and employees  without judgment and with respect. ❤️',
        },
      ],
    },

    jobs: {
      heading: '🚀 #NOJUDGEMENT JOBS',
    },
  },

  companyModal: {
    heading: '#NOJUDGEMENT',
    items: [
      {
        heading: '💬 Why no judgment?',
        text: 'There are still a lot of people among us that have an outdated notion about how the “normal” person should look like, who should he or she love, what he or she should believe in, etc. We think that these “perfect templates” are just nonsense. And that`s why we fight to stop the judgment of the “different” and look only at the substantial as a society. <a href="/manifest">Learn more</a>',
      },
      {
        heading: '🫶 Don`t judge with us!',
        text: 'Add a new job and use the tag #nojudgement. Build an inclusive work environment within your company and spread the word about this topic wherever you can!',
      },
    ],
  },
}
