module.exports = {
  newTitle: 'Novinka',
  sendUsArticleIdea: 'Pošlete nám TIP na článek',
  relatedPostsTitle: 'Podobné články',
  thankForArticle: 'Poděkuj za článek',
  latestPosts: 'Články z magazínu',
  showAllArticles: 'Zobrazit všechny',
  noArticleFound: 'Žádný článek nebyl nalezen.',
  newsletter: {
    title: 'Magazín Newsletter',
    subtitle:
      'Rozhovory, aktuality či inspiraci. To vše najdeš pravidelně ve tvém mailboxu.',
  },
  boxTitle: `Článek
    <br />
    v našem
    <br />
    magazínu`,
  article: 'článek',
  readersWithCount: '{{count}} čtenářů',
  boxText:
    'Potřebujete posílit povědomí o vaší značce či firmě? Chcete ukázat uchazečům zákulisí vaší firmy nebo firemní kulturu a tak zvýšit vaši atraktivitu? Pomůžeme vám...',
  mailTo: {
    subject: 'Mám záujem o článok v magazíne 📰',
    body: 'Pozdravujeme Pretlak, %0D%0A %0D%0A Rádi bychom si u vás objednali Článek v magazínu 📰 . %0D%0A %0D%0A Děkujeme pěkně. Jste super. %0D%0A %0D%0A Se srdečným pozdravem,',
  },
}
