module.exports = {
  introductionText:
    'Vyberte si typ ponuky a získajte relevantné CV-čka, zvýšenie povedomia o firme aj nekonečnú slávu v komunite. Ideme na to?',
  badgeLabel: 'Nekonečné ponuky',
  priceNote: 'Cena bez DPH / ponuka',
  package: {
    exclusive: 'Exkluzív',
    exclusiveNote: '+ DPH / ponuka',
    exclusiveSale: '299 €',
    premium: 'Prémium',
    premiumNote: '+ DPH / ponuka',
    premiumSale: '99 €',
    standard: 'Štandard',
    standardNote: '+ DPH / ponuka',
    standardSale: '79 €',
    complete: 'Komplet',
    category: 'Kategória',
    title: '{{packageName}} balík',
    smallTitle: 'Malý {{packageName}} balík',
    mediumTitle: 'Stredný {{packageName}} balík',
    largeTitle: 'Nekonečný {{packageName}} balík',
  },
  addon: {
    companyLogo: 'Logo firmy',
    jobDuration: 'Trvanie ponuky',
    topVisibility: 'TOP zobrazenie - {{count}} dní',
    topVisibilityDescription: 'TOP zobrazenie na webe',
    durationDays: '{{count}} dní',
    premiumVisibility: 'Prémiové zobrazenie - {{count}} dní',
    standardVisibility: 'Základné zobrazenie',
    newsletter: 'Zaradenie do Newslettra',
    newsletterShort: 'Newsletter',
    includedInNewsletter1x: '1x zaradenie do Newslettra',
    includedInNewsletter2x: '2x zaradenie do Newslettra',
    ppcCampaign: 'PPC online kampaň',
    ownColor: 'Zvýraznenie v hodnote {{price}} €',
    talentbox: 'Prístup do databázy životopisov',
    video: 'Video v ponuke',
    facebook: 'Facebook post',
    instagram: 'Instagram post',
    linkedin: 'LinkedIn post',
  },
  customPackageUpsell: {
    text: 'Potrebujete nekonečný balík, alebo balík ponúk na mieru?',
    cta: 'Napíšte nám',
  },
  selected: 'Vybrané',
  notebox: {
    headline: 'Hľadajte kolegov výhodnejšie!',
    text: 'Kúpte 5 alebo 10 jobov vo výhodnom balíku a ušetrite až 30 %.',
    cta: 'Pozrieť cenník',
  },
  mostFavorite: 'NAJOBĽÚBENEJŠÍ',
  viewAdvantageousPackage: 'Zobraziť výhodný balík',
  unavailableForHRAgencies: 'Nedostupné pre HR agentúry',
  tooltip1:
    'Ponuka bude zverejnená navždy. Môžete ju kedykoľvek uzavrieť vo vašom profile.',
  tooltip2: 'Odlíšte sa od konkurencie a podfarbite si ponuku vlastnou farbou.',
  tooltip3: 'Vyššia pozícia na webe + logo',
  tooltip4: 'Základná pozícia na webe',
  tooltipDb:
    'Na základe inzerovanej pozície vám vyberieme relevantných uchádzačov z Databázy životopisov',
  tooltip5: 'Najvyššia pozícia na webe + logo + farebné zvýraznenie',
  packagesOfferHead: 'Výhodné balíky ponúk',
  smallPackage: 'Malý balík',
  mediumPackage: 'Stredný balík',
  infinitePackage: 'Nekonečný balík',
  smallStandardPackage: 'MALÝ ŠTANDARD BALÍK',
  mediumStandardPackage: 'STREDNÝ ŠTANDARD BALÍK',
  infiniteStandardPackage: 'NEKONEČNÝ ŠTANDARD BALÍK',
  smallPremiumPackage: 'MALÝ PRÉMIUM BALÍK',
  mediumPremiumPackage: 'STREDNÝ PRÉMIUM BALÍK',
  infinitePremiumPackage: 'NEKONEČNÝ PRÉMIUM BALÍK',
  smallExclusivePackage: 'MALÝ EXKLUZÍV BALÍK',
  mediumExclusivePackage: 'STREDNÝ EXKLUZÍV BALÍK',
  infiniteExclusivePackage: 'NEKONEČNÝ EXKLUZÍV BALÍK',
  promocodeTitle: {
    'malý balík': 'Malý balík',
    'nekonečný balík': 'Nekonečný balík',
    'stredný balík': 'Stredný balík',
    default: 'Promokód',
  },
  promocodeSubtitle: {
    exkluzív: 'Exkluzív',
    prémium: 'Prémium',
    štandard: 'Štandard',
  },
  help: {
    start: 'Potrebujete poradiť, alebo máte otázky?',
    contactUs: 'Napíšte nám',
    end: 'alebo zavolajte',
  },
  pill: {
    followers: '{{count}} sledovateľov',
    contacts: '{{count}} kontaktov',
    views: '{{count}}x viac videní',
  },
  tooltipPpc:
    'Vďaka našim PPC kampaniam na sociálnych sieťach Facebook a Instagram, ako aj v Google Ads, dokážeme vašu ponuku dostať k ešte väčšiemu počtu relevantných kandidátov. Minimálny rozpočet na PPC kampaň je 30 € pri PRÉMIUM ponuke a 60 € pri EXKLUZÍV ponuke. Takto môžete osloviť až 5-krát viac kandidátov.',
  tooltipNewsletter:
    'Pretlak Newsletter posielame každý pondelok ráno na naše publikum uchádzačov a freelancerov. Spolu ho dostane viac ako 9000 kontaktov.',
}
